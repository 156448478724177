import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as Plotly from 'plotly.js';
import { Layout, PlotData } from 'plotly.js';
import * as moment from 'moment';
import { PhrosprPortalApi } from '../../../resource/phrospr-api';
import { ICountPerPlanningItemType, IDashboard, PlanningItemType, PlanningState } from '@phrospr/api-backend';

@Component({
    selector: 'pr-home-page',
    styleUrls: ['./home-page.component.scss'],
    templateUrl: './home-page.component.html',
})
export class HomePageComponent implements OnInit {
    PlanningItemType = PlanningItemType;
    PlanningState = PlanningState;

    dashboard: IDashboard;
    fromDate: string = moment().startOf('year').format('DD-MM-YYYY');
    toDate: string = moment().endOf('year').format('DD-MM-YYYY');

    dataLeadSourceCounts: Array<Partial<PlotData | any>> = [
        {
            values: [],
            labels: [],
            text: 'CO2',
            textposition: 'inside',
            domain: { column: 1 },
            hoverinfo: 'label+percent+name',
            hole: 0.4,
            type: 'pie',
        },
    ];

    layoutLeadSources = {
        title: 'Lead sources ' + new Date().getFullYear().toString(),
        annotations: [
            {
                font: {
                    size: 20,
                },
                showarrow: false,
                text: '',
                x: 0.1,
                y: 0.5,
            },
        ],
        height: 500,
        width: 600,
        showlegend: true,
        grid: { rows: 1, columns: 1 },
    };

    traceCalendarItemsPerDayMaintenances = {
        x: null,
        y: null,
        type: null,
        line: null,
        name: null,
    };
    traceCalendarItemsPerDayLeads = {
        x: null,
        y: null,
        type: null,
        line: null,
        name: null,
    };
    traceCalendarItemsPerDayDelivery = {
        x: null,
        y: null,
        type: null,
        line: null,
        name: null,
    };
    traceCalendarItemsPerDayRepair = {
        x: null,
        y: null,
        type: null,
        line: null,
        name: null,
    };
    traceCalendarItemsPerDayPlacement = {
        x: null,
        y: null,
        type: null,
        line: null,
        name: null,
    };

    layoutCalendarItemsPerDayPerType = {
        title: 'Aantal geplande afspraken per dag per type',
        width: 1800,
    };

    sunburstCountPlanningItemTypePerUser = [];

    traceSumQuotesCreated = {
        x: [],
        y: [],
        name: 'Created',
        type: 'bar',
        marker: {
            color: '#ff7f0e',
        },
    };

    traceSumQuotesApproved = {
        x: [],
        y: [],
        name: 'Approved',
        type: 'bar',
        marker: {
            color: '#20b142',
        },
    };

    layoutSumQuotes: Partial<Layout> = {
        barmode: 'stack',
        title: 'Offerte total (excl btw)',
        width: 400,
        height: 400,
    };

    layout = {
        title: {
            text: '',
            font: {
                size: 17,
            },
        },
        margin: { l: 0, r: 0, b: 0, t: 0 },
        width: 400,
        height: 400,
    };

    constructor(private router: Router) {}

    async createGraph() {
        await this.getAllDashboardData();
        Plotly.react('myLeadsPerSource', this.dataLeadSourceCounts, this.layoutLeadSources);
        const dataLineGraph: Array<Partial<PlotData | any>> = [
            this.traceCalendarItemsPerDayLeads,
            this.traceCalendarItemsPerDayMaintenances,
            this.traceCalendarItemsPerDayRepair,
            this.traceCalendarItemsPerDayPlacement,
            this.traceCalendarItemsPerDayDelivery,
        ];
        Plotly.react('sunburstFinishedItems', this.sunburstCountPlanningItemTypePerUser, this.layout);
        Plotly.react('PlannedItemsPerDay', dataLineGraph, this.layoutCalendarItemsPerDayPerType);

        this.traceCalendarItemsPerDayMaintenances.type = 'bar';
        this.traceCalendarItemsPerDayLeads.type = 'bar';
        this.traceCalendarItemsPerDayDelivery.type = 'bar';
        this.traceCalendarItemsPerDayRepair.type = 'bar';
        this.traceCalendarItemsPerDayPlacement.type = 'bar';

        const dataStackedBarChart: Array<Partial<PlotData | any>> = [
            this.traceSumQuotesApproved,
            this.traceSumQuotesCreated,
        ];

        Plotly.react('stackedBarSumQuoteTotals', dataStackedBarChart, this.layoutSumQuotes);

        /*  const datatest = [this.traceCalendarItemsPerDayLeads,
      this.traceCalendarItemsPerDayMaintenances,
      this.traceCalendarItemsPerDayRepair, this.traceCalendarItemsPerDayPlacement,
      this.traceCalendarItemsPerDayDelivery];

    const layout = {
      title: '2020 Sales Report',
      width: 1800,
      barmode: 'stack',
    };
    Plotly.react('myDiv', datatest, layout);*/
    }

    ngOnInit(): void {
        this.createGraph();
    }

    async getAllDashboardData() {
        this.dashboard = (
            await PhrosprPortalApi.DashboardApi.getDashboard(
                moment(this.fromDate, 'DD-MM-YYYY').toISOString(),
                moment(this.toDate, 'DD-MM-YYYY').toISOString(),
            )
        ).data;
        await this.fillInDashboardDataInGraphs();
    }

    getCountPlanningItemTypePerState(planningItemType: PlanningItemType, state: PlanningState) {
        const countMapping: { [planningState: string]: ICountPerPlanningItemType[] } = {};
        countMapping[PlanningState.toBePlanned] = this.dashboard.count_to_be_planned_per_planning_item_type;
        countMapping[PlanningState.planned] = this.dashboard.count_planned_calendar_items_per_type;
        countMapping[PlanningState.completed] = this.dashboard.count_finished_calendar_items_per_type;
        countMapping[PlanningState.completedWithOffer] = this.dashboard.count_finished_calendar_items_per_type;
        countMapping[PlanningState.completedNoOffer] = this.dashboard.count_finished_calendar_items_per_type;

        const countList = countMapping[state];
        if (!countList) throw new Error('state countlist not mapped');
        for (let i = 0; i < countList.length; i++) {
            const item = countList[i];
            if (item.planning_item_type === planningItemType && (!item.state || item.state === state))
                return item.count;
        }
    }

    async fillInDashboardDataInGraphs() {
        this.prepareDataForPieChartAndScatter();
        this.prepareDataForSunburst();
        this.prepareDataForStackedBarChartForQuotes();
    }

    prepareDataForPieChartAndScatter() {
        for (let i = 0; i < this.dashboard.count_lead_sources.length; i++) {
            this.dataLeadSourceCounts[0].labels.push(this.dashboard.count_lead_sources[i].lead_type.toString());
            this.dataLeadSourceCounts[0].values.push(this.dashboard.count_lead_sources[i].count);
        }

        this.traceCalendarItemsPerDayMaintenances = {
            x: [],
            y: [],
            type: 'scatter',
            line: { color: '#17BECF' },
            name: 'Onderhouden',
        };

        this.traceCalendarItemsPerDayLeads = {
            x: [],
            y: [],
            type: 'scatter',
            line: { color: '#cf110d' },
            name: 'Leads',
        };
        this.traceCalendarItemsPerDayDelivery = {
            x: [],
            y: [],
            type: 'scatter',
            line: { color: '#cbcf11' },
            name: 'Leveringen',
        };
        this.traceCalendarItemsPerDayRepair = {
            x: [],
            y: [],
            type: 'scatter',
            line: { color: '#cf00c9' },
            name: 'Depannage ',
        };
        this.traceCalendarItemsPerDayPlacement = {
            x: [],
            y: [],
            type: 'scatter',
            line: { color: '#b17200' },
            name: 'Plaatsingen',
        };

        const traceMapping: { [planningItemType: string]: any } = {};
        traceMapping[PlanningItemType.maintenance] = this.traceCalendarItemsPerDayMaintenances;
        traceMapping[PlanningItemType.lead] = this.traceCalendarItemsPerDayLeads;
        traceMapping[PlanningItemType.repair] = this.traceCalendarItemsPerDayRepair;
        traceMapping[PlanningItemType.placement] = this.traceCalendarItemsPerDayPlacement;
        traceMapping[PlanningItemType.delivery] = this.traceCalendarItemsPerDayDelivery;

        this.dashboard.count_calendar_items_per_type_per_date.forEach(item => {
            const trace = traceMapping[item.planning_item_type];
            trace.x.push(moment(item.date_trunc).format('YYYY-MM-DD'));
            trace.y.push(parseInt(item.count, 10));
        });
    }

    prepareDataForSunburst() {
        // clear graphData
        this.sunburstCountPlanningItemTypePerUser = [
            {
                type: 'sunburst',
                ids: ['Total'],
                labels: ['Totaal<br>afgewerkt<br>door'],
                parents: [''],
                values: [0],
                outsidetextfont: { size: 20, color: '#377eb8' },
                leaf: { opacity: 0.4 },
                marker: { line: { width: 2 } },
                branchvalues: 'total',
            },
        ];

        let totalOfBurst = 0;
        for (let i = 0; i < this.dashboard.count_finished_planning_item_type_per_user.length; i++) {
            if (
                !this.sunburstCountPlanningItemTypePerUser[0].ids.includes(
                    this.dashboard.count_finished_planning_item_type_per_user[i].in_calendar_of_user_id.toString(),
                )
            ) {
                let totalCountUser = 0;
                let leadCount = 0;
                const user_id = this.dashboard.count_finished_planning_item_type_per_user[i].in_calendar_of_user_id;
                this.dashboard.count_finished_planning_item_type_per_user.filter(item => {
                    if (item.in_calendar_of_user_id === user_id) {
                        totalCountUser += parseInt(item.count, 10);
                        if (item.planning_item_type === PlanningItemType.lead) {
                            leadCount += parseInt(item.count, 10);
                            // ADDING THE MOST DETAILED LEVEL FOR LEADS
                            this.sunburstCountPlanningItemTypePerUser[0].ids.push(
                                item.in_calendar_of_user_id + PlanningItemType.lead + item.state,
                            );
                            this.sunburstCountPlanningItemTypePerUser[0].labels.push(
                                PlanningItemType.lead + ' ' + item.state,
                            );
                            this.sunburstCountPlanningItemTypePerUser[0].parents.push(
                                item.in_calendar_of_user_id.toString() + PlanningItemType.lead,
                            );
                            this.sunburstCountPlanningItemTypePerUser[0].values.push(parseInt(item.count, 10));
                        }
                    }
                });

                // ADDING THE USER LEVELS
                this.sunburstCountPlanningItemTypePerUser[0].ids.push(
                    this.dashboard.count_finished_planning_item_type_per_user[i].in_calendar_of_user_id.toString(),
                );
                this.sunburstCountPlanningItemTypePerUser[0].labels.push(
                    this.dashboard.count_finished_planning_item_type_per_user[i].first_name,
                );
                this.sunburstCountPlanningItemTypePerUser[0].parents.push('Total');
                this.sunburstCountPlanningItemTypePerUser[0].values.push(totalCountUser);
                totalOfBurst += totalCountUser;

                // ADDING THE PLANNING ITEM LEVELS ABOVE USER FOR LEADS
                if (leadCount > 0) {
                    this.sunburstCountPlanningItemTypePerUser[0].ids.push(
                        this.dashboard.count_finished_planning_item_type_per_user[i].in_calendar_of_user_id +
                            PlanningItemType.lead,
                    );
                    this.sunburstCountPlanningItemTypePerUser[0].labels.push(PlanningItemType.lead);
                    this.sunburstCountPlanningItemTypePerUser[0].parents.push(
                        this.dashboard.count_finished_planning_item_type_per_user[i].in_calendar_of_user_id.toString(),
                    );
                    this.sunburstCountPlanningItemTypePerUser[0].values.push(leadCount);
                }
            }

            // ADDING THE PLANNING ITEM LEVELS ABOVE USER FOR NON_LEADS
            if (
                this.dashboard.count_finished_planning_item_type_per_user[i].planning_item_type !==
                PlanningItemType.lead
            ) {
                this.sunburstCountPlanningItemTypePerUser[0].ids.push(
                    this.dashboard.count_finished_planning_item_type_per_user[i].in_calendar_of_user_id +
                        this.dashboard.count_finished_planning_item_type_per_user[i].planning_item_type,
                );
                this.sunburstCountPlanningItemTypePerUser[0].labels.push(
                    this.dashboard.count_finished_planning_item_type_per_user[i].planning_item_type,
                );
                this.sunburstCountPlanningItemTypePerUser[0].parents.push(
                    this.dashboard.count_finished_planning_item_type_per_user[i].in_calendar_of_user_id.toString(),
                );
                this.sunburstCountPlanningItemTypePerUser[0].values.push(
                    parseInt(this.dashboard.count_finished_planning_item_type_per_user[i].count, 10),
                );
            }
        }
        this.sunburstCountPlanningItemTypePerUser[0].values[0] = totalOfBurst;
    }

    prepareDataForStackedBarChartForQuotes() {
        this.dashboard.sum_quote_total_per_month_created.forEach(item => {
            this.traceSumQuotesCreated.x.push(moment(item.date_trunc).format('YYYY-MM-DD'));
            this.traceSumQuotesCreated.y.push(item.sum);
        });
        this.dashboard.sum_quote_total_per_month_approved.forEach(item => {
            this.traceSumQuotesApproved.x.push(moment(item.date_trunc).format('YYYY-MM-DD'));
            this.traceSumQuotesApproved.y.push(item.sum);
        });
    }

    async resetDates() {
        this.fromDate = moment().startOf('year').format('DD-MM-YYYY');
        this.toDate = moment().endOf('year').format('DD-MM-YYYY');
        await this.createGraph();
    }

    async triggerDateFilter(targetDate: 'from' | 'to', event) {
        if (targetDate === 'from') {
            this.fromDate = event;
        } else if (targetDate === 'to') {
            this.toDate = event;
        }
        await this.createGraph();
    }

    goToPlanningItemOverviewPage(planningItemName: PlanningItemType, tabName: string) {
        let planningItemNameString: string = planningItemName;

        if (planningItemNameString === 'delivery') {
            planningItemNameString = 'deliveries';
        }
        if (planningItemNameString === 'lead') {
            planningItemNameString = 'leads';
        }

        this.router.navigateByUrl('/' + planningItemNameString + '?tab=' + tabName + '&pageNo=1');
    }
}
