import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ROUTING } from '../../app.routing';
import { ClarityModule, ClrButtonGroupModule, ClrButtonModule, ClrTabsModule } from '@clr/angular';
import { SharedModule } from '../shared/shared.module';
import { PlanCalendarItemPageComponent } from './containers/plan-calendar-item-page/plan-calendar-item-page.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';

const components = [];

const containers = [PlanCalendarItemPageComponent];

const imports = [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ROUTING,
    ClarityModule,
    ClrTabsModule,
    ClrButtonModule,
    SharedModule,
    ClrButtonGroupModule,
    CalendarModule.forRoot({
        provide: DateAdapter,
        useFactory: adapterFactory,
    }),
    NgbModule,
    NgbModalModule,
];

const sandboxes = [];

const services = [];

@NgModule({
    imports: [...imports, NgbModalModule],
    declarations: [...containers, ...components, PlanCalendarItemPageComponent],
    providers: [...sandboxes, ...services],
    entryComponents: [],
})
export class PlanCalendarItemModule {}
