<div>
    <div class="row calendar-header">
        <div class="col-md-4 grid-item">
            <div class="btn-group">
                <button
                    class="btn btn-primary"
                    mwlCalendarPreviousView
                    [view]="view"
                    [(viewDate)]="viewDate"
                    (viewDateChange)="closeOpenMonthViewDay()"
                >
                    <clr-icon shape="caret left"></clr-icon>
                    Previous
                </button>
                <button class="btn btn-outline" mwlCalendarToday [(viewDate)]="viewDate" (click)="setToday()">
                    Today
                </button>
                <button
                    class="btn btn-primary"
                    mwlCalendarNextView
                    [view]="view"
                    [(viewDate)]="viewDate"
                    (viewDateChange)="closeOpenMonthViewDay()"
                >
                    Next
                    <clr-icon shape="caret right"></clr-icon>
                </button>
            </div>
        </div>
        <div class="col-md-4 grid-item text-center">
            <h3>{{ viewDate | calendarDate: view + 'ViewTitle':'en' }}</h3>
        </div>
        <div class="col-md-4 grid-item text-right">
            <div class="btn-group">
                <button
                    class="btn btn-primary active"
                    (click)="setView(CalendarView.Week)"
                    [class.active]="view === CalendarView.Week"
                >
                    Week
                </button>
                <button
                    class="btn btn-secondary"
                    (click)="setView(CalendarView.Day)"
                    [class.active]="view === CalendarView.Day"
                >
                    Day
                </button>
            </div>
        </div>
    </div>
    <div class="wrapper" *ngIf="loading">
        <span class="spinner"> Loading... </span>
    </div>
    <div class="calendar-layout">
        <div class="grid-item calendars">
            <div class="select-calendar-layout">
                <div class="grid-item">
                    <!-- test-->
                </div>
                <div class="grid-item">
                    <div>Andere agenda's</div>
                    <div *ngIf="userListDb$ | async; let users">
                        <clr-checkbox-wrapper *ngFor="let user of users; let i = index">
                            <input
                                type="checkbox"
                                clrCheckbox
                                value="{{ user }}"
                                name="options"
                                [ngModel]="user.visualize"
                                (ngModelChange)="visualizeUserCalendarData(user, $event)"
                            />
                            <label [ngStyle]="{ 'border-bottom': '2px solid ' + user.data.secondary }">
                                <!--<span [ngStyle]="{'background-color':user.data.secondary}" class="dot"></span>-->
                                {{ user.first_name }}
                            </label>
                        </clr-checkbox-wrapper>
                    </div>
                </div>
            </div>
        </div>

        <div class="grid-item">
            <br />
            <ng-template
                #weekViewHourSegmentTemplate
                let-segment="segment"
                let-locale="locale"
                let-segmentHeight="segmentHeight"
                let-isTimeLabel="isTimeLabel"
            >
                <div
                    #segmentElement
                    class="cal-hour-segment"
                    [style.height.px]="segmentHeight"
                    [class.cal-hour-start]="segment.isStart"
                    [class.cal-after-hour-start]="!segment.isStart"
                    [ngClass]="segment.cssClass"
                    (mousedown)="startDragToCreate(segment, $event, segmentElement)"
                >
                    <div class="cal-time" *ngIf="isTimeLabel">
                        {{ segment.date | calendarDate: 'weekViewHour':locale }}
                    </div>
                </div>
            </ng-template>
            <div [ngSwitch]="view">
                <mwl-calendar-week-view
                    *ngSwitchCase="CalendarView.Week"
                    [viewDate]="viewDate"
                    [events]="events"
                    [hourSegmentTemplate]="weekViewHourSegmentTemplate"
                    [weekStartsOn]="1"
                    [dayStartHour]="6"
                    [dayEndHour]="20"
                    [refresh]="refresh"
                    (eventClicked)="handleEvent('Clicked', $event.event)"
                    (eventTimesChanged)="eventTimesChanged($event)"
                    (dayHeaderClicked)="viewDate = $event.day.date; view = 'day'"
                    (mouseup)="handleEvent('Created', $event.event)"
                >
                </mwl-calendar-week-view>
                <mwl-calendar-day-view
                    *ngSwitchCase="CalendarView.Day"
                    [viewDate]="viewDate"
                    [events]="events"
                    [hourSegmentTemplate]="weekViewHourSegmentTemplate"
                    [dayStartHour]="7"
                    [dayEndHour]="20"
                    [refresh]="refresh"
                    (eventClicked)="handleEvent('Clicked', $event.event)"
                    (eventTimesChanged)="eventTimesChanged($event)"
                    (mouseup)="handleEvent('Created', $event.event)"
                >
                </mwl-calendar-day-view>
            </div>
        </div>
    </div>

    <clr-modal
        [(clrModalOpen)]="openCreateModal"
        [clrModalSize]="'lg'"
        data-target="#modal"
        id="planPlanningItemModal"
        (close)="close(modalData.action)"
        [clrModalClosable]="false"
    >
        <div class="modal-header">
            <!-- <h5 class="modal-title">Onderhoud plannen</h5>-->
            <button type="button" (close)="close(modalData.action)">
                <span aria-hidden="true"></span>
            </button>
        </div>
        <div class="modal-body">
            <div>
                <div class="title-div">
                    <div class="title-input">
                        <input
                            *ngIf="modalData?.action === 'Created'"
                            placeholder="Title toevoegen"
                            name="title-Created"
                            type="text"
                            id="title-Created"
                            class="full-length-screen"
                            [(ngModel)]="dragToSelectEvent.title"
                        />
                        <input
                            *ngIf="modalData?.action === 'Clicked'"
                            disabled
                            placeholder="Title toevoegen"
                            name="title-Clicked"
                            type="text"
                            id="title-Clicked"
                            class="full-length-screen"
                            [(ngModel)]="modalData.event.title"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="icon-modal">
                        <clr-icon shape="clock"></clr-icon>
                    </div>
                    <div class="group-section" *ngIf="modalData?.action === 'Created'">
                        <input
                            [(clrDate)]="dragToSelectEvent.start"
                            clrDate
                            placeholder="From"
                            type="date"
                            class="date-input group-element no-border"
                        />
                        <div class="clr-select-wrapper group-element no-border">
                            <select
                                id="select-date-from"
                                class="clr-select no-border"
                                #hourFrom
                                (change)="setTime(dragToSelectEvent, hourFrom.value, true)"
                            >
                                <option
                                    *ngFor="let hour of hours"
                                    [ngValue]="hour.hour"
                                    [selected]="selectRightTime(dragToSelectEvent.start, hour.hour)"
                                >
                                    {{ hour.hour }}
                                    <!-- {{dragToSelectEvent.start | date: 'hh:mm'}}-->
                                </option>
                            </select>
                        </div>
                        <div class="group-element">-</div>
                        <input
                            [(clrDate)]="dragToSelectEvent.end"
                            clrDate
                            placeholder="To"
                            type="date"
                            class="date-input group-element no-border"
                        />
                        <div class="clr-select-wrapper group-element no-border">
                            <select
                                id="select-date-to"
                                class="clr-select no-border"
                                #hourTo
                                (change)="setTime(dragToSelectEvent, hourTo.value, false)"
                            >
                                <option
                                    *ngFor="let hour of hours"
                                    [ngValue]="hour.hour"
                                    [selected]="selectRightTime(dragToSelectEvent.end, hour.hour)"
                                >
                                    {{ hour.hour }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="group-section" *ngIf="modalData?.action === 'Clicked'">
                        <input
                            [(clrDate)]="modalData.event.start"
                            disabled
                            clrDate
                            placeholder="From"
                            [disabled]="true"
                            type="date"
                            class="date-input group-element no-border"
                        />
                        <div class="group-element no-border">
                            <label class="group-element" disabled style="vertical-align: baseline">
                                {{ modalData.event.start.getHours() + ':' + modalData.event.start.getMinutes() }}
                            </label>
                        </div>
                        <div class="group-element">-</div>
                        <input
                            [(clrDate)]="modalData.event.end"
                            clrDate
                            placeholder="To"
                            disabled
                            [disabled]=""
                            type="date"
                            class="date-input group-element no-border"
                        />
                        <div class="group-element no-border">
                            <label class="group-element" disabled style="vertical-align: baseline">
                                {{ modalData.event.end.getHours() + ':' + modalData.event.end.getMinutes() }}
                            </label>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="icon-modal">
                        <clr-icon shape="calendar"></clr-icon>
                    </div>
                    <div class="group-section" *ngIf="modalData?.action === 'Created'">
                        <div class="input-select full-length-screen">
                            <div
                                *ngIf="userListDb$ | async; let users"
                                class="clr-select-wrapper full-length-screen"
                                style="padding-right: 16px"
                            >
                                <select
                                    id="select-user"
                                    class="clr-select group-element no-border full-length-screen"
                                    [(ngModel)]="this.dragToSelectEvent.user"
                                >
                                    <option *ngFor="let user of users; let i = index" [value]="user.email">
                                        {{ user.first_name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="group-section" *ngIf="modalData?.action === 'Clicked'">
                        <div class="full-length-screen input-select">
                            <div class="full-length-screen" style="padding-right: 16px">
                                <label class="group-element full-length-screen">{{ modalData.event.user }} </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="icon-modal">
                        <clr-icon shape="map-marker"></clr-icon>
                    </div>
                    <div class="group-section">
                        <div class="group-element" style="width: 95%">
                            <input
                                *ngIf="modalData?.action === 'Created'"
                                placeholder="Locatie toevoegen"
                                name="location"
                                type="text"
                                id="location"
                                [(ngModel)]="this.dragToSelectEvent.location"
                                class="full-length-screen no-border"
                            />
                            <input
                                *ngIf="modalData?.action === 'Clicked'"
                                disabled
                                placeholder="Locatie toevoegen"
                                name="location-clicked"
                                type="text"
                                id="location-clicked"
                                [(ngModel)]="modalData.event.location"
                                class="full-length-screen no-border"
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <div class="icon-modal"></div>
                    <div class="group-section">
                        <div class="group-element" style="width: 95%">
                            <clr-textarea-container class="no-margin">
                                <textarea
                                    *ngIf="modalData?.action === 'Created'"
                                    clrTextarea
                                    [(ngModel)]="this.dragToSelectEvent.content"
                                    name="description"
                                    class="full-length-screen no-border textarea-custom"
                                    placeholder="beschrijving toevoegen"
                                >
                                </textarea>
                            </clr-textarea-container>
                            <clr-textarea-container class="no-margin">
                                <textarea
                                    *ngIf="modalData?.action === 'Clicked'"
                                    clrTextarea
                                    [(ngModel)]="this.modalData.event.content"
                                    name="description"
                                    class="full-length-screen no-border textarea-custom"
                                    disabled
                                >
                                </textarea>
                            </clr-textarea-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div class="modal-footer" *ngIf="!this.loadingSaveCalendarItem">
                <div *ngIf="modalData?.action === 'Created'">
                    <button
                        type="button"
                        class="btn btn-link"
                        (click)="editMailLayout()"
                        [disabled]="getSendToEmailForEmailObject() === null"
                    >
                        {{ getSendToEmailForEmailObject() != null ? 'mail layout' : 'Geen mail gevonden' }}
                    </button>
                    <button type="button" class="btn btn-primary" (click)="createCalendarEvent(dragToSelectEvent)">
                        opslaan
                    </button>
                </div>
                <button type="button" class="btn btn-secondary" (click)="close(modalData.action)">close</button>
            </div>
            <div *ngIf="this.loadingSaveCalendarItem">
                <span class="spinner spinner-inline"> Loading... </span>
                <span> Saving... </span>
            </div>
        </div>
    </clr-modal>
</div>

<pr-send-mail-card
    *ngIf="visualizeMailCard"
    [visible]="visualizeMailCard"
    [email_object]="email_object"
    (ok)="saveMailLayout($event)"
    (cancel)="cancelMailLayout()"
    (language)="getMailTemplateObjectPlanned($event)"
>
</pr-send-mail-card>
