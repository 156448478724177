import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { PhrosprPortalApi } from '../../../../../resource/phrospr-api';
import { ClientState, IClientEntity, IClientFilterEntity, RequestSort } from '@phrospr/api-backend';

@Component({
    selector: 'pr-client-search-modal',
    templateUrl: './client-search-modal.component.html',
    styleUrls: ['./client-search-modal.component.scss'],
})
export class ClientSearchModalComponent implements OnInit {
    @Output() client = new EventEmitter<any>();

    filter: { clientNumber: string; clientName: string; state: string } = {
        clientNumber: '',
        clientName: '',
        state: 'client',
    };
    filter$: ReplaySubject<{ key: string; value: any }> = new ReplaySubject(1);

    clientSearchTrigger = '';
    searchClientField$: ReplaySubject<any> = new ReplaySubject(1);

    clients: IClientEntity[];

    hideContent = true;

    selectedClient: IClientEntity = {
        client_id: null,
        exact_client_number: null,
        exact_last_sync: null,
        state: ClientState.client,
        inactive: false,
        client_data: {
            first_name: null,
            last_name: null,
            email: null,
            main_address: {
                address: null,
                city: null,
                zip_code: null,
                deleted: false,
            },
            company_data: {},
        },
        client_settings: {
            mailSettings: {
                disableSendMailForPlannedNotification: false,
                disableSendMailForUpcomingMaintenance: false,
                disableSendMailNotifyPlanningItemForHisAppointment: false,
            },
            maintenanceSettings: {
                active: false,
                inactive_date: null,
            },
        },
    };

    constructor() {
        this.filter$.subscribe(item => {
            if (!item) return;
            this.filter[item.key] = item.value;
            this.hideContent = false;
        });
        this.searchClientField$.pipe(debounceTime(300)).subscribe(_ => {
            this.filter$.next({ key: 'clientNumber', value: this.clientSearchTrigger });
            this.filter$.next({ key: 'clientName', value: this.clientSearchTrigger });
            this.getClients({
                limit: 50,
                offset: 0,
                sort: { order: RequestSort.ASC, column: 'client_number' },
            });
        });
    }

    ngOnInit(): void {}

    async getClients(searchInfo?: {
        limit: number;
        offset: number;
        sort: {
            order: RequestSort;
            column: string;
        };
    }) {
        if (this.filter) {
            this.filter = JSON.parse(JSON.stringify(this.filter));
        }

        const ClientFilterEntity: IClientFilterEntity = {
            filter: this.filter,
            offset: searchInfo.offset * searchInfo.limit,
            sort: searchInfo.sort,
            limit: searchInfo.limit,
            show: {},
        };
        try {
            this.clients = (await PhrosprPortalApi.ClientApi.listClients(ClientFilterEntity)).data.clients;
        } catch (err) {
            console.error(err);
        }
    }

    selectClient(client) {
        this.selectedClient = client;
        this.hideContent = true;
        this.client.emit(client);
    }
}
