"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseIRequestActivityListBody = void 0;
const iactivity_search_entity_1 = require("./iactivity-search-entity");
function parseIRequestActivityListBody(data) {
    if (data.search_filter !== null && typeof data.search_filter === 'object')
        data.search_filter = (0, iactivity_search_entity_1.parseIActivitySearchEntity)(data.search_filter);
    return data;
}
exports.parseIRequestActivityListBody = parseIRequestActivityListBody;
