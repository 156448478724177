<div *ngIf="planningItems">
  <ngx-datatable
    class="material striped"
    [rows]="planningItems"
    [columnMode]="'force'"
    [rowHeight]="30"
    [headerHeight]="50"
    [footerHeight]="50"
    [selectionType]="'single'"
    [externalPaging]="true"
    [externalSorting]="true"
    (activate)="onActivate($event)"
    [count]="page.count"
    [offset]="page.offset"
    [limit]="page.limit"
    [sortType]="'single'"
    (page)="pageCallback($event)"
    (sort)="sortCallback($event)"
    [rowClass]="getRowClass"
    [reorderable]="reorderable"
  >
    <ngx-datatable-column name="planning_item_type_number" [sortable]="true" [maxWidth]="200">
      <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
        <span (click)="sort()" class="pointer">
        {{planningItemFilter.planning_item_type | getNameOfPlanningItemTypePipe: Language.nl}} ID
        </span>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.planning_item_type_number}}
      </ng-template>
    </ngx-datatable-column>

    <div *ngIf="planningItemFilter.planning_item_type !== PlanningItemType.lead">
      <ngx-datatable-column name="client_number" [maxWidth]="200">
        <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
        <span (click)="sort()" class="pointer">
          Klant nummer
        </span>
        </ng-template>
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{row.client.exact_client_number}}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="name">
        <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
            <span (click)="sort()" class="pointer">
             Naam
           </span>
        </ng-template>
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{row.client.client_data.first_name}} {{row.client.client_data.last_name}}
        </ng-template>
      </ngx-datatable-column>
    </div>

    <ngx-datatable-column name="postcode">
      <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
        <span (click)="sort()" class="pointer">
          Postcode
        </span>
      </ng-template>
      <ng-template let-row="row" ngx-datatable-cell-template>
        <span *ngIf="row.client">
        {{row.client.client_data.main_address.zip_code ? row.client.client_data.main_address.zip_code : '/'}}
          {{row.client.client_data.main_address.city ? row.client.client_data.main_address.city : '/'}}
        </span>
      </ng-template>
    </ngx-datatable-column>

    <div *ngIf="planningItemFilter.planning_item_type === PlanningItemType.lead">
      <ngx-datatable-column name="lead_type">
        <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
        <span (click)="sort()" class="pointer">
          Lead type
        </span>
        </ng-template>
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{row.client.lead_data ? row.client.lead_data.lead_type : ''}}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="lead_customs_id">
        <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
        <span (click)="sort()" class="pointer">
          Lead ID source
        </span>
        </ng-template>
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{row.client.lead_data ? row.client.lead_data.lead_custom_id : ''}}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="name">
        <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
         <span (click)="sort()" class="pointer">
          Naam
        </span>
        </ng-template>
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{row.client.client_data.first_name}} {{row.client.client_data.last_name}}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="lead_creation_date">
        <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
        <span (click)="sort()" class="pointer">
          Lead creatie datum
        </span>
        </ng-template>
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{(row.client.lead_data && row.client.lead_data.created_at && row.client.lead_data.created_at.date) ?
          (row.client.lead_data.created_at.date | dateFormatWithTime) : (row.creation_date| dateFormatWithTime)}}
        </ng-template>
      </ngx-datatable-column>
    </div>


    <div *ngIf="planningItemFilter.planning_item_type === PlanningItemType.delivery">
      <ngx-datatable-column name="delivery_creation_date">
        <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
        <span (click)="sort()" class="pointer">
          Levering creatie datum
        </span>
        </ng-template>
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{row.creation_date ?
          (row.creation_date | dateFormatWithTime) : '-'}}
        </ng-template>
      </ngx-datatable-column>
    </div>


    <div *ngIf="planningItemFilter.state === PlanningState.planned">
      <ngx-datatable-column name="assigned_calendar_item_date">
        <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
               <span (click)="sort()" class="pointer">
               Geplande datum onderhoud
               </span>
        </ng-template>
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{row.assigned_calendar_item ?
          (row.assigned_calendar_item.date_time_start | dateFormatWithTime) : ''}}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="created_by_user_id">
        <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()" class="pointer">
                  Ingepland door
               </span>
        </ng-template>
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{row?.assigned_calendar_item?.created_by_user?.first_name}}
          {{row?.assigned_calendar_item?.created_by_user?.last_name }}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="in_calendar_of_user_id">
        <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()" class="pointer">
                  Ingepland bij
               </span>
        </ng-template>
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{row?.assigned_calendar_item?.in_calendar_of_user?.first_name}}
          {{row?.assigned_calendar_item?.in_calendar_of_user?.last_name}}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="finish_maintenance">
        <ng-template let-column="column" ngx-datatable-header-template>
        </ng-template>
        <ng-template let-row="row" ngx-datatable-cell-template>
          <button class="btn btn-success btn-sm btn-icon" *ngIf='!row.unAssigning' (click)="$event.preventDefault();
               $event.stopPropagation(); showFinishPlanningItem(row)">
            <clr-icon shape="check"></clr-icon>
          </button>
        </ng-template>
      </ngx-datatable-column>


    </div>

    <div *ngIf="planningItemFilter.state === PlanningState.completed ||
    planningItemFilter.state === PlanningState.completedWithOffer ||
    planningItemFilter.state === PlanningState.completedNoOffer ||
    planningItemFilter.state === PlanningState.completedAndQuoteApproved">

      <ngx-datatable-column name="in_calendar_of_user_id">
        <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
        <span (click)="sort()" class="pointer">
        Uitgevoerd door
        </span>
        </ng-template>
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{row.assigned_calendar_item ? row?.assigned_calendar_item?.in_calendar_of_user?.first_name
          : 'manueel'}}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="last_completed_calendar_item_date">
        <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
         <span (click)="sort()" class="pointer">
           Uitgevoerd op
        </span>
        </ng-template>
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{row.assigned_calendar_item ? (row.assigned_calendar_item.date_time_start | dateFormatOnlyDate)
          : 'manueel'}}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="approved_quotes">
        <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
         <span (click)="sort()" class="pointer">
           Verkoper
        </span>
        </ng-template>
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{row.approved_quotes && row.approved_quotes.length && row.approved_quotes[0].sold_by_user ? row.approved_quotes[0].sold_by_user.first_name
          : ''}}
        </ng-template>
      </ngx-datatable-column>

    </div>

    <div *ngIf="planningItemFilter.state === PlanningState.completedWithOffer ||
        planningItemFilter.state === PlanningState.completedAndQuoteApproved">


      <ngx-datatable-column name="complete_quote">
        <ng-template let-column="column" ngx-datatable-header-template>
        </ng-template>
        <ng-template let-row="row" ngx-datatable-cell-template>
          <button class="btn btn-success btn-sm btn-icon" *ngIf='!row.unAssigning &&
          planningItemFilter.state === PlanningState.completedWithOffer '
                  (click)="$event.preventDefault();
               $event.stopPropagation(); showFinishQuote(row)">
            <clr-icon shape="check"></clr-icon>
          </button>
          <button class="btn btn-outline btn-sm btn-icon" *ngIf='!row.unAssigning' (click)="$event.preventDefault();
               $event.stopPropagation(); downloadQuote(row)">
            <clr-icon shape="download"></clr-icon>
          </button>
        </ng-template>
      </ngx-datatable-column>

    </div>


    <div *ngIf="planningItemFilter.state === PlanningState.inactive">

      <ngx-datatable-column name="maintenance_inactive_date">
        <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
         <span (click)="sort()" class="pointer">
             Inactief van
        </span>
        </ng-template>
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{row.client.client_settings.maintenanceSettings.inactive_date | dateFormatOnlyDate}}
        </ng-template>
      </ngx-datatable-column>

    </div>

  </ngx-datatable>
</div>

<pr-planning-item-finish-modal *ngIf="planningItemFilter.state === PlanningState.planned"
                               [planningItem]="selectedPlanningItem"
                               [visible]="finishModal"
                               (cancel)="cancelFinish()"
                               (createPlanningItemReport)="finishPlannedPlanningItem($event)">
</pr-planning-item-finish-modal>


<pr-quote-approve-modal *ngIf="planningItemFilter.state === PlanningState.completedWithOffer"
                        (cancel)="cancelApproveQuote()"
                        (yes)="ApproveQuoteModal($event)"
                        [currentPlanningItem]="planningItemToApproveQuote"
                        [quote]="quoteToApprove"
                        [visible]="approveQuoteModal">
</pr-quote-approve-modal>
