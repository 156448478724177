
<clr-modal [(clrModalOpen)]="visible" [clrModalSize]="'lg'" *ngIf="this.quoteClient && this.quote">
  <h3 class="modal-title" style="font-weight: bold;">Goedkeuren offerte</h3>
  <div class="modal-body" *ngIf="visible">
    <div>
      <p class="header-title" >Klant / Lead details</p>
      <div class="row2">
        <div class="grid-item">
            <div class="full-length-screen" style="padding-right: 16px;">
              <label>{{quoteClient.client_data.first_name + ' ' + quoteClient.client_data.last_name}}</label>
            </div>
            <div class="full-length-screen" style="padding-right: 16px;">
              <label>{{quoteClient.client_data.email}}</label>
            </div>
            <div class="full-length-screen" style="padding-right: 16px;">
              <label>{{quoteClient.client_data.telephone_phone ? 'Tel nr: ' + quoteClient.client_data.telephone_phone : ''}}
                {{quoteClient.client_data.mobile_phone ? 'Gsm: ' + quoteClient.client_data.mobile_phone : ''}}</label>
            </div>
        </div>
        <div class="grid-item">
          <div class="full-length-screen" style="padding-right: 16px;">
            <label>{{quoteClient.client_data.main_address.address}}</label>
          </div>
          <div class="full-length-screen" style="padding-right: 16px;">
            <label>{{quoteClient.client_data.main_address.city}}
              {{quoteClient.client_data.main_address.zip_code}}</label>
          </div>
          <div class="full-length-screen" style="padding-right: 16px;">
            <label>{{quoteClient.client_data.main_address.countryName}} -
              {{quoteClient.client_data.main_address.country}}
              </label>
          </div>
          <div class="full-length-screen" style="padding-right: 16px;">
            <label>{{quoteClient.client_data.main_address.stateName}}</label>
          </div>
        </div>


      </div>
    </div>
<!--
    <hr class="solid" style="margin: 15px 0 15px 0">-->
    <p class="header-title">Offerte details</p>
    <div class="card-content" *ngIf="quoteClientFiche">
      <div class="grid-item">
       <!-- <p class="sub-title">Klant contact</p>-->
        <div class="info-row" *ngIf="quoteClient.state === ClientState.client">
          <div class="info-content lighter">Klant nummer:</div>
          <div class="info-content">
            {{quoteClient.client_id}}
          </div>
        </div>
        <div class="info-row">
          <div class="info-content lighter">Naam</div>
          <div>
            {{quoteClientFiche.first_name + quoteClientFiche.last_name}}
          </div>
        </div>

        <div class="info-row">
          <div class="info-content lighter">Email</div>
          <div>
            <label required="required" type="text" id="email">
              {{quoteClientFiche.email}}
            </label>
          </div>
        </div>

        <div class="info-row">
          <div class="info-content lighter">Gsm nummer</div>
          <div class="info-content">
            <label required="required" type="text" id="_mobile_phone">
              {{quoteClientFiche.mobile_phone}}
            </label>

          </div>
        </div>
        <div class="info-row">
          <div class="info-content lighter">Telefoon</div>
          <div>
            <label required="required" type="text" id="phone">
              {{quoteClientFiche.phone}}
            </label>

          </div>
        </div>
        <div class="info-row">
          <div class="info-content lighter">Straat</div>
          <div>
            <label required="required" type="text" id="adress_1">
              {{quoteClientFiche.address}}
            </label>
          </div>
        </div>
        <div class="info-row">
          <div class="info-content lighter">Postcode</div>
          <div>
            <label required="required" type="text" id="postcode" >
              {{quoteClientFiche.zip_code}}
            </label>
          </div>
        </div>
        <div class="info-row">
          <div class="info-content lighter">Gemeente</div>
          <div class="info-row">
            <div>
              <label required="required" type="text" id="city">
                {{quoteClientFiche.city}}
              </label>
              <label required="required" type="text" id="state">
              </label>
            </div>
          </div>
        </div>
        <div class="info-row">
          <div class="info-content lighter">Offerte prijs</div>
          <div class="info-row">
            <div>
              <label required="required" type="text" id="totalPrice" style="font-weight: bold">
                {{quote.data.quote_create.machineFiche.total}}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="icon-modal">
        <label class="clr-control-label">Gewenste Plaatsing datum</label>
      </div>
      <div class="group-section">
        <div class="group-element" style="width: 95%; margin-bottom: 6px;">
          <div class="full-length-screen" style="padding-right: 16px;" >
            <clr-date-container class="full-length-screen">
              <input type="date" [(clrDate)]="suggested_date" disabled>
            </clr-date-container>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="icon-modal">
        <label class="clr-control-label">Omschrijving</label>
      </div>
      <div class="group-section">
        <div class="group-element" style="width: 95%; margin-bottom: 6px;">
          <clr-textarea-container class="no-margin">
                <textarea clrTextarea
                          [(ngModel)]="planningItemToAdd.data.description"
                          name="description" class="full-length-screen no-border textarea-custom"
                          placeholder="beschrijving toevoegen">
              </textarea>
          </clr-textarea-container>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
      <button type="button" class="btn btn-outline" (click)="sendCancel()">Cancel</button>
      <button type="button" class="btn btn-outline" (click)="editQuote(quote)">Edit offerte</button>
      <button type="button" class="btn btn-primary" (click)="approveQuoteAndCreatePlacement()">Yes</button>
  </div>
</clr-modal>
