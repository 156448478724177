"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseICalendarCreateEventBodyEntityAttendees = void 0;
const icalendar_create_event_body_entity_email_address_1 = require("./icalendar-create-event-body-entity-email-address");
function parseICalendarCreateEventBodyEntityAttendees(data) {
    if (data.emailAddress !== null && typeof data.emailAddress === 'object')
        data.emailAddress = (0, icalendar_create_event_body_entity_email_address_1.parseICalendarCreateEventBodyEntityEmailAddress)(data.emailAddress);
    return data;
}
exports.parseICalendarCreateEventBodyEntityAttendees = parseICalendarCreateEventBodyEntityAttendees;
