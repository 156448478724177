import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ROUTING } from '../../app.routing';
import { MaintenancePageComponent } from './containers/maintenance-page/maintenance-page.component';
import { ClarityModule, ClrButtonGroupModule, ClrButtonModule, ClrTabsModule } from '@clr/angular';
import { MaintenanceToBePlannedTabComponent } from './containers/maintenance-to-be-planned-tab/maintenance-to-be-planned-tab.component';
import { MaintenancePlannedTabComponent } from './containers/maintenance-planned-tab/maintenance-planned-tab.component';
import { MaintenanceCompletedTabComponent } from './containers/maintenance-completed-tab/maintenance-completed-tab.component';

import { SharedModule } from '../shared/shared.module';
import { MaintenanceInactiveTabComponent } from './containers/maintenance-inactive-tab/maintenance-inactive-tab.component';
import { MaintenanceInvoicedTabComponent } from './containers/maintenance-invoiced-tab/maintenance-invoiced-tab.component';
import { CreateMaintenanceComponent } from './containers/create-maintenance/create-maintenance.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { BrowserModule } from '@angular/platform-browser';
import { PlanningItemModule } from '../planning-item/planning-item.module';

const components = [];

const containers = [
    MaintenancePageComponent,
    MaintenanceToBePlannedTabComponent,
    MaintenancePlannedTabComponent,
    MaintenanceCompletedTabComponent,
    MaintenanceInactiveTabComponent,
    MaintenanceInvoicedTabComponent,
    CreateMaintenanceComponent,
];

const imports = [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ROUTING,
    ClarityModule,
    ClrTabsModule,
    ClrButtonModule,
    SharedModule,
    ClrButtonGroupModule,
    NgxDatatableModule,
    BrowserModule,
    PlanningItemModule,
];

const sandboxes = [];

const services = [];

@NgModule({
    imports: [...imports],
    declarations: [...containers, ...components],
    providers: [...sandboxes, ...services],
    entryComponents: [],
    exports: [...containers],
})
export class MaintenanceModule {}
