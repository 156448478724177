import { IProductSearchFilterEntity } from '@phrospr/api-backend';

const basicSearchFilter: IProductSearchFilterEntity = {
    product_id: null,
    name: null,
    product_number: null,
};

export function validateProductItemSearchFilterKey(filterKey: string): boolean {
    return basicSearchFilter[filterKey] === null;
}
