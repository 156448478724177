"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseIProductEntity = void 0;
const iproduct_pricing_entity_1 = require("./iproduct-pricing-entity");
const iproduct_specifications_entity_1 = require("./iproduct-specifications-entity");
function parseIProductEntity(data) {
    if (typeof data.creation_date === 'string')
        data.creation_date = new Date(data.creation_date);
    if (data.specifications !== null && typeof data.specifications === 'object')
        data.specifications = (0, iproduct_specifications_entity_1.parseIProductSpecificationsEntity)(data.specifications);
    if (data.pricing !== null && typeof data.pricing === 'object')
        data.pricing = (0, iproduct_pricing_entity_1.parseIProductPricingEntity)(data.pricing);
    return data;
}
exports.parseIProductEntity = parseIProductEntity;
