import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { IClientEntity, IPlanningItemEntity } from '@phrospr/api-backend';
import { newPlanningItem } from '@phrospr/lib-core/src/engine/planning-item.engine';
import { newClientEntity } from '@phrospr/lib-core/src/engine/client.engine';

@Component({
    selector: 'pr-delivery-create',
    templateUrl: './create-delivery.component.html',
    styleUrls: ['./create-delivery.component.scss'],
})
export class CreateDeliveryComponent implements OnInit {
    @Output() planningItem = new EventEmitter<IPlanningItemEntity>();

    newPlanningItemDelivery: IPlanningItemEntity = newPlanningItem();

    selectedClient: IClientEntity = newClientEntity();

    constructor() {}

    ngOnInit(): void {}

    setClient(client: IClientEntity) {
        /* this.newDelivery.client_number = client.number;*/
        this.selectedClient = client;
        this.newPlanningItemDelivery.client_id = client.client_id;
        this.sendDeliveryToComponent();
    }

    sendDeliveryToComponent() {
        this.planningItem.emit(this.newPlanningItemDelivery);
    }
}
