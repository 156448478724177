
<div *ngIf="product">
  <div class="card-header">
    <a (click)="goBack()" class="lighter pointer">
      <clr-icon shape="caret" dir="left"></clr-icon>
      <p class="lighter" style="margin: 10px 0px">Terug</p>
    </a>
    <div class="border-bottom-header">
      <div>
        <div *ngIf="!createMode" class="bold shipment-external-id">
          Edit product
        </div>
        <div *ngIf="createMode" class="bold shipment-external-id">
          Aanmaak nieuw product
        </div>
      </div>
      <div class="command-bar" *ngIf="!createMode">
        <button class="btn btn-menu btn-sm" (click)="editMode = !editMode" *ngIf="!editMode && !loadingSave">
          <clr-icon shape="edit" class="button-icon"></clr-icon>
          Edit
        </button>
        <button class="btn btn-sm btn-success btn-group-prospr" (click)="updateProduct()"
                *ngIf="editMode && !loadingSave">Save
        </button>
        <button class="btn btn-sm btn-menu" (click)="cancelProductEdit()" *ngIf="editMode && !loadingSave">
          Cancel
        </button>
        <div class="spinner-menu" *ngIf="loadingSave">
         <span class="spinner spinner-inline">
           Loading...
         </span>
          <span>
            Saving...
          </span>
        </div>

      </div>
      <div class="command-bar" *ngIf="createMode">
        <button class="btn btn-sm btn-success btn-group-prospr" (click)="addProduct()"
                *ngIf="createMode && !loadingSave">Add Product
        </button>
        <button class="btn btn-sm btn-menu" (click)="goBack()"  *ngIf="createMode && !loadingSave">
          Cancel
        </button>
      </div>
    </div>
  </div>

  <div class="card-content detail-content">
    <div class="row2">
      <div class="card">
        <div class="title">
          <div class="grid-item">
            <p class="bold">Product details</p>
          </div>
        </div>
        <div class="card-content card-content-2">
          <div class="grid-item">
            <div class="info-row">
              <div class="info-content lighter">Product ID</div>
              <div>
                <label>{{product.product_id}}</label>
              </div>
            </div>
            <div class="info-row">
              <div class="info-content lighter">Product creatie datum</div>
              <div>
                <label>{{product.creation_date ? (product.creation_date | dateFormatOnlyDate) : ''}}</label>
              </div>
            </div>
            <div class="info-row">
              <div class="info-content lighter">Product nummer</div>
              <div>
                <label required="required" type="text" id="product_number_label" *ngIf="!this.editMode">
                  {{product.product_number ? product.product_number : ''}}
                </label>
                <input required="required" type="text" id="product_number"
                       placeholder="Product nummer" class="input-area" *ngIf="this.editMode"
                       [(ngModel)]="product.product_number " [ngModelOptions]="{standalone: true}">
              </div>
            </div>
            <div class="info-row">
              <div class="info-content lighter">Product naam</div>
              <div>
                <label required="required" type="text" id="product_name_label" *ngIf="!this.editMode">
                  {{product.name ? product.name : ''}}
                </label>
                <input required="required" type="text" id="product_name"
                       placeholder="Product naam"  *ngIf="this.editMode"
                       [(ngModel)]="product.name" [ngModelOptions]="{standalone: true}">
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="card">
        <div class="title">
          <div class="grid-item">
            <p class="bold">Prijs settings</p>
          </div>
        </div>
        <div class="card-content card-content-1" style="overflow-y: auto; max-height: 550px">

          <div class="grid-item">

            <div class="info-row">
              <div class="info-content lighter">Product Eenheid </div>
              <div>
                <label required="required" type="text" id="unit_amount_label" *ngIf="!this.editMode">
                  {{product.pricing.unit_amount ? product.pricing.unit_amount + ' ' +
                  (product.pricing.unit_type ? product.pricing.unit_type:'') : ''}}
                </label>
                <div class="unit-type-row">
                  <input required="required" type="number" id="unit_amount" style="width: fit-content; max-width: 70px"
                         *ngIf="this.editMode"
                         [(ngModel)]="product.pricing.unit_amount" [ngModelOptions]="{standalone: true}">
                  <div class="group-section" *ngIf="this.editMode">
                    <div class="group-element" style="width: 80%">
                      <div class="clr-select-wrapper full-length-screen" style="padding-right: 16px;">
                        <select id="creationType" [(ngModel)]="selectedUnitType" name="creationType"
                                class="clr-select group-element no-border full-length-screen">
                          <option *ngFor="let type of unitTypes" [ngValue]="type">
                            {{type}}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div class="info-row">
              <div class="info-content lighter">Prijs</div>
              <div>
                <label required="required" type="text" id="price_per_unit_label" *ngIf="!this.editMode">
                  {{product.pricing.unit_amount ? product.pricing.price_per_unit : ''}}
                </label>
                <input required="required" type="number" id="price_per_unit"
                       placeholder="Prijs" class="input-area" *ngIf="this.editMode"
                       [(ngModel)]="product.pricing.price_per_unit " [ngModelOptions]="{standalone: true}">
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>

    <div class="row2">
      <div  class="card">
        <div class="title">
          <div class="grid-item">
            <p class="bold">Product omschrijving</p>
          </div>
        </div>
        <div class="card-content card-content-2">
          <div class="grid-item">
            <div class="info-row">
              <div class="info-content lighter">Omschrijving</div>

              <clr-textarea-container disabled="editMode">
                <textarea clrTextarea
                          [(ngModel)]="product.specifications.description"
                          name="product-description" class="textarea-custom"
                          placeholder="Omschrijving toevoegen">
              </textarea>
              </clr-textarea-container>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

</div>


