import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { ICalendarCreateEventBodyEntity, ICalendarEventLite } from '@phrospr/lib-models';
import { BASE_URL } from '../../../../environments/environment';

@Injectable()
export class Office365Service {
    constructor(private http: HttpClient) {}

    public getAllUsersFromOffice365(): Observable<any> {
        return this.http.get(`${BASE_URL}api/office365/getAllUsers`);
    }

    public getEventsPlanningCalendar(from: Date, to: Date): Observable<any> {
        return this.http.get(`${BASE_URL}api/calendar/events?from=${from.toISOString()}&to=${to.toISOString()}`);
    }

    public async getEventsPlanningCalendarForUser(from: Date, to: Date, email: string): Promise<ICalendarEventLite[]> {
        return this.http
            .get<ICalendarEventLite[]>(
                `${BASE_URL}api/calendar/events?from=${from.toISOString()}&to=${to.toISOString()}&email=${email}`,
            )
            .toPromise();
    }

    public createCalendarEvent(createEventBody: ICalendarCreateEventBodyEntity): Observable<any> {
        return this.http.post(`${BASE_URL}api/office365/createCalendarEvent`, createEventBody);
    }
}
