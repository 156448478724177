import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as toastr from 'toastr';
import { APP_INSIGHTS_KEY } from '../environments/environment';
import { LeadManager } from './modules/shared/manager/lead.manager';
import { IFEUserEntity } from './modules/shared/manager/entity/user.entity';
import { logout } from './modules/shared/resources/oidc';
import { user$ } from './modules/shared/resources/oidc/phrospr-user';

declare const window: any;

@Component({
    selector: 'pr-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
    title = 'app';

    user: IFEUserEntity;
    user$ = user$;

    visible = false;

    constructor(public router: Router, public leadManager: LeadManager) {
        if (APP_INSIGHTS_KEY) {
            window.setInsights(APP_INSIGHTS_KEY);
        }
    }

    async ngOnInit(): Promise<void> {
        if (typeof Storage === 'undefined') {
            toastr.error('Your browser is not supported!');
            return;
        }
        await this.loadSite();
    }

    async loadSite() {
        try {
            this.user = await user$;
            console.log('starting app', this.user);
            await this.router.navigateByUrl('/home');
        } catch (err) {
            console.log('load site failed');
            console.error(err);
        }
    }

    async logout() {
        await logout();
    }

    ok() {
        this.visible = false;
    }

    cancel() {
        this.visible = false;
    }
}
