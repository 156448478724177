"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseICommentDataEntity = void 0;
const icomment_data_entity_contacted_info_1 = require("./icomment-data-entity-contacted-info");
function parseICommentDataEntity(data) {
    if (data.contacted_info !== null && typeof data.contacted_info === 'object')
        data.contacted_info = (0, icomment_data_entity_contacted_info_1.parseICommentDataEntityContactedInfo)(data.contacted_info);
    return data;
}
exports.parseICommentDataEntity = parseICommentDataEntity;
