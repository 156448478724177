<div>
  <div class="row calendar-header">
    <div class="col-md-4 grid-item">
      <div class="btn-group">
        <button
          class="btn btn-primary"
          mwlCalendarPreviousView
          [view]="view"
          [(viewDate)]="viewDate"
          (viewDateChange)="closeOpenMonthViewDay()"
        >
          <clr-icon shape="caret left"></clr-icon>
          Previous
        </button>
        <button
          class="btn btn-outline"
          mwlCalendarToday
          [(viewDate)]="viewDate"
          (click)="setToday()"
        >
          Today
        </button>
        <button
          class="btn btn-primary"
          mwlCalendarNextView
          [view]="view"
          [(viewDate)]="viewDate"
          (viewDateChange)="closeOpenMonthViewDay()"
        >
          Next
          <clr-icon shape="caret right"></clr-icon>
        </button>
      </div>
    </div>
    <div class="col-md-4 grid-item text-center">
      <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
    </div>
    <div class="col-md-4 grid-item text-right">
      <div class="btn-group">
        <button
          class="btn btn-primary active"
          (click)="setView(CalendarView.Week)"
          [class.active]="view === CalendarView.Week"
        >
          Week
        </button>
        <button
          class="btn btn-secondary"
          (click)="setView(CalendarView.Day)"
          [class.active]="view === CalendarView.Day"
        >
          Day
        </button>
      </div>
    </div>
  </div>
  <div class="calendar-layout ">
    <div class="wrapper" *ngIf="loading">
          <span class="spinner" >
          Loading...
      </span>
    </div>
    <div class="grid-item calendars">
      <div class="select-calendar-layout">
        <div class="grid-item"></div>
        <div class="grid-item">
          <div>Andere agenda's
          </div>
          <div *ngIf="userListDb$ | async; let users; ">
            <clr-checkbox-wrapper *ngFor="let user of users; let i = index">
              <input type="checkbox" clrCheckbox value="{{user}}" name="options" [ngModel]="user.visualize"
                     (ngModelChange)="loadUserCalendarData(user, $event)"/>
              <label [ngStyle]="{'border-bottom': '2px solid ' + user.data.secondary}">
                {{user.first_name}}
              </label>
            </clr-checkbox-wrapper>
          </div>
        </div>
      </div>
    </div>


    <div class="grid-item">
      <br/>
      <ng-template
        #weekViewHourSegmentTemplate
        let-segment="segment"
        let-locale="locale"
        let-segmentHeight="segmentHeight"
        let-isTimeLabel="isTimeLabel"
      >
        <div
          #segmentElement
          class="cal-hour-segment"
          [style.height.px]="segmentHeight"
          [class.cal-hour-start]="segment.isStart"
          [class.cal-after-hour-start]="!segment.isStart"
          [ngClass]="segment.cssClass"
        >
          <div class="cal-time" *ngIf="isTimeLabel">
            {{ segment.date | calendarDate:'weekViewHour':locale }}
          </div>
        </div>
      </ng-template>
      <div [ngSwitch]="view">
        <mwl-calendar-week-view
          *ngSwitchCase="CalendarView.Week"
          [viewDate]="viewDate"
          [events]="events"
          [hourSegmentTemplate]="weekViewHourSegmentTemplate"
          [weekStartsOn]="1"
          [dayStartHour]="6"
          [dayEndHour]="20"
          [refresh]="refresh"
          (eventClicked)="handleEvent('Clicked', $event.event)"
          (dayHeaderClicked)="viewDate = $event.day.date; view = 'day'"
          [locale]="locale"
        >
        </mwl-calendar-week-view>
        <mwl-calendar-day-view
          *ngSwitchCase="CalendarView.Day"
          [viewDate]="viewDate"
          [events]="events"
          [hourSegmentTemplate]="weekViewHourSegmentTemplate"
          [dayStartHour]="6"
          [dayEndHour]="20"
          [refresh]="refresh"
          (eventClicked)="handleEvent('Clicked', $event.event)"
          [locale]="locale"
        >
        </mwl-calendar-day-view>
      </div>
    </div>
  </div>


  <clr-modal [(clrModalOpen)]="openCreateModal" [clrModalSize]="'lg'" data-target="#modal" id="planMaintenanceModal"
             (close)="close()" [clrModalClosable]="false">
    <div class="modal-header">
      <button (close)="close()" type="button">
        <span aria-hidden="true"></span>
      </button>
    </div>
    <div class="modal-body">
      <div>
        <div class="title-div">
          <div class="title-input">
            <input *ngIf="modalData?.action === 'Clicked'" disabled
                   placeholder="Title toevoegen" name="title-Clicked" type="text" id="title-Clicked"
                   class="full-length-screen"
                   [(ngModel)]="modalData.event.title">
          </div>
        </div>
        <div class="row">
          <div class="icon-modal">
            <clr-icon shape="clock"></clr-icon>
          </div>
          <div class="group-section" *ngIf="modalData?.action === 'Clicked'">
            <input [(clrDate)]="modalData.event.start" disabled clrDate placeholder="From" [disabled]="true"
                   type="date" class="date-input group-element no-border">
            <div class=" group-element no-border">
              <label class="group-element" disabled style="vertical-align: baseline;">
                {{modalData.event.start.getHours() + ':' + modalData.event.start.getMinutes()  }}
              </label>
            </div>
            <div class="group-element"> -</div>
            <input [(clrDate)]="modalData.event.end" clrDate placeholder="To" disabled [disabled]=" "
                   type="date" class="date-input group-element no-border">
            <div class=" group-element no-border">
              <label class="group-element" disabled style="vertical-align: baseline;">
                {{modalData.event.end.getHours() + ':' + modalData.event.end.getMinutes()  }}
              </label>
            </div>
          </div>
        </div>
        <div>
          <div class="icon-modal">
            <clr-icon shape="calendar"></clr-icon>
          </div>
          <div class="group-section" *ngIf="modalData?.action === 'Clicked'">
            <div class="full-length-screen input-select">
              <div class="full-length-screen" style="padding-right: 16px;">
                <label class="group-element full-length-screen">{{modalData.event.user}} </label>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="icon-modal">
            <clr-icon shape="map-marker"></clr-icon>
          </div>
          <div class="group-section">
            <div class="group-element" style="width: 95%">
              <input *ngIf="modalData?.action === 'Clicked'" disabled placeholder="Locatie toevoegen"
                     name="location-clicked"
                     type="text" id="location-clicked"
                     [(ngModel)]="modalData.event.location"
                     class="full-length-screen no-border">
            </div>
          </div>
        </div>
        <div>
          <div class="icon-modal">

          </div>
          <div class="group-section">
            <div class="group-element" style="width: 95%">
              <clr-textarea-container class="no-margin">
                <textarea *ngIf="modalData?.action === 'Clicked'" clrTextarea [(ngModel)]="this.modalData.event.content"
                          name="description" class="full-length-screen no-border textarea-custom" disabled>
              </textarea>
              </clr-textarea-container>
            </div>
          </div>
        </div>
      </div>
      <!--   DON'T DELETE => USEFUL WHEN DEBUGGING
     <div>
         Action:
         <pre>{{ modalData?.action }}</pre>
       </div>
       <div>
         Event:
         <pre>{{ modalData?.event | json }}</pre>
       </div>-->
    </div>
    <div class="modal-footer">
      <button (click)="close()" class="btn btn-secondary" type="button">
        close
      </button>
    </div>
  </clr-modal>
</div>
