import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { LeadService } from '../services/lead.service';
import { IClientEntity } from '@phrospr/api-backend';
import { user$ } from '../resources/oidc/phrospr-user';

@Injectable({
    providedIn: 'root',
})
export class LeadManager {
    newLeadCount$: ReplaySubject<number> = new ReplaySubject<number>(1);

    constructor(private leadService: LeadService) {
        user$.then(user => {
            if (!user) {
                return;
            }
            // FIXME
            // getSocket().on('LEAD_NEW', (lead: ILeadCoreEntity) => this.processNewLead(lead));
            // getSocket().on('LEAD_VIEWED', (lead_id: number) => this.updateNewLeadCount());
            return this.updateNewLeadCount();
        });
    }

    public async updateNewLeadCount() {
        const isLoggedIn = await user$;
        if (!isLoggedIn) {
            return;
        }
        const newLeadCount = await this.leadService.getNewLeadCount().catch(err => {
            console.log('could not load new lead count');
            console.log(err);
        });
        this.newLeadCount$.next(newLeadCount as number);
    }

    public updateLead(lead: IClientEntity, planning_item_id: number, viewed?: boolean) {
        return this.leadService
            .updateLead(lead, planning_item_id, viewed)
            .toPromise()
            .then(updatedLead => {
                this.updateNewLeadCount();
                return updatedLead;
            });
    }
}
