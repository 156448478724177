<clr-main-container>
    <div *ngIf="router.url !== '/login' && user$ | async">
        <clr-header class="bg-aquagroup">
            <div class="branding" style="width: 250px">
                <a href="#" [routerLink]="['/home']" [class.active]="router.url === '/home' || router.url === '/'">
                    <img class="clr-icon" src="assets/images/AquagroupSealNoTextHeader.png" />
                    <span class="title">Aquagroup</span>
                </a>
            </div>
            <div class="header-nav">
                <div class="nav-link" style="padding-top: 8px">
                    <button class="btn btn-primary" style="background-color: #42d1ec" (click)="visible = true">
                        Create
                    </button>
                </div>
            </div>
            <div class="header-actions">
                <!--   <div class="dropdown-alert">
             <a href="..." class="nav-link nav-icon dropdown-alert-button">
               <clr-icon shape="bell" class="is-solid alert-red" ></clr-icon>
               <span class="badge">3</span>
             </a>
             <div class="dropdown-alert-content">
               <div>Test 1</div>
               <div>Test 2</div>
               <div>Test 3</div>
               <div>Test 4</div>
               <div>Test 5</div>
               <div>Test 6</div>
               <div>Test 7</div>
               <div>Test 8</div>
               <div>Test 9</div>
               <div>Test 10</div>
               <div>Test 11</div>
               <div>Test 12</div>
               <div>Test 13</div>
               <div>Test 14</div>
             </div>
           </div>-->

                <pr-planning-item-search-modal class="search-center-alignment"> </pr-planning-item-search-modal>

                <clr-dropdown class="dropdown bottom-right">
                    <button class="nav-text" clrDropdownToggle>
                        <clr-icon shape="user"></clr-icon>
                        {{ user.email }}
                    </button>
                    <div class="dropdown-menu">
                        <a
                            clrDropdownItem
                            href="#"
                            [routerLink]="['/preferences']"
                            [class.active]="router.url === '/preferences' || router.url === '/'"
                            >Preferences</a
                        >
                        <a
                            value=""
                            clrDropdownItem
                            href="#"
                            [routerLink]="['/userMaintenance']"
                            [class.active]="router.url === '/userMaintenance' || router.url === '/'"
                            >User Maintenance</a
                        >
                        <a clrDropdownItem (click)="logout()">Log out</a>
                    </div>
                </clr-dropdown>
            </div>
        </clr-header>
        <!--<nav class="sub-nav " [clr-nav-level]="1">
      <ul class="nav ">
        <li class="nav-item">
          <a class="nav-link" href="#" [routerLink]="['/home']"
             [class.active]="router.url==='/home' || router.url==='/'">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#" [routerLink]="['/maintenance']"
             [class.active]="router.url && router.url.indexOf('/maintenance') > -1">Onderhoud</a>
        </li>
        &lt;!&ndash;<li class="nav-item">&ndash;&gt;
        &lt;!&ndash;<a class="nav-link" href="#" [routerLink]="['/login']"&ndash;&gt;
        &lt;!&ndash;[class.active]="router.url && router.url.indexOf('/login') > -1">Login page</a>&ndash;&gt;
        &lt;!&ndash;</li>&ndash;&gt;
      </ul>
    </nav>-->
    </div>

    <div class="content-container">
        <clr-vertical-nav
            [clrVerticalNavCollapsible]="true"
            class="nav-trigger--bottom"
            [(clrVerticalNavCollapsed)]="collapsed"
            *ngIf="router.url !== '/login' && user$ | async"
        >
            <a
                clrVerticalNavLink
                class="nav-link"
                href="#"
                [routerLink]="['/home']"
                [class.active]="router.url === '/home' || router.url === '/'"
            >
                <clr-icon clrVerticalNavIcon shape="user"></clr-icon>
                Home
            </a>
            <a
                clrVerticalNavLink
                class="nav-link"
                href="#"
                [routerLink]="['/calendar-view']"
                [class.active]="router.url && router.url.indexOf('/calendar-view') > -1"
            >
                <clr-icon clrVerticalNavIcon shape="calendar"></clr-icon>
                Kalender
            </a>
            <a
                clrVerticalNavLink
                class="nav-link"
                href="#"
                [routerLink]="['/maintenance']"
                [class.active]="router.url && router.url.indexOf('/maintenance') > -1"
            >
                <clr-icon clrVerticalNavIcon shape="wrench"></clr-icon>
                Onderhoud
            </a>
            <a clrVerticalNavLink [routerLink]="['/leads']" routerLinkActive="active">
                <clr-icon clrVerticalNavIcon shape="happy-face"></clr-icon>
                Leads
                <span class="badge badge-danger">{{ leadManager.newLeadCount$ | async }}</span>
            </a>
            <a clrVerticalNavLink [routerLink]="['/repair']" routerLinkActive="active">
                <clr-icon clrVerticalNavIcon shape="warning-standard"></clr-icon>
                Depannage
            </a>
            <a clrVerticalNavLink [routerLink]="['/deliveries']" routerLinkActive="active">
                <clr-icon clrVerticalNavIcon shape="truck"></clr-icon>
                Leveringen
            </a>
            <a clrVerticalNavLink [routerLink]="['/placement']" routerLinkActive="active">
                <clr-icon clrVerticalNavIcon shape="crosshairs"></clr-icon>
                Plaatsing
            </a>

            <clr-vertical-nav-group routerLinkActive="active">
                <a clrVerticalNavLink [routerLink]="['/offer']" routerLinkActive="active">
                    <clr-icon clrVerticalNavIcon shape="bug"></clr-icon>
                    Offertes
                </a>
                <clr-vertical-nav-group-children>
                    <a clrVerticalNavLink [routerLink]="['/quote-stats-overview-page']" routerLinkActive="active">
                        Statistieken
                    </a>
                    <!-- <a clrVerticalNavLink  routerLink="./normal/snorlax" routerLinkActive="active">
            Snorlax
          </a>-->
                </clr-vertical-nav-group-children>
            </clr-vertical-nav-group>
            <a clrVerticalNavLink [routerLink]="['/sales']" routerLinkActive="active">
                <clr-icon clrVerticalNavIcon shape="euro"></clr-icon>
                Verkoop
            </a>
            <a clrVerticalNavLink [routerLink]="['/product']" routerLinkActive="active">
                <clr-icon clrVerticalNavIcon shape="bundle"></clr-icon>
                Producten
            </a>
            <a clrVerticalNavLink [routerLink]="['/client']" routerLinkActive="active">
                <clr-icon clrVerticalNavIcon shape="user"></clr-icon>
                Klant
            </a>
            <a [routerLink]="['/activity']" clrVerticalNavLink routerLinkActive="active">
                <clr-icon clrVerticalNavIcon shape="view-list"></clr-icon>
                Activiteit
            </a>
        </clr-vertical-nav>
        <main class="content-area">
            <router-outlet></router-outlet>
        </main>
    </div>
</clr-main-container>

<pr-create-modal [visible]="visible" (ok)="ok()" (cancel)="cancel()"> </pr-create-modal>
