<clr-modal [(clrModalOpen)]="visible" [clrModalClosable]="false" [clrModalSize]="'lg'" class="modal-content">
  <h4 class="modal-title">Create</h4>
  <div class="modal-body modal-body-min-height">
    <div class="type-header">
      <div>
        <div class="icon-modal">
          <label class="clr-control-label">Type</label>
        </div>
        <div class="group-section">
          <div class="group-element" style="width: 95%">
            <div class="clr-select-wrapper full-length-screen" style="padding-right: 16px;">
              <select id="creationType" [(ngModel)]="selectedType" name="creationType"
                      class="clr-select group-element no-border full-length-screen">
                <option *ngFor="let type of creationTypes" [ngValue]="type">
                  {{type}}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div *ngIf="visible && selectedType === 'Lead'">
      <pr-lead-create
        (lead)=setLeadObject($event)
      ></pr-lead-create>
    </div>
    <div *ngIf="visible && selectedType === 'Onderhoud'">
      <pr-maintenance-create></pr-maintenance-create>
    </div>
    <div *ngIf="visible && selectedType === 'Levering'">
      <pr-delivery-create
        (planningItem)="setPlanningObject($event)"
      ></pr-delivery-create>
    </div>
    <div *ngIf="visible && selectedType === 'Depannage'">
      <pr-repair-create
        (planningItem)="setPlanningObject($event)"
      ></pr-repair-create>
    </div>
    <div *ngIf="visible && selectedType === 'Plaatsing'">
      <pr-placement-create
        [clientSelectionVisible]="true"
        (planningItem)="setPlanningObject($event)"
      ></pr-placement-create>
    </div>

  </div>
  <div class="modal-footer">
    <button *ngIf="!this.loading" class="btn btn-sm btn-success" (click)="sendCreate()">Create</button>
    <div *ngIf="this.loading">
         <span class="spinner spinner-inline">
           Loading...
         </span>
      <span>
            Saving...
          </span>
    </div>
    <button class="btn btn-sm btn-link" (click)="sendCancel()">Cancel</button>
  </div>

</clr-modal>
