<div class="search-center-alignment">
  <div class="search search-input">
    <input id="search_input" type="text" placeholder="Search for keywords..." id="searchPlanningItemList"
           (ngModelChange)="searchPlanningItemField$.next(true)" [(ngModel)]="searchValue"
           on-focus="focus()" on-focusout="loseFocus()"
           style="width: 100%">
    <clr-icon shape="search"></clr-icon>
  </div>
</div>
<div [ngClass]="{'hide-dropdown-content': !showResults}" class="quick-search-dropdown"
     id="planningItemList">
  <div>
    <div class="quick-search-section-heading">
      Planning Items
    </div>
    <ul>
      <li *ngFor="let planningItem of planningItems">
        <a class="icon-container" (click)="goToPlanningItem(planningItem)">
          <pr-planning-item-type-image
          [planningItemType]="planningItem.planning_item_type"
          [leadType]="planningItem.lead ? planningItem.lead.lead_type: ''"
          [big]="false">
          </pr-planning-item-type-image>
          <span>{{(planningItem.planning_item_type | getNameOfPlanningItemTypePipe: 'nl')
          + '-' + planningItem.planning_item_type_number}}</span>
          <div class="quick-search-subtitle-container">
            <span class="quick-search-item-subtitle"
                  *ngIf="planningItem.planning_item_type !== PlanningItemType.lead">
              {{planningItem.client.client_data.first_name + ' ' + planningItem.client.client_data.last_name}}
            </span>
            <span class="quick-search-item-subtitle"
                  *ngIf="planningItem.planning_item_type === PlanningItemType.lead">
              {{planningItem.client.client_data.first_name + ' ' + planningItem.client.client_data.last_name}}
            </span>
          </div>
        </a>
      </li>
    </ul>
  </div>
  <div id="clientList" class="quick-search-result-group">
    <div class="quick-search-section-heading">
      Klanten
    </div>
    <div *ngIf="clients.length === 0" class="quick-search-no-results">
      No results.
    </div>
    <ul>
      <li *ngFor="let client of clients">
        <a (click)="goToClient(client)" class="icon-container">
          <clr-icon shape="user" class="quick-search-item-image"></clr-icon><!--
          <img src="assets/images/onderhoud3.png" class="quick-search-item-image"/>-->
          <span>{{client.client_data.first_name + ' ' + client.client_data.last_name}}</span>
          <div class="quick-search-subtitle-container">
            <span class="quick-search-item-subtitle">Klant nummer: {{client.exact_client_number +
            ' (client id: ' + client.client_id +  ')'}}</span>
          </div>
        </a>
      </li>
    </ul>
  </div>
  <div id="leadList" class="quick-search-result-group">
    <div class="quick-search-section-heading">
      Leads
    </div>
    <div *ngIf="leads.length === 0" class="quick-search-no-results">
      No results.
    </div>
    <ul>
      <li *ngFor="let lead of leads">
        <a (click)="goToLead(lead)" class="icon-container">
          <clr-icon shape="happy-face" class="quick-search-item-image"></clr-icon>
          <!--<img src="assets/images/onderhoud3.png" class="quick-search-item-image"/>-->
          <span>{{lead.client_data.first_name + ' ' + lead.client_data.last_name}}</span>
          <div class="quick-search-subtitle-container">
            <span class="quick-search-item-subtitle">
              <!--{{lead.lead_id ? ('Lead id: ' + lead.lead_id) : '' }}
              - -->
              {{lead.lead_data.lead_custom_id ? ((lead.lead_data.lead_type | getNameOfLeadTypePipe) + ': ' +
              lead.lead_data.lead_custom_id) +' (client id: ' + lead.client_id +  ')' : 'Client id: ' + lead.client_id}}
            </span>
          </div>
        </a>
      </li>
    </ul>
  </div>
</div>
