"use strict";
/* tslint:disable */
/* eslint-disable */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientApi = exports.ClientApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
// @ts-ignore
const models_1 = require("../models");
// @ts-ignore
const models_2 = require("../models");
/**
 * ClientApi - axios parameter creator
 * @export
 */
const ClientApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {IClientEntity} iClientEntity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addClient: (iClientEntity, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iClientEntity' is not null or undefined
            (0, common_1.assertParamExists)('addClient', 'iClientEntity', iClientEntity);
            const localVarPath = `/api/client/add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // http bearer authentication if accessToken is configured
            yield (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(iClientEntity, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {number} clientId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClient: (clientId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'clientId' is not null or undefined
            (0, common_1.assertParamExists)('getClient', 'clientId', clientId);
            const localVarPath = `/api/client/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // http bearer authentication if accessToken is configured
            yield (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration);
            if (clientId !== undefined) {
                localVarQueryParameter['client_id'] = clientId;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} clientNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientByClientNumber: (clientNumber, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'clientNumber' is not null or undefined
            (0, common_1.assertParamExists)('getClientByClientNumber', 'clientNumber', clientNumber);
            const localVarPath = `/api/client/getClientByClientNumber`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // http bearer authentication if accessToken is configured
            yield (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration);
            if (clientNumber !== undefined) {
                localVarQueryParameter['client_number'] = clientNumber;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {IClientFilterEntity} iClientFilterEntity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClients: (iClientFilterEntity, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iClientFilterEntity' is not null or undefined
            (0, common_1.assertParamExists)('listClients', 'iClientFilterEntity', iClientFilterEntity);
            const localVarPath = `/api/client/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // http bearer authentication if accessToken is configured
            yield (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(iClientFilterEntity, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {number} clientId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setClientMaintenanceActive: (clientId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'clientId' is not null or undefined
            (0, common_1.assertParamExists)('setClientMaintenanceActive', 'clientId', clientId);
            const localVarPath = `/api/client/setClientMaintenanceActive`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // http bearer authentication if accessToken is configured
            yield (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration);
            if (clientId !== undefined) {
                localVarQueryParameter['client_id'] = clientId;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {number} clientId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setClientMaintenanceInActive: (clientId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'clientId' is not null or undefined
            (0, common_1.assertParamExists)('setClientMaintenanceInActive', 'clientId', clientId);
            const localVarPath = `/api/client/setClientMaintenanceInActive`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // http bearer authentication if accessToken is configured
            yield (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration);
            if (clientId !== undefined) {
                localVarQueryParameter['client_id'] = clientId;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {IClientEntity} iClientEntity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClient: (iClientEntity, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iClientEntity' is not null or undefined
            (0, common_1.assertParamExists)('updateClient', 'iClientEntity', iClientEntity);
            const localVarPath = `/api/client/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // http bearer authentication if accessToken is configured
            yield (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(iClientEntity, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.ClientApiAxiosParamCreator = ClientApiAxiosParamCreator;
/**
 * ClientApi -
 */
var ClientApi;
(function (ClientApi) {
    /**
     *
     * @param {IClientEntity} iClientEntity
     * @param {*} [options] Override http request option.
     * @param {*} [configuration] Override the defaultConfiguration
     * @throws {RequiredError}
     * @memberof ClientApi
     * url /api/client/add
     */
    function addClient(iClientEntity, options, configuration = common_1.defaultConfiguration) {
        return __awaiter(this, void 0, void 0, function* () {
            const localVarAxiosParamCreator = (0, exports.ClientApiAxiosParamCreator)(configuration);
            const localVarAxiosArgs = yield localVarAxiosParamCreator.addClient(iClientEntity, options);
            const axiosRequest = (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            return axiosRequest(axios_1.default, (0, common_1.getBasePath)(configuration))
                .then(res => {
                res.data = (0, models_1.parseIClientEntity)(res.data);
                return res;
            });
        });
    }
    ClientApi.addClient = addClient;
    /**
     *
     * @param {number} clientId
     * @param {*} [options] Override http request option.
     * @param {*} [configuration] Override the defaultConfiguration
     * @throws {RequiredError}
     * @memberof ClientApi
     * url /api/client/get
     */
    function getClient(clientId, options, configuration = common_1.defaultConfiguration) {
        return __awaiter(this, void 0, void 0, function* () {
            const localVarAxiosParamCreator = (0, exports.ClientApiAxiosParamCreator)(configuration);
            const localVarAxiosArgs = yield localVarAxiosParamCreator.getClient(clientId, options);
            const axiosRequest = (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            return axiosRequest(axios_1.default, (0, common_1.getBasePath)(configuration))
                .then(res => {
                res.data = (0, models_1.parseIClientEntity)(res.data);
                return res;
            });
        });
    }
    ClientApi.getClient = getClient;
    /**
     *
     * @param {string} clientNumber
     * @param {*} [options] Override http request option.
     * @param {*} [configuration] Override the defaultConfiguration
     * @throws {RequiredError}
     * @memberof ClientApi
     * url /api/client/getClientByClientNumber
     */
    function getClientByClientNumber(clientNumber, options, configuration = common_1.defaultConfiguration) {
        return __awaiter(this, void 0, void 0, function* () {
            const localVarAxiosParamCreator = (0, exports.ClientApiAxiosParamCreator)(configuration);
            const localVarAxiosArgs = yield localVarAxiosParamCreator.getClientByClientNumber(clientNumber, options);
            const axiosRequest = (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            return axiosRequest(axios_1.default, (0, common_1.getBasePath)(configuration))
                .then(res => {
                res.data = (0, models_1.parseIClientEntity)(res.data);
                return res;
            });
        });
    }
    ClientApi.getClientByClientNumber = getClientByClientNumber;
    /**
     *
     * @param {IClientFilterEntity} iClientFilterEntity
     * @param {*} [options] Override http request option.
     * @param {*} [configuration] Override the defaultConfiguration
     * @throws {RequiredError}
     * @memberof ClientApi
     * url /api/client/list
     */
    function listClients(iClientFilterEntity, options, configuration = common_1.defaultConfiguration) {
        return __awaiter(this, void 0, void 0, function* () {
            const localVarAxiosParamCreator = (0, exports.ClientApiAxiosParamCreator)(configuration);
            const localVarAxiosArgs = yield localVarAxiosParamCreator.listClients(iClientFilterEntity, options);
            const axiosRequest = (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            return axiosRequest(axios_1.default, (0, common_1.getBasePath)(configuration))
                .then(res => {
                res.data = (0, models_2.parseInlineResponse2003)(res.data);
                return res;
            });
        });
    }
    ClientApi.listClients = listClients;
    /**
     *
     * @param {number} clientId
     * @param {*} [options] Override http request option.
     * @param {*} [configuration] Override the defaultConfiguration
     * @throws {RequiredError}
     * @memberof ClientApi
     * url /api/client/setClientMaintenanceActive
     */
    function setClientMaintenanceActive(clientId, options, configuration = common_1.defaultConfiguration) {
        return __awaiter(this, void 0, void 0, function* () {
            const localVarAxiosParamCreator = (0, exports.ClientApiAxiosParamCreator)(configuration);
            const localVarAxiosArgs = yield localVarAxiosParamCreator.setClientMaintenanceActive(clientId, options);
            const axiosRequest = (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            return axiosRequest(axios_1.default, (0, common_1.getBasePath)(configuration));
        });
    }
    ClientApi.setClientMaintenanceActive = setClientMaintenanceActive;
    /**
     *
     * @param {number} clientId
     * @param {*} [options] Override http request option.
     * @param {*} [configuration] Override the defaultConfiguration
     * @throws {RequiredError}
     * @memberof ClientApi
     * url /api/client/setClientMaintenanceInActive
     */
    function setClientMaintenanceInActive(clientId, options, configuration = common_1.defaultConfiguration) {
        return __awaiter(this, void 0, void 0, function* () {
            const localVarAxiosParamCreator = (0, exports.ClientApiAxiosParamCreator)(configuration);
            const localVarAxiosArgs = yield localVarAxiosParamCreator.setClientMaintenanceInActive(clientId, options);
            const axiosRequest = (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            return axiosRequest(axios_1.default, (0, common_1.getBasePath)(configuration));
        });
    }
    ClientApi.setClientMaintenanceInActive = setClientMaintenanceInActive;
    /**
     *
     * @param {IClientEntity} iClientEntity
     * @param {*} [options] Override http request option.
     * @param {*} [configuration] Override the defaultConfiguration
     * @throws {RequiredError}
     * @memberof ClientApi
     * url /api/client/update
     */
    function updateClient(iClientEntity, options, configuration = common_1.defaultConfiguration) {
        return __awaiter(this, void 0, void 0, function* () {
            const localVarAxiosParamCreator = (0, exports.ClientApiAxiosParamCreator)(configuration);
            const localVarAxiosArgs = yield localVarAxiosParamCreator.updateClient(iClientEntity, options);
            const axiosRequest = (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            return axiosRequest(axios_1.default, (0, common_1.getBasePath)(configuration));
        });
    }
    ClientApi.updateClient = updateClient;
})(ClientApi = exports.ClientApi || (exports.ClientApi = {}));
