import { IQuoteSearchFilterEntity } from '@phrospr/api-backend';

const basicSearchFilter: IQuoteSearchFilterEntity = {
    // keep this up to date with filtercorentity
    planning_item_type_number: null,
    from_created_date: null,
    to_created_date: null,
    client_number: null,
    client_name: null,
    postcode: null,
    lead_name: null,
    quote_id: null,
    created_by_user_name: null,
    sold_by_user_name: null,
};

export function validateQuoteItemSearchFilterKey(filterKey: string): boolean {
    return basicSearchFilter[filterKey] === null;
}
