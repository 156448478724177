import axios from 'axios';
import { IConfiguration } from '@phrospr/lib-models';

export let conf: IConfiguration;
export const conf$: Promise<IConfiguration> = new Promise((resolve, reject) => {
    // frontend is expected to be hosted under the backend
    axios
        .get(`${window.location.origin}/api/.configuration`)
        .then(x => {
            conf = x.data;
            resolve(conf);
        })
        .catch(_ => {
            console.error('failed to fetch the configuration, returning local settings');
            if (location.origin.startsWith('http://localhost')) {
                conf = {
                    keycloakUrl: 'http://localhost:3001/realms/phrospr',
                    apiUrl: 'http://localhost:3000',
                };
                return resolve(conf);
            }
            reject();
        });
});
