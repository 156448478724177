import { Configuration, ProtocolMode, BrowserCacheLocation } from '@azure/msal-browser';
import { conf$ } from '../configuration';

export const msalConfig$: Promise<Configuration> = (async function () {
    const conf = await conf$;
    const msalConfig: Configuration = {
        auth: {
            clientId: 'phrospr_api',
            authority: conf.keycloakUrl,
            knownAuthorities: [conf.keycloakUrl.replace(/https?:\/\/([^\/]+)\/.*/, '$1')],
            protocolMode: ProtocolMode.OIDC,
            redirectUri: window.location.origin + '/#/callback',
            postLogoutRedirectUri: window.location.origin,
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
        },
    };
    return msalConfig;
})();
