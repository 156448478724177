import { CalendarEventTitleFormatter } from 'angular-calendar';
import { IMyCalendarEvent } from '../../../plan-calendar-item/containers/plan-calendar-item-page/plan-calendar-item-page.component';

export class CustomEventTitleFormatter extends CalendarEventTitleFormatter {
    // you can override any of the methods defined in the parent class

    monthTooltip(event: IMyCalendarEvent, title: string): string {
        return event.user + ' ' + title + ' ' + location;
    }

    weekTooltip(event: IMyCalendarEvent, title: string): string {
        return event.user + ' ' + title + ' ' + location;
    }

    dayTooltip(event: IMyCalendarEvent, title: string): string {
        return event.user + ' ' + title + ' ' + location;
    }
}
