<div>
  <div class="row calendar-header">
    <div class="col-md-4 grid-item">
      <div class="btn-group">
        <button (click)="previous(periodSelected)"
                class="btn btn-primary">
          <clr-icon shape="caret left"></clr-icon>
          Previous
        </button>
        <button (click)="currentPeriod(periodSelected)"
                class="btn btn-outline">
          This {{ periodSelected}}
        </button>
        <button (click)="next(periodSelected)"
                class="btn btn-primary">
          Next
          <clr-icon shape="caret right"></clr-icon>
        </button>
      </div>
    </div>
    <div class="col-md-4 grid-item text-center">
      <h3>{{ fromDate | dateFormatOnlyDate }} - {{ toDate | dateFormatOnlyDate  }}</h3>
      <div *ngIf="periodSelected==='month'">{{fromDate | dateFormatOnlyMonthName}}</div>
      <div *ngIf="periodSelected==='week'">Weeknr: {{fromDate | dateFormatGetWeekNumber}}</div>
    </div>
    <div class="col-md-4 grid-item text-right">
      <div class="btn-group">
        <div (click)="setSelectedPeriod(QuoteRequestPeriod.year)" class="btn"
             [class.btn-secondary]="periodSelected==='year'"
             [class.btn-primary]="periodSelected!=='year'">
          Year
        </div>
        <div (click)="setSelectedPeriod(QuoteRequestPeriod.month)" class="btn"
             [class.btn-secondary]="periodSelected==='month'"
             [class.btn-primary]="periodSelected!=='month'">
          Month
        </div>
        <button class="btn" (click)="setSelectedPeriod(QuoteRequestPeriod.week)"
                [class.btn-secondary]="(periodSelected==='week')"
                [class.btn-primary]="periodSelected!=='week'">
          Week
        </button>
        <button (click)="setSelectedPeriod(QuoteRequestPeriod.day)" [class.btn-primary]="periodSelected!=='day'"
                [class.btn-secondary]="(periodSelected==='day')"
                class="btn">
          Day
        </button>
      </div>
    </div>
  </div>

</div>


<!--  (page)="pageCallback($event)"-->
<!--  (sort)="sortCallback($event)"-->
<!--  [rowClass]="getRowClass"-->
<!--  [reorderable]="reorderable"-->
<!--  [externalPaging]="false"-->
<!--  [externalSorting]="false"-->

<!--  [loadingIndicator]="loading"-->
<ngx-datatable [columnMode]="'flex'"
               [count]="page.count"
               [externalSorting]="false"
               [footerHeight]="50"
               [headerHeight]="50"
               [reorderable]="true"
               [rowClass]="getRowClass"
               [rowHeight]="30"
               [rows]="quoteOverViewPerUserPerPeriod"
               [selectionType]="'single'"
               [sortType]="'single'"
               (sort)="sortCallback($event)"
               class="material striped"
               id="quote-stats-table"
>
  <ngx-datatable-column [flexGrow]="2" [sortable]="true" name="user_name">
    <ng-template let-column="column"  let-sort="sortFn" ngx-datatable-header-template>
      <span (click)="sort()" class="pointer">User</span>
    </ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{row.sold_by_user.first_name}} {{row.sold_by_user.last_name ? row.sold_by_user.last_name : ''}}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column [flexGrow]="1" [sortable]="true" name="total_sum">
    <ng-template let-column="column" ngx-datatable-header-template>
      <span>Total in €</span>
    </ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{row.created_sum || row.approved_sum ? (row.created_sum + row.approved_sum) : 0}} EUR
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column [flexGrow]="1" [sortable]="true" name="count_of_quotes_total">
    <ng-template let-column="column" ngx-datatable-header-template>
      <span>#Offertes total</span>
    </ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{row.count_of_quotes_created || row.count_of_quotes_approved
      ?  getTotalCountOfQuotes(row.count_of_quotes_created, row.count_of_quotes_approved) : 0}}
    </ng-template>
  </ngx-datatable-column>


  <ngx-datatable-column [flexGrow]="1" [sortable]="true" name="created_sum">
    <ng-template let-column="column" ngx-datatable-header-template>
      <span>Created in €</span>
    </ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{row.created_sum ? row.created_sum : 0}} EUR
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column [flexGrow]="1" [sortable]="true" name="count_of_quotes_created">
    <ng-template let-column="column" ngx-datatable-header-template>
      <span>#Offertes Created</span>
    </ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{row.count_of_quotes_created ? row.count_of_quotes_created : 0}}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column [flexGrow]="1" [sortable]="true" name="approved_sum">
  <ng-template let-column="column" ngx-datatable-header-template>
    <span>Verkocht in €</span>
  </ng-template>
  <ng-template let-row="row" ngx-datatable-cell-template>
    {{row.approved_sum ? row.approved_sum : 0}} EUR
  </ng-template>
</ngx-datatable-column>

  <ngx-datatable-column [flexGrow]="1" [sortable]="true" name="count_of_quotes_approved">
    <ng-template let-column="column" ngx-datatable-header-template>
      <span>#Offertes goedgekeurd</span>
    </ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{row.count_of_quotes_approved ? row.count_of_quotes_approved : 0}}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column [flexGrow]="1" [sortable]="true" name="cancelled_sum">
    <ng-template let-column="column" ngx-datatable-header-template>
      <span>Cancelled in €</span>
    </ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{row.cancelled_sum ? row.cancelled_sum: 0}} EUR
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column [flexGrow]="1" [sortable]="true" name="count_of_quotes_cancelled">
    <ng-template let-column="column" ngx-datatable-header-template>
      <span>#Offertes cancelled</span>
    </ng-template>
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{row.count_of_quotes_cancelled ? row.count_of_quotes_cancelled : 0}}
    </ng-template>
  </ngx-datatable-column>

</ngx-datatable>




