import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ROUTING } from '../../app.routing';
import { SharedModule } from '../shared/shared.module';
import { ClarityModule, ClrButtonGroupModule } from '@clr/angular';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { BrowserModule } from '@angular/platform-browser';
import { PlanningItemModule } from '../planning-item/planning-item.module';
import { RepairPageComponent } from './containers/repair-page/repair-page.component';
import { CreateRepairComponent } from './containers/create-repair/create-repair.component';
import { NewRepairPageComponent } from './containers/new-repair-page/new-repair-page.component';
import { CompletedRepairPageComponent } from './containers/completed-repair-page/completed-repair-page.component';
import { PlannedRepairPageComponent } from './containers/planned-repair-page/planned-repair-page.component';

const components = [];

const containers = [
    RepairPageComponent,
    CreateRepairComponent,
    NewRepairPageComponent,
    PlannedRepairPageComponent,
    CompletedRepairPageComponent,
];

const imports = [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ROUTING,
    SharedModule,
    ClarityModule,
    ClrButtonGroupModule,
    NgxDatatableModule,
    BrowserModule,
    PlanningItemModule,
];

const sandboxes = [];

const services = [];

@NgModule({
    imports: [...imports],
    declarations: [...containers, ...components],
    providers: [...sandboxes, ...services],
    entryComponents: [],
    exports: [...containers],
})
export class RepairModule {}
