import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import 'flatpickr/dist/flatpickr.css'; // you may need to adjust the css import depending on your build tool
import { AppComponent } from './app.component';
import { ROUTING } from './app.routing';
import { FormsModule } from '@angular/forms';
import { MatButtonModule, MatButtonToggleModule, MatCardModule, MatCheckboxModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './modules/shared/shared.module';
import { HomePageComponent } from './containers/home-page/home-page.component';
import { ClarityModule } from '@clr/angular';
import { PreferencesModule } from './modules/preferences/preferences.module';
import { MaintenanceModule } from './modules/maintenance/maintenance.module';
import { UserMaintenanceModule } from './modules/userMaintenance/userMaintenance.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { PlanCalendarItemModule } from './modules/plan-calendar-item/plan-calendar-item.module';
import { FlatpickrModule } from 'angularx-flatpickr';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { ClientModule } from './modules/client/client.module';
import { SalesModule } from './modules/sales-page/sales.module';
import { LeadsModule } from './modules/leads/leads.module';
import { CalendarViewModule } from './modules/calendar/containers/calendar-view.module';
import { CreateModalComponent } from './containers/create-modal/create-modal.component';
import { AuthenticationInterceptor } from './modules/shared/interceptors/authentication.interceptor';
import { DeliveryModule } from './modules/delivery/delivery.module';
import { PlanningItemModule } from './modules/planning-item/planning-item.module';
import { RepairModule } from './modules/repair/repair.module';
import { PlacementModule } from './modules/placement/placement.module';
import { QuoteModule } from './modules/quote/quote.module';
import { LoadingPageComponent } from './containers/loading-page/loading-page.component';
import { ActivityModule } from './modules/activity/activity.module';
import { ProductModule } from './modules/product/product.module';

@NgModule({
    declarations: [AppComponent, HomePageComponent, CreateModalComponent, LoadingPageComponent],
    imports: [
        BrowserModule,
        ROUTING,
        FormsModule,
        MatCardModule,
        BrowserAnimationsModule,
        MatButtonModule,
        MatCheckboxModule,
        MaintenanceModule,
        SharedModule,
        PlanningItemModule,
        MatButtonToggleModule,
        ClarityModule,
        PreferencesModule,
        UserMaintenanceModule,
        PlanCalendarItemModule,
        HttpClientModule,
        FlatpickrModule.forRoot(),
        NgbModalModule,
        ClientModule,
        QuoteModule,
        SalesModule,
        LeadsModule,
        CalendarViewModule,
        DeliveryModule,
        RepairModule,
        PlacementModule,
        ActivityModule,
        ProductModule,
    ],
    providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true }],
    bootstrap: [AppComponent],
})
export class AppModule {}
