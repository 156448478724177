"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseICalendarEventStatus = exports.ICalendarEventStatusResponseEnum = exports.ICalendarEventStatusTimeEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var ICalendarEventStatusTimeEnum;
(function (ICalendarEventStatusTimeEnum) {
    ICalendarEventStatusTimeEnum["_00010101T000000Z"] = "0001-01-01T00:00:00Z";
})(ICalendarEventStatusTimeEnum = exports.ICalendarEventStatusTimeEnum || (exports.ICalendarEventStatusTimeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ICalendarEventStatusResponseEnum;
(function (ICalendarEventStatusResponseEnum) {
    ICalendarEventStatusResponseEnum["None"] = "none";
})(ICalendarEventStatusResponseEnum = exports.ICalendarEventStatusResponseEnum || (exports.ICalendarEventStatusResponseEnum = {}));
function parseICalendarEventStatus(data) {
    return data;
}
exports.parseICalendarEventStatus = parseICalendarEventStatus;
