import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LeadManager } from '../../modules/shared/manager/lead.manager';
import * as toastr from 'toastr';
import { IClientEntity, IPlanningItemEntity, PlanningItemType, PlanningState } from '@phrospr/api-backend';
import { newClientEntity } from '@phrospr/lib-core/src/engine/client.engine';
import { newPlanningItem } from '@phrospr/lib-core/src/engine/planning-item.engine';
import { PhrosprPortalApi } from '../../../resource/phrospr-api';

@Component({
    selector: 'pr-create-modal',
    templateUrl: './create-modal.component.html',
    styleUrls: ['./create-modal.component.scss'],
})
export class CreateModalComponent {
    creationTypes: string[] = ['Lead', 'Levering', 'Depannage', 'Plaatsing'];
    selectedType = 'Lead';

    planningItemToAdd: IPlanningItemEntity = newPlanningItem();
    leadToAdd: IClientEntity = newClientEntity(true);

    loading = false;

    @Input() visible;

    @Output() ok = new EventEmitter<any>();
    @Output() cancel = new EventEmitter<any>();

    constructor(private leadManager: LeadManager) {}

    sendCreate() {
        // todo This is a dirty fix that still needs to be changed
        this.loading = true;
        if (this.selectedType === 'Lead') {
            if (
                !this.leadToAdd ||
                !this.leadToAdd.lead_data.lead_type ||
                !this.leadToAdd.client_data.first_name ||
                !this.leadToAdd.client_data.last_name ||
                !this.leadToAdd.lead_data.description
            ) {
                toastr.error('Volgende velden zijn verplicht: Leadtype, Voornaam, Achternaam, omschrijving');
                this.loading = false;
            } else {
                this.leadToAdd.lead_data.created_at.date = new Date();
                PhrosprPortalApi.LeadApi.createManualLead(this.leadToAdd).then(_ => {
                    this.ok.emit();
                    this.clearAndHideModal();
                });
            }
        } else if (
            this.selectedType === 'Levering' ||
            this.selectedType === 'Depannage' ||
            this.selectedType === 'Plaatsing'
        ) {
            if (
                !this.planningItemToAdd ||
                !this.planningItemToAdd.data.description ||
                !this.planningItemToAdd.client_id ||
                !this.planningItemToAdd.data.suggested_date
            ) {
                toastr.error('Volgende velden zijn verplicht: Klant, leverdatum, omschrijving');
                this.loading = false;
            } else {
                if (this.selectedType === 'Levering') {
                    this.planningItemToAdd.planning_item_type = PlanningItemType.delivery;
                } else if (this.selectedType === 'Depannage') {
                    this.planningItemToAdd.planning_item_type = PlanningItemType.repair;
                } else if (this.selectedType === 'Plaatsing') {
                    this.planningItemToAdd.planning_item_type = PlanningItemType.placement;
                } else {
                    toastr.error('Geen duidelijk type gekozen');
                    this.loading = false;
                }
                this.planningItemToAdd.state = PlanningState.toBePlanned;
                PhrosprPortalApi.PlanningItemApi.createPlanningItem({ planningItem: this.planningItemToAdd }).then(
                    _ => {
                        this.ok.emit();
                        this.clearAndHideModal();
                    },
                );
            }
        } else {
            toastr.error('Dit is nog niet inbegrepen');
            this.loading = false;
        }
    }

    sendCancel() {
        this.clearAndHideModal();
        this.cancel.emit();
    }

    setLeadObject(lead: IClientEntity) {
        if (this.selectedType === 'Lead') {
            this.leadToAdd = lead;
        }
    }

    setPlanningObject(planningItem: IPlanningItemEntity) {
        if (
            this.selectedType === 'Levering' ||
            this.selectedType === 'Depannage' ||
            this.selectedType === 'Plaatsing'
        ) {
            this.planningItemToAdd = planningItem;
        }
    }

    clearAndHideModal() {
        this.selectedType = 'Lead';
        this.planningItemToAdd = newPlanningItem();
        this.leadToAdd = null;
        this.visible = false;
        this.loading = false;
    }
}
