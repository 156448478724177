<div *ngIf="!clientDB">Could not load client</div>

<div *ngIf="clientDB">
    <div class="card-header">
        <a (click)="backToOverview()" class="lighter pointer">
            <clr-icon shape="caret" dir="left"></clr-icon>
            <p class="lighter" style="margin: 10px 0px">Terug</p>
        </a>
        <div class="border-bottom-header">
            <div class="bold shipment-external-id" *ngIf="!createMode">
                {{ clientDB.client_data.first_name + ' ' + clientDB.client_data.last_name }}
                : {{ clientDB.client_id }}

                <div class="row">
                    <div *ngIf="clientDB.state === ClientState.client" class="maintenance-inactive">
                        <span class="label label-info">CLIENT</span>
                    </div>
                    <div *ngIf="clientDB.state === ClientState.lead" class="maintenance-inactive">
                        <span class="label label-info">LEAD</span>
                    </div>
                </div>
            </div>
            <div class="command-bar" *ngIf="!createMode">
                <button class="btn btn-menu btn-sm" (click)="editMode = !editMode" *ngIf="!editMode && !loadingSave">
                    <clr-icon shape="edit" class="button-icon"></clr-icon>
                    Edit
                </button>
                <button
                    class="btn btn-sm btn-success btn-group-prospr"
                    (click)="updateClient()"
                    *ngIf="editMode && !loadingSave"
                >
                    Save
                </button>
                <button
                    class="btn btn-sm btn-success btn-group-prospr"
                    (click)="addClient()"
                    *ngIf="createMode && !loadingSave"
                >
                    Add Client
                </button>
                <button class="btn btn-sm btn-menu" (click)="cancelClientEdit()" *ngIf="editMode && !loadingSave">
                    Cancel
                </button>
                <div class="spinner-menu" *ngIf="loadingSave">
                    <span class="spinner spinner-inline"> Loading... </span>
                    <span> Saving... </span>
                </div>

                <!-- <button class="btn btn-group-prospr btn-menu btn-sm">
          test button
        </button>-->
                <!--<div class="btn btn-group-prospr btn-menu btn-sm">Other action</div>
        <div class="btn btn-menu btn-sm">Other action2</div>-->
                <clr-dropdown>
                    <button class="btn btn-outline-primary btn-menu btn-sm" clrDropdownTrigger type="button">
                        Meer
                        <clr-icon shape="caret down"></clr-icon>
                    </button>
                    <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
                        <!--<button clrDropdownItem type="button">Action 1</button>
            <button clrDropdownItem disabled type="button">Disabled Action</button>-->
                        <div class="dropdown-divider"></div>
                        <clr-dropdown>
                            <button clrDropdownItem type="button" disabled>Set inactive</button>
                        </clr-dropdown>

                        <button clrDropdownItem type="button" (click)="createOffer()">Maak offerte</button>
                        <button clrDropdownItem type="button" [disabled]="clientDB.state === ClientState.client">
                            <!-- <clr-icon shape="history" dir="left" class="button-icon"></clr-icon>-->
                            Protest Lead
                        </button>
                    </clr-dropdown-menu>
                </clr-dropdown>
                <clr-dropdown class="command-bar-right">
                    <button class="btn btn-outline-primary btn-menu btn-sm" clrDropdownTrigger type="button">
                        <clr-icon shape="download"></clr-icon>
                        Export
                        <clr-icon shape="caret down"></clr-icon>
                    </button>
                    <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
                        <button clrDropdownItem type="button" disabled>Printable</button>
                        <button clrDropdownItem type="button" disabled>JSON</button>
                        <!--<div class="dropdown-divider"></div>
            <button clrDropdownItem type="button" disabled>Extra action</button>-->
                    </clr-dropdown-menu>
                </clr-dropdown>
            </div>
            <div class="command-bar" *ngIf="createMode">
                <button
                    class="btn btn-sm btn-success btn-group-prospr"
                    (click)="addClient()"
                    *ngIf="createMode && !loadingSave"
                >
                    Add Client
                </button>
                <button class="btn btn-sm btn-menu" (click)="backToOverview()" *ngIf="createMode && !loadingSave">
                    Cancel
                </button>
            </div>
        </div>

        <div class="sub-header">
            <!--<p class="bold">Test Test</p>
      &lt;!&ndash; <a [routerLink]="['/maintenance-history']" class="lighter"
          [class.active]="router.url==='/maintenance-history' || router.url==='/'">
         <clr-icon shape="history" dir="left"></clr-icon>
         <p class="lighter">View History</p>
       </a>&ndash;&gt;
      <div class="dates">
        <p style="margin: 0;">
          <span class="lighterHeader">Test</span>
          <span>
               test
             </span>
        </p>
      </div>-->
        </div>
    </div>

    <div class="row2">
        <div class="card">
            <div class="title">
                <div class="grid-item">
                    <p class="bold">Klant details</p>
                </div>
            </div>
            <div class="card-content card-content-2">
                <div class="grid-item">
                    <div class="info-row">
                        <div class="info-content lighter">Klant nummer</div>
                        <div>
                            <label>{{ clientDB.exact_client_number }}</label>
                        </div>
                    </div>
                    <div class="info-row">
                        <div class="info-content lighter">Naam</div>
                        <div>
                            <label required="required" type="text" id="first_name_label" *ngIf="!this.editMode">
                                {{
                                    clientDB.client_data && clientDB.client_data.first_name
                                        ? clientDB.client_data.first_name
                                        : ''
                                }}
                            </label>
                            <input
                                required="required"
                                name="name"
                                type="text"
                                id="first_name"
                                placeholder="Voornaam"
                                class="input-area"
                                *ngIf="this.editMode"
                                [(ngModel)]="clientDB.client_data.first_name"
                                [ngModelOptions]="{ standalone: true }"
                            />
                        </div>
                    </div>
                    <div class="info-row">
                        <div class="info-content lighter">Achternaam</div>
                        <div>
                            <label required="required" type="text" id="last_name_label" *ngIf="!this.editMode">
                                {{
                                    clientDB.client_data && clientDB.client_data.last_name
                                        ? clientDB.client_data.last_name
                                        : ''
                                }}
                            </label>
                            <input
                                required="required"
                                name="name"
                                type="text"
                                id="last_name"
                                placeholder="Achternaam"
                                class="input-area"
                                *ngIf="this.editMode"
                                [(ngModel)]="clientDB.client_data.last_name"
                                [ngModelOptions]="{ standalone: true }"
                            />
                        </div>
                    </div>

                    <div class="info-row">
                        <div class="info-content lighter">Telefoon</div>
                        <div>
                            <label required="required" type="text" id="Telphone_label" *ngIf="!this.editMode">
                                {{
                                    clientDB.client_data && clientDB.client_data.telephone_phone
                                        ? clientDB.client_data.telephone_phone
                                        : ''
                                }}
                            </label>
                            <input
                                required="required"
                                name="phone_number"
                                type="text"
                                id="phone_number"
                                placeholder="Telefoon"
                                class="input-area"
                                *ngIf="this.editMode"
                                [(ngModel)]="clientDB.client_data.telephone_phone"
                                [ngModelOptions]="{ standalone: true }"
                            />
                        </div>
                    </div>
                    <div class="info-row">
                        <div class="info-content lighter">GSM</div>
                        <div>
                            <label required="required" type="text" id="mobile_phone_label" *ngIf="!this.editMode">
                                {{
                                    clientDB.client_data && clientDB.client_data.mobile_phone
                                        ? clientDB.client_data.mobile_phone
                                        : ''
                                }}
                            </label>
                            <input
                                required="required"
                                name="phone_number"
                                type="text"
                                id="mobile_phone"
                                placeholder="Gsm nr."
                                class="input-area"
                                *ngIf="this.editMode"
                                [(ngModel)]="clientDB.client_data.mobile_phone"
                                [ngModelOptions]="{ standalone: true }"
                            />
                        </div>
                    </div>
                    <div class="info-row">
                        <div class="info-content lighter">Email</div>
                        <div>
                            <label required="required" type="text" id="email_label" *ngIf="!this.editMode">
                                {{
                                    clientDB.client_data && clientDB.client_data.email ? clientDB.client_data.email : ''
                                }}
                            </label>
                            <input
                                required="required"
                                name="email"
                                type="text"
                                id="email"
                                placeholder="Email"
                                class="input-area"
                                *ngIf="this.editMode"
                                [(ngModel)]="clientDB.client_data.email"
                                [ngModelOptions]="{ standalone: true }"
                            />
                        </div>
                    </div>

                    <p class="sub-title">Hoofd address</p>
                    <div class="info-row">
                        <div class="info-content lighter">Straat</div>
                        <div>
                            <label required="required" type="text" id="address_label" *ngIf="!this.editMode">
                                {{
                                    clientDB.client_data.main_address && clientDB.client_data.main_address.address
                                        ? clientDB.client_data.main_address.address
                                        : ''
                                }}
                            </label>
                            <input
                                required="required"
                                name="address"
                                type="text"
                                id="address1"
                                placeholder="Adres"
                                class="input-area"
                                *ngIf="this.editMode"
                                [(ngModel)]="clientDB.client_data.main_address.address"
                                [ngModelOptions]="{ standalone: true }"
                            />
                        </div>
                    </div>
                    <div class="info-row">
                        <div class="info-content lighter">Postcode</div>
                        <div>
                            <label required="required" type="text" id="zipcode_label" *ngIf="!this.editMode">
                                {{
                                    clientDB.client_data.main_address && clientDB.client_data.main_address.zip_code
                                        ? clientDB.client_data.main_address.zip_code
                                        : ''
                                }}
                            </label>
                            <input
                                required="required"
                                name="zip_code"
                                type="text"
                                id="zip_code"
                                placeholder="postcode"
                                class="input-area"
                                *ngIf="this.editMode"
                                [(ngModel)]="clientDB.client_data.main_address.zip_code"
                                [ngModelOptions]="{ standalone: true }"
                            />
                        </div>
                    </div>
                    <div class="info-row">
                        <div class="info-content lighter">Gemeente / Stad</div>
                        <div>
                            <label required="required" type="text" id="city_label" *ngIf="!this.editMode">
                                {{
                                    clientDB.client_data.main_address && clientDB.client_data.main_address.city
                                        ? clientDB.client_data.main_address.city
                                        : ''
                                }}
                            </label>
                            <input
                                required="required"
                                name="city"
                                type="text"
                                id="city"
                                placeholder="Stad"
                                class="input-area"
                                *ngIf="this.editMode"
                                [(ngModel)]="clientDB.client_data.main_address.city"
                                [ngModelOptions]="{ standalone: true }"
                            />
                        </div>
                    </div>
                    <div class="info-row">
                        <div class="info-content lighter">Province</div>
                        <div>
                            <label required="required" type="text" id="state_label" *ngIf="!this.editMode">
                                {{
                                    clientDB.client_data.main_address && clientDB.client_data.main_address.stateName
                                        ? clientDB.client_data.main_address.stateName
                                        : ''
                                }}
                            </label>
                            <input
                                required="required"
                                name="state"
                                type="text"
                                id="state"
                                placeholder="provincie"
                                class="input-area"
                                *ngIf="this.editMode"
                                [(ngModel)]="clientDB.client_data.main_address.stateName"
                                [ngModelOptions]="{ standalone: true }"
                            />
                        </div>
                    </div>

                    <div class="info-row">
                        <div class="info-content lighter">Land</div>
                        <div>
                            <label required="required" type="text" id="country_label" *ngIf="!this.editMode">
                                {{
                                    clientDB.client_data.main_address && clientDB.client_data.main_address.countryName
                                        ? clientDB.client_data.main_address.countryName
                                        : ''
                                }}
                                -
                                {{
                                    clientDB.client_data.main_address && clientDB.client_data.main_address.country
                                        ? clientDB.client_data.main_address.country
                                        : ''
                                }}
                            </label>

                            <div class="country-row">
                                <input
                                    required="required"
                                    name="country"
                                    type="text"
                                    id="country_name"
                                    style="width: 150px"
                                    placeholder="Land"
                                    class="input-area"
                                    *ngIf="this.editMode"
                                    [(ngModel)]="clientDB.client_data.main_address.countryName"
                                    [ngModelOptions]="{ standalone: true }"
                                />

                                <input
                                    required="required"
                                    name="country"
                                    type="text"
                                    id="country"
                                    style="width: 80px"
                                    placeholder="Landcode"
                                    class="input-area"
                                    *ngIf="this.editMode"
                                    [(ngModel)]="clientDB.client_data.main_address.country"
                                    [ngModelOptions]="{ standalone: true }"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="title">
                <div class="grid-item">
                    <p class="bold">Klant adres (adres regel 2 exact)</p>
                </div>
            </div>
            <div class="card-content card-content-1" style="overflow-y: auto; max-height: 550px">
                <button
                    class="btn btn-primary btn-sm"
                    *ngIf="this.editMode"
                    (click)="addDeliveryAddress()"
                    style="width: 150px"
                >
                    <clr-icon shape="plus"></clr-icon>
                    Nieuw leveradres
                </button>
                <div *ngIf="!clientDB.client_data.delivery_addresses">
                    <p class="sub-title">Geen lever adres beschikbaar</p>
                </div>
                <div
                    class="grid-item"
                    *ngFor="let deliveryAddress of clientDB.client_data.delivery_addresses; let i = index"
                >
                    <div class="info-row-delivery-address">
                        <p class="sub-title">
                            Leveradres {{ i }}
                            <span
                                *ngIf="clientDB.client_data.delivery_addresses[i].deleted === true"
                                class="label label-danger"
                            >
                                Niet actief
                            </span>
                        </p>

                        <div style="text-align: right" class="sub-title" *ngIf="this.editMode">
                            <div class="btn-group btn-primary btn-icon">
                                <button
                                    class="btn btn-icon btn-warning btn-sm"
                                    aria-label="warning"
                                    (click)="deleteDeliveryAddress(i)"
                                >
                                    <clr-icon shape="trash" title="home"></clr-icon>
                                </button>
                                <button
                                    *ngIf="clientDB.client_data.delivery_addresses[i].deleted === true"
                                    class="btn btn-icon btn-sm tooltip tooltip-md"
                                    aria-label="warning"
                                    (click)="activateDeliveryAddress(i)"
                                    role="tooltip"
                                    aria-haspopup="true"
                                >
                                    <clr-icon shape="cursor-hand-click"></clr-icon>
                                    <span class="tooltip-content">Activeer lever adres</span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="info-row-delivery-address">
                        <div class="info-content lighter">Straat</div>
                        <div>
                            <label required="required" type="text" id="{{ 'address' + i }}" *ngIf="!this.editMode">
                                {{ deliveryAddress.address ? deliveryAddress.address : '' }}
                            </label>
                            <div *ngIf="this.editMode" class="row">
                                <input
                                    name="address_edit"
                                    type="text"
                                    id="{{ 'addressEdit' + i }}"
                                    [(ngModel)]="clientDB.client_data.delivery_addresses[i].address"
                                    class="full-length-screen"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="info-row-delivery-address">
                        <div class="info-content lighter">Postcode</div>
                        <div>
                            <label required="required" type="text" id="{{ 'zip_code' + i }}" *ngIf="!this.editMode">
                                {{ deliveryAddress.zip_code ? deliveryAddress.zip_code : '' }}
                            </label>
                            <div *ngIf="this.editMode" class="row">
                                <input
                                    name="zip_code_edit"
                                    type="text"
                                    id="{{ 'zipCodeEdit' + i }}"
                                    [(ngModel)]="clientDB.client_data.delivery_addresses[i].zip_code"
                                    class="full-length-screen"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="info-row-delivery-address">
                        <div class="info-content lighter">Gemeente</div>
                        <div>
                            <div *ngIf="!this.editMode">
                                <label required="required" type="text" id="{{ 'city' + i }}">
                                    {{
                                        clientDB.client_data.main_address.city
                                            ? clientDB.client_data.delivery_addresses[i].city
                                            : ''
                                    }}
                                </label>
                            </div>
                            <div *ngIf="this.editMode" class="row">
                                <input
                                    name="city_edit"
                                    type="text"
                                    id="{{ 'city_edit' + i }}"
                                    [(ngModel)]="clientDB.client_data.delivery_addresses[i].city"
                                    class="full-length-screen"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="info-row-delivery-address">
                        <div class="info-content lighter">Provincie</div>
                        <div>
                            <div *ngIf="!this.editMode">
                                <label required="required" type="text" id="{{ 'state' + i }}">
                                    {{
                                        clientDB.client_data.delivery_addresses[i].state
                                            ? clientDB.client_data.delivery_addresses[i].state
                                            : '/'
                                    }}
                                    -
                                    {{
                                        clientDB.client_data.delivery_addresses[i].stateName
                                            ? clientDB.client_data.delivery_addresses[i].stateName
                                            : '/'
                                    }}
                                </label>
                            </div>

                            <div *ngIf="this.editMode" class="row">
                                <input
                                    name="state"
                                    type="text"
                                    id="{{ 'state_edit' + i }}"
                                    [(ngModel)]="clientDB.client_data.delivery_addresses[i].stateName"
                                    class="full-length-screen"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="info-row-delivery-address">
                        <div class="info-content lighter">Land</div>
                        <div>
                            <label required="required" type="text" id="{{ 'country' + i }}" *ngIf="!this.editMode">
                                {{
                                    clientDB.client_data.delivery_addresses[i].country
                                        ? clientDB.client_data.delivery_addresses[i].country
                                        : ''
                                }}
                            </label>
                            <div *ngIf="this.editMode" class="row">
                                <input
                                    name="country_edit"
                                    type="text"
                                    id="{{ 'countryEdit' + i }}"
                                    [(ngModel)]="clientDB.client_data.delivery_addresses[i].country"
                                    class="full-length-screen"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row2">
        <div class="card">
            <div class="title">
                <div class="grid-item">
                    <p class="bold">Company details</p>
                </div>
            </div>
            <div class="card-content card-content-2">
                <div class="grid-item">
                    <div class="info-row">
                        <div class="info-content lighter">Company naam</div>
                        <div>
                            <label required="required" type="text" id="company_name" *ngIf="!this.editMode">
                                {{
                                    clientDB.client_data.company_data && clientDB.client_data.company_data.company_name
                                        ? clientDB.client_data.company_data.company_name
                                        : ''
                                }}
                            </label>
                            <div *ngIf="this.editMode" class="row">
                                <input
                                    name="company_name_edit"
                                    type="text"
                                    id="companyNameEdit"
                                    [(ngModel)]="clientDB.client_data.company_data.company_name"
                                    class="full-length-screen"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="info-row">
                        <div class="info-content lighter">VAT nummer</div>
                        <div>
                            <label required="required" type="text" id="vat" *ngIf="!this.editMode">
                                {{
                                    clientDB.client_data.company_data && clientDB.client_data.company_data.vat_number
                                        ? clientDB.client_data.company_data.vat_number
                                        : ''
                                }}
                            </label>
                            <div *ngIf="this.editMode" class="row">
                                <input
                                    name="vat_number_edit"
                                    type="text"
                                    id="vatNumberEdit"
                                    [(ngModel)]="clientDB.client_data.company_data.vat_number"
                                    class="full-length-screen"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="info-row">
                        <div class="info-content lighter">Bank account</div>
                        <div>
                            <label required="required" type="text" id="bank_account" *ngIf="!this.editMode">
                                {{
                                    clientDB.client_data.company_data && clientDB.client_data.company_data.bank_account
                                        ? clientDB.client_data.company_data.bank_account
                                        : ''
                                }}
                            </label>
                            <div *ngIf="this.editMode" class="row">
                                <input
                                    name="bank_account_edit"
                                    type="text"
                                    id="bank_account_edit"
                                    [(ngModel)]="clientDB.client_data.company_data.bank_account"
                                    class="full-length-screen"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="info-row">
                        <div class="info-content lighter">Type industrie</div>
                        <div>
                            <label required="required" type="text" id="industry" *ngIf="!this.editMode">
                                {{
                                    clientDB.client_data.company_data && clientDB.client_data.company_data.industry
                                        ? clientDB.client_data.company_data.industry
                                        : ''
                                }}
                            </label>
                            <div *ngIf="this.editMode" class="row">
                                <input
                                    name="industry_edit"
                                    type="text"
                                    id="industry_edit"
                                    [(ngModel)]="clientDB.client_data.company_data.industry"
                                    class="full-length-screen"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="info-row">
                        <div class="info-content lighter">Aantal werknemers</div>
                        <div>
                            <label required="required" type="text" id="employees" *ngIf="!this.editMode">
                                {{
                                    clientDB.client_data.company_data && clientDB.client_data.company_data.employees
                                        ? clientDB.client_data.company_data.employees
                                        : ''
                                }}
                            </label>
                            <div *ngIf="this.editMode" class="row">
                                <input
                                    name="employees_edit"
                                    type="text"
                                    id="employees_edit"
                                    [(ngModel)]="clientDB.client_data.company_data.employees"
                                    class="full-length-screen"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="title">
                <div class="grid-item">
                    <p class="bold">Client settings</p>
                </div>
            </div>
            <div class="card-content card-content-2">
                <div class="grid-item">
                    <div class="info-row">
                        <div class="info-content lighter">Onderhoud</div>
                        <div *ngIf="clientDB.client_settings.maintenanceSettings">
                            <label *ngIf="clientDB.client_settings.maintenanceSettings.active"> Actief </label>
                            <label *ngIf="!clientDB.client_settings.maintenanceSettings.active">
                                Niet actief -
                                {{
                                    clientDB.client_settings.maintenanceSettings.inactive_date
                                        ? (clientDB.client_settings.maintenanceSettings.inactive_date
                                          | dateFormatOnlyDate)
                                        : ''
                                }}
                            </label>
                        </div>
                    </div>
                    <div class="info-row">
                        <div class="info-content lighter">Verzend mails voor naderend onderhoud</div>
                        <div>
                            <input
                                [(ngModel)]="sendMailForUpcomingMaintenance"
                                class="input-area"
                                [disabled]="!editMode"
                                id="disableSendMailForUpcomingMaintenance"
                                name="disableSendMailForUpcomingMaintenance"
                                type="checkbox"
                            />
                        </div>
                    </div>
                    <div class="info-row">
                        <div class="info-content lighter">Verzend mails bij inplannen afspraken</div>
                        <div>
                            <input
                                [(ngModel)]="sendMailNotifyPlanningItemForHisAppointment"
                                class="input-area"
                                [disabled]="!editMode"
                                id="disableSendMailForPlannedNotification"
                                name="disableSendMailForPlannedNotification"
                                type="checkbox"
                            />
                        </div>
                    </div>
                    <div class="info-row">
                        <div class="info-content lighter">Verzend reminder mails voor ingeplande afspraak</div>
                        <div>
                            <input
                                [(ngModel)]="sendMailForPlannedNotification"
                                class="input-area"
                                [disabled]="!editMode"
                                id="disableSendMailNotifyPlanningItemForHisAppointment"
                                name="disableSendMailNotifyPlanningItemForHisAppointment"
                                type="checkbox"
                            />
                        </div>
                    </div>
                    <div class="info-row">
                        <div class="info-content lighter">
                            Verzend feedback mail na afgewerkte onderhoud / plaatsing
                        </div>
                        <div>
                            <input
                                [(ngModel)]="sendFeedbackMail"
                                [disabled]="!editMode"
                                class="input-area"
                                id="disableSendFeedbackMail"
                                name="disableSendFeedbackMail"
                                type="checkbox"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row1">
        <div class="card">
            <div class="title">
                <div class="grid-item">
                    <p class="bold">Klant info</p>
                </div>
            </div>
            <div class="card-content card-content-2">
                <div class="grid-item">
                    <div class="info-row">
                        <div class="info-content lighter">Klant Opmerkingen</div>

                        <clr-textarea-container disabled="editMode">
                            <textarea
                                clrTextarea
                                [(ngModel)]="clientDB.client_data.remarks"
                                name="remarks"
                                class="textarea-custom"
                                placeholder="Opmerking toevoegen"
                            >
                            </textarea>
                        </clr-textarea-container>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row2" *ngIf="!createMode">
        <div class="card">
            <div class="title">
                <div class="grid-item">
                    <p class="bold">Klant historiek</p>
                </div>
                <div class="grid-item btn-edit">
                    <!--   <button class="btn btn-sm btn-link btn-edit">
               <clr-icon shape="pencil"></clr-icon>
             </button>-->
                </div>
            </div>

            <div id="content">
                <ul class="clr-timeline clr-timeline-vertical">
                    <li class="clr-timeline-step" *ngFor="let planningItem of historyOfClient">
                        <div class="clr-timeline-step-header" style="width: 100px">
                            {{
                                planningItem.assigned_calendar_item
                                    ? (planningItem.assigned_calendar_item.date_time_start | dateFormatOnlyDate)
                                    : '-'
                            }}
                        </div>
                        <clr-icon
                            shape="circle"
                            aria-label="Not started"
                            *ngIf="planningItem.state === PlanningState.toBePlanned"
                        ></clr-icon>
                        <clr-icon
                            shape="dot-circle"
                            aria-current="true"
                            aria-label="Current"
                            *ngIf="planningItem.state === PlanningState.planned"
                        ></clr-icon>
                        <clr-icon
                            shape="success-standard"
                            aria-label="Completed"
                            *ngIf="
                                planningItem.state === PlanningState.completed ||
                                planningItem.state === PlanningState.completedAndQuoteApproved ||
                                planningItem.state === PlanningState.completedWithOffer ||
                                planningItem.state === PlanningState.completedNoOffer
                            "
                        ></clr-icon>
                        <clr-icon
                            shape="error-standard"
                            aria-label="Error"
                            *ngIf="
                                planningItem.state === PlanningState.inactive ||
                                planningItem.state === PlanningState.protest
                            "
                        ></clr-icon>
                        <div class="clr-timeline-step-body">
                            <span class="clr-timeline-step-title">
                                <a class="pointer" (click)="goToPlanningItem(planningItem.planning_item_id)">
                                    {{ planningItem.planning_item_type | getNameOfPlanningItemTypePipe: 'nl' }}
                                    : {{ planningItem.planning_item_type_number }}
                                    {{
                                        planningItem.data.water_meter_stand !== undefined
                                            ? '(watermeterstand: ' + planningItem.data.water_meter_stand + ')'
                                            : ''
                                    }}
                                </a>
                            </span>
                            <span class="clr-timeline-step-description"> </span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="card">
            <div class="title">
                <div class="grid-item">
                    <p class="bold">Lead details</p>
                </div>
            </div>
            <div class="card-content card-content">
                <div class="grid-item">
                    <div class="info-row">
                        <div class="info-content lighter">LeadID Aquagroup</div>
                        <div>
                            <label required="required" type="text" id="lead_id">
                                {{ clientDB.client_id }}
                            </label>
                        </div>
                    </div>
                    <div class="info-row">
                        <div class="info-content lighter">
                            Lead ID
                            {{ clientDB.lead_data ? clientDB.lead_data.lead_type : '' }}
                        </div>
                        <div>
                            <label required="required" type="text" id="exact_custom_id">
                                {{ clientDB.lead_data ? clientDB.lead_data.lead_custom_id : '' }}
                            </label>
                        </div>
                    </div>
                    <div class="info-row">
                        <div class="info-content lighter">Creatie Datum Lead</div>
                        <div>
                            <label
                                required="required"
                                type="text"
                                id="creation_date"
                                *ngIf="clientDB.lead_data && clientDB.lead_data.created_at"
                            >
                                {{
                                    clientDB.lead_data.created_at.date
                                        ? (clientDB.lead_data.created_at.date | dateFormatWithTime)
                                        : ''
                                }}
                            </label>
                        </div>
                    </div>

                    <div class="info-row">
                        <div class="info-content lighter">Type klant</div>
                        <div>
                            <label required="required" type="text" id="type">
                                {{ clientDB.lead_data ? clientDB.lead_data.type : '' }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="row1">
    <div  class="card">
      <div class="title">
        <div class="grid-item">
          <p class="bold">Offerte overzicht</p>
        </div>
      </div>
      <div class="card-content card-content-2">
        <div class="grid-item">
          <div class="info-row">
            <div class="info-content lighter">Offerte lijst</div>

            <div>
              todo
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>-->

    <pr-client-activity-section *ngIf="!createMode" [client]="clientDB" [activities]="clientDB.activities">
    </pr-client-activity-section>
</div>
