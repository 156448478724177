import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import {
    ClientState,
    IPlanningItemEntity,
    PlanningItemType,
    PlanningState,
    PlanningItemReportState,
    IQuoteEntity,
    IClientEntity,
    IQuoteClientFicheEntity,
} from '@phrospr/api-backend';
import { newPlanningItem } from '@phrospr/lib-core/src/engine/planning-item.engine';
import { getQuoteVersion } from '@phrospr/lib-core/src/engine/quote.engine';

@Component({
    selector: 'pr-quote-approve-modal',
    styleUrls: ['./quote-approve-modal.component.scss'],
    templateUrl: './quote-approve-modal.component.html',
})
export class QuoteApproveModalComponent {
    @Input() visible: boolean;

    _quote: IQuoteEntity;
    @Input('quote') get quote() {
        return this._quote;
    }

    set quote(quote: IQuoteEntity) {
        this._quote = quote;
        this.quoteVersion = getQuoteVersion(quote);

        if (quote && quote.data.quote_create && quote.data.quote_create.machineFiche.voorkeurs_plaatsing_datum) {
            this.quoteClient = this.quote.client;
            if (this.quoteVersion === 1) {
                this.quoteClientFiche = this.quote.data.quote_create.clientFiche;
            }
            this.suggested_date = new Date(quote.data.quote_create.machineFiche.voorkeurs_plaatsing_datum);
        } else {
            // If the Quote is not existing we set a date
            this.suggested_date = new Date();
        }
    }

    _currentPlanningItem: IPlanningItemEntity;
    @Input('currentPlanningItem') get currentPlanningItem() {
        return this._currentPlanningItem;
    }

    set currentPlanningItem(currentPlanningItem: IPlanningItemEntity) {
        this._currentPlanningItem = currentPlanningItem;
        if (this.quote && this.quote.client) {
            this.quoteClient = this.quote.client;
            if (this.quoteVersion === 1) {
                this.quoteClientFiche = this.quote.data.quote_create.clientFiche;
            }
        }
    }

    @Output() cancel = new EventEmitter<any>();
    @Output() yes = new EventEmitter<{
        planningItemToAdd: IPlanningItemEntity;
        planningItem: IPlanningItemEntity;
    }>();

    actionOptions: Array<{ text: string; value: PlanningItemReportState }> = [];

    planningItemToAdd: IPlanningItemEntity = newPlanningItem();
    suggested_date: Date;

    quoteClient: IClientEntity;
    quoteClientFiche: IQuoteClientFicheEntity;

    ClientState = ClientState;

    quoteVersion: number;

    constructor(private router: Router) {}

    setPlanningObject() {
        this.planningItemToAdd.planning_item_type = PlanningItemType.placement;
        this.planningItemToAdd.state = PlanningState.toBePlanned;
        this.planningItemToAdd.client_id = this.quoteClient.client_id;
        this.planningItemToAdd.data.suggested_date = this.suggested_date;
        this.planningItemToAdd.data.created_from_lead_planning_item_id = this.currentPlanningItem
            ? this.currentPlanningItem.planning_item_id
            : undefined;
    }

    approveQuoteAndCreatePlacement() {
        this.setPlanningObject();
        this.yes.emit({
            planningItemToAdd: this.planningItemToAdd,
            planningItem: this.currentPlanningItem,
        });
        this.visible = false;
    }

    sendCancel() {
        this.cancel.emit();
        this.visible = false;
    }

    async editQuote(quote: IQuoteEntity) {
        this.cancel.emit();
        this.visible = false;
        const quoteVersion: number = getQuoteVersion(quote);
        if (quoteVersion === 1) {
            await this.router.navigateByUrl(`/quote-upsert?quote_id=${quote.quote_id}`);
        } else {
            await this.router.navigateByUrl(`/quote-upsert2?quote_id=${quote.quote_id}`);
        }
    }
}
