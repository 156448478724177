"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseInlineResponse200 = void 0;
const iplanning_item_entity_1 = require("./iplanning-item-entity");
function parseInlineResponse200(data) {
    if (data.records !== null && typeof data.records === 'object')
        data.records = data.records.map((x) => (0, iplanning_item_entity_1.parseIPlanningItemEntity)(x));
    return data;
}
exports.parseInlineResponse200 = parseInlineResponse200;
