import { IActivitySearchFilterEntity } from '@phrospr/api-backend';

const basicSearchFilter: IActivitySearchFilterEntity = {
    // keep this up to date with filtercorentity
    raw_activity_id: null,
    from_created_date: null,
    to_created_date: null,
    created_by_user_id: null,
    created_by_user_name: null,
    planning_item_type: null,
    activity_types: null,
    ignore_development: null,
};

export function validateActivityItemSearchFilterKey(filterKey: string): boolean {
    return basicSearchFilter[filterKey] === null;
}
