import { Component, OnInit } from '@angular/core';
import { QuoteService } from '../../../shared/services/quote.service';
import * as toastr from 'toastr';
import { Router } from '@angular/router';
import {
    IPlanningItemEntity,
    IQuoteDataRating,
    IQuoteEntity,
    IQuoteSearchEntity,
    IQuoteSearchFilterEntity,
    QuoteStatus,
    RequestSort,
} from '@phrospr/api-backend';
import { getQuoteVersion } from '@phrospr/lib-core/src/engine/quote.engine';
import { PlanningItemService } from '../../../shared/services/planning-item.service';
import { HttpErrorResponse } from '@angular/common/http';
import { PhrosprPortalApi } from '../../../../../resource/phrospr-api';

interface QuoteRecord {
    quote: IQuoteEntity;
    ratingEdit: IQuoteDataRating;
}

function loadRating(quote: IQuoteEntity): IQuoteDataRating {
    return quote.data && quote.data.rating
        ? {
              value: quote.data.rating.value,
              max: quote.data.rating.max,
          }
        : {
              value: 0,
              max: 5,
          };
}

@Component({
    selector: 'pr-quote-page',
    styleUrls: ['./quote-page.component.scss'],
    templateUrl: './quote-page.component.html',
})
export class QuotePageComponent implements OnInit {
    QuoteStatus = QuoteStatus;

    quotes: QuoteRecord[];

    quoteSearch: IQuoteSearchEntity = {
        show: {
            client: true,
            planning_item_lead: true,
            planning_item_placement: true,
            created_by_user: true,
            sold_by_user: true,
            pdf_raw: false,
        },
        sort: { order: RequestSort.DESC, column: 'creation_date' },
        filter: {
            quote_states: [QuoteStatus.approved, QuoteStatus.created],
        },
    };

    reorderable: true;
    loading = false;

    page: {
        limit: number;
        count: number;
        offset: number;
        sort?: { order: RequestSort; column: string };
    } = {
        limit: 25,
        count: 0,
        offset: 0,
        sort: { order: RequestSort.DESC, column: 'creation_date' },
    };

    approveQuoteModal = false;
    quoteToApprove: IQuoteEntity;
    planningItemToApproveQuote: IPlanningItemEntity;

    cancelQuoteModal = false;
    quoteToCancel: IQuoteEntity;

    updateQuoteRatingModal = false;
    updateQuoteRating: QuoteRecord;

    constructor(
        private quoteService: QuoteService,
        private router: Router,
        private planningItemService: PlanningItemService,
    ) {}

    ngOnInit(): void {
        this.pageCallback(this.page);
    }

    onActivate(event) {
        if (event.type === 'click') {
            console.log(event);
            if (event.row.quote.planning_item_lead) {
                this.goToPlanningItemDetail(event.row.quote.planning_item_lead.planning_item_id);
            } else if (event.row.quote.planning_item_placement) {
                this.goToPlanningItemDetail(event.row.quote.planning_item_placement.planning_item_id);
            } else {
                this.editQuote(event.row.quote);
            }
        }
    }

    pageCallback(pageInfo: { count?: number; pageSize?: number; limit?: number; offset?: number }) {
        this.page.offset = pageInfo.offset;
        if (pageInfo.pageSize !== undefined) this.quoteSearch.offset = pageInfo.offset * pageInfo.pageSize;
        this.quoteSearch.limit = pageInfo.limit;
        this.loadQuoteList();
    }

    sortCallback(sortInfo: {
        sorts: { dir: 'ASC' | 'DESC'; prop: string };
        column: { name: string };
        prevValue: string;
        newValue: string;
    }) {
        // there will always be one "sort" object if "sortType" is set to "single"
        // FYI it's always the column prop that is filled in the sorting
        this.quoteSearch.sort.order = sortInfo.sorts[0].dir.toUpperCase();
        this.quoteSearch.sort.column = sortInfo.column.name;
        this.loadQuoteList();
    }

    async loadQuoteList() {
        this.loading = true;
        try {
            const { records, total } = (
                await PhrosprPortalApi.QuoteApi.getQuoteListPost({
                    quote_filter: { ...this.quoteSearch },
                })
            ).data;
            this.quotes = records.map(quote => ({
                quote,
                ratingEdit: loadRating(quote),
            }));
            this.page.count = total;
        } catch (err) {
            console.error(err);
            toastr.error('Een probleem is opgedoken tijdens het opvragen van de offertes');
        } finally {
            this.loading = false;
        }
    }

    getRowClass(row: IQuoteEntity) {
        return {
            'row-style-datatable': row.quote_id !== null,
        };
    }

    async editQuote(quote: IQuoteEntity) {
        const quoteVersion: number = getQuoteVersion(quote);
        if (quoteVersion === 1) {
            await this.router.navigateByUrl(`/quote-upsert?quote_id=${quote.quote_id}`);
        } else {
            await this.router.navigateByUrl(`/quote-upsert2?quote_id=${quote.quote_id}`);
        }
    }

    async downloadQuote(quote: IQuoteEntity) {
        try {
            await this.quoteService.downloadQuotePdf(quote.quote_id);
        } catch (err) {
            if (err instanceof HttpErrorResponse && err.status === 404) {
                toastr.error('Geen PDF beschikbaar');
            } else {
                console.error(err);
                toastr.error('Fout tijdens het ophalen van de pdf');
            }
        }
    }

    async updateRating() {
        try {
            if (this.updateQuoteRating === undefined) throw new Error('updateQuoteRating undefined');
            await PhrosprPortalApi.QuoteApi.rateQuote(
                this.updateQuoteRating.quote.quote_id,
                this.updateQuoteRating.ratingEdit,
            );
            this.updateQuoteRating = undefined;
            this.updateQuoteRatingModal = false;
            await this.loadQuoteList();
        } catch (err) {
            console.error(err);
            toastr.error('Fout tijdens het opslaan van de rating');
        }
    }

    async goToPlanningItemDetail(planningItemId: number) {
        await this.router.navigateByUrl('/planning-item-detail?planning_item_id=' + planningItemId);
    }

    async goToClientDetail(clientId: number) {
        await this.router.navigateByUrl('/client-detail?client_id=' + clientId);
    }

    async showFinishQuote(quote: IQuoteEntity) {
        if (quote.client) {
            this.planningItemToApproveQuote = quote.planning_item_lead ? quote.planning_item_lead : null;
            this.quoteToApprove = quote;
            this.approveQuoteModal = true;
        } else {
            toastr.error(
                'Er ging iets mis met goedkeuren van de offerte. Waarschijnlijk vonden ' +
                    'we geen gelinkte lead in het system. Of is er iets mis met de quote. Contacteer IT.',
            );
        }
    }

    async showCancelQuote(quote: IQuoteEntity) {
        if (quote.client) {
            this.quoteToCancel = quote;
            this.cancelQuoteModal = true;
        } else {
            toastr.error('Er ging iets mis met de cancel van de offerte. Contacteer IT.');
        }
    }

    async cancelQuote() {
        try {
            await PhrosprPortalApi.QuoteApi.cancelQuote(this.quoteToCancel.quote_id);
            await this.loadQuoteList();
            this.cancelQuoteModal = false;
        } catch (e) {
            console.error(e);
            toastr.error('Een probleem is opgedoken tijdens het annuleren van de offerte');
        }
        return;
    }

    cancelCancelQuote() {
        this.quoteToCancel = null;
        this.cancelQuoteModal = false;
    }

    cancelUpdateQuoteRating() {
        this.updateQuoteRating.ratingEdit.value = loadRating(this.updateQuoteRating.quote).value;
        this.updateQuoteRating = undefined;
        this.updateQuoteRatingModal = false;
    }

    cancelApproveQuote() {
        this.quoteToApprove = null;
        this.planningItemToApproveQuote = null;
        this.approveQuoteModal = false;
    }

    async ApproveQuoteModal(event: { planningItemToAdd: IPlanningItemEntity; planningItem: IPlanningItemEntity }) {
        try {
            await PhrosprPortalApi.PlanningItemApi.approvePlanningItemLeadQuote({
                planning_item_id: this.planningItemToApproveQuote
                    ? this.planningItemToApproveQuote.planning_item_id
                    : undefined,
                planning_item_placement_to_create: event.planningItemToAdd,
                quote_id: this.quoteToApprove.quote_id,
            });
            await this.loadQuoteList();
            this.approveQuoteModal = false;
        } catch (e) {
            console.error(e);
            toastr.error('Een probleem is opgedoken tijdens het goedkeuren van de offerte');
        }
        return;
    }

    async updateFilters(filters: IQuoteSearchFilterEntity) {
        this.quoteSearch.filter = filters;
        await this.pageCallback(this.page);
    }

    async newQuote() {
        await this.router.navigateByUrl('/quote-upsert');
    }

    async newQuote2() {
        await this.router.navigateByUrl('/quote-upsert2');
    }
}
