import { IUserEntity } from '@phrospr/api-backend';
import { UserRole } from '@phrospr/lib-models';

export const routeAuthorization: { [key: string]: (userRoles: UserRole[]) => boolean } = {
    '/api/user': userRoles => userRoles.indexOf(UserRole.admin) > -1,
    '/api/auth': _ => true,
    '/api/planning-item/detail': userRoles => userRoles.indexOf(UserRole.user) > -1,
    '/api/planning-item/finish': userRoles => userRoles.indexOf(UserRole.user) > -1,
    '/api/planning-item/list': userRoles => userRoles.indexOf(UserRole.user) > -1,
    '/api/planning-item/attachment': userRoles => userRoles.indexOf(UserRole.user) > -1,
    '/api/quote/': userRoles => userRoles.indexOf(UserRole.user) > -1,
    '/api/comment/add': userRoles => userRoles.indexOf(UserRole.user) > -1,
    '/api/client/list': userRoles => userRoles.indexOf(UserRole.user) > -1,
    '/api/planning-item-report/update': userRoles => userRoles.indexOf(UserRole.user) > -1,
};

export function isSuperAdmin(email: string): boolean {
    const superAdmins = ['hendrik.hamerlinck@outlook.com', 'teodeconinck1@hotmail.com', 'development@aquagroup.be'];
    return superAdmins.indexOf(email) > -1;
}

export function hasRouteAccess(route: string, user: IUserEntity): boolean {
    const roles = user.roles.roles;

    // Shouldn't we consider the deleted setting on the user as well?
    // I think deleted users should no longer be able to access anything
    if (user.deleted) {
        return false;
    }

    if (isSuperAdmin(user.email)) {
        return true;
    }

    if (roles.indexOf(UserRole.admin) > -1) {
        return true;
    }

    for (const keyRoute in routeAuthorization) {
        if (route.startsWith(keyRoute)) {
            const isAuthorized = routeAuthorization[keyRoute](roles);
            if (isAuthorized !== null) {
                return isAuthorized;
            }
        }
    }

    return false;
}
