import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { map } from 'rxjs/operators';
import { hasRouteAccess } from '@phrospr/lib-core/src/engine/authorization.engine';
import { fromPromise } from 'rxjs/internal-compatibility';
import { user$ } from './resources/oidc/phrospr-user';

@Injectable()
export class AuthorizationGuard implements CanActivate {
    constructor() {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const apiRoute = route.data ? route.data.api : null;
        if (!apiRoute) {
            return true;
        }
        return fromPromise(user$).pipe(
            map(user => {
                if (!user) {
                    return false;
                }
                return hasRouteAccess(apiRoute, user);
            }),
        );
    }
}
