import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ROUTING } from '../../app.routing';
import { SharedModule } from '../shared/shared.module';
import { ClarityModule, ClrButtonGroupModule } from '@clr/angular';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { BrowserModule } from '@angular/platform-browser';
import { PlanningItemModule } from '../planning-item/planning-item.module';
import { PlacementPageComponent } from './containers/placement-page/placement-page.component';
import { NewPlacementPageComponent } from './containers/new-placement-page/new-placement-page.component';
import { PlannedPlacementPageComponent } from './containers/planned-placement-page/planned-placement-page.component';
import { CompletedPlacementPageComponent } from './containers/completed-placement-page/completed-placement-page.component';
import { CancelledPlacementPageComponent } from './containers/cancelled-placement-page/cancelled-placement-page.component';

const components = [];

const containers = [
    PlacementPageComponent,
    NewPlacementPageComponent,
    PlannedPlacementPageComponent,
    CompletedPlacementPageComponent,
    CancelledPlacementPageComponent,
];

const imports = [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ROUTING,
    SharedModule,
    ClarityModule,
    ClrButtonGroupModule,
    NgxDatatableModule,
    BrowserModule,
    PlanningItemModule,
];

const sandboxes = [];

const services = [];

@NgModule({
    imports: [...imports],
    declarations: [...containers, ...components],
    providers: [...sandboxes, ...services],
    entryComponents: [],
    exports: [...containers, ...components],
})
export class PlacementModule {}
