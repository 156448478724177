import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import 'rxjs/add/operator/take';
import 'rxjs/add/operator/map';
import * as React from 'react';
import { initializeApi } from './resource/phrospr-api';
import { reactComponentInitialize } from './resource/components/react-components';

(window as any).React = React;

if (environment.production) {
    enableProdMode();
}

initializeApi();
reactComponentInitialize();

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.log(err));
