import { PublicClientApplication, AuthenticationResult, UrlString } from '@azure/msal-browser';
import { ClientConfigurationError } from '@azure/msal-common/dist/error/ClientConfigurationError';
import { msalConfig$ } from './configuration';
import { conf } from '../configuration';

const TOKEN_LIFETIME_MS_MARGIN = 5_000;

// The default of the MSAL library must be overwritten because it is not allowed to have a non https identity server
UrlString.prototype.validateAsUri = function () {
    // Attempts to parse url for uri components
    let components;
    try {
        components = this.getUrlComponents();
    } catch (e) {
        throw ClientConfigurationError.createUrlParseError(e);
    }
    // Throw error if URI or path segments are not parseable.
    if (!components.HostNameAndPort || !components.PathSegments) {
        throw ClientConfigurationError.createUrlParseError('Given url string: ' + this.urlString);
    }

    if (!conf.keycloakUrl.startsWith('http://localhost')) {
        // Throw error if uri is insecure.
        if (!components.Protocol || components.Protocol.toLowerCase() !== 'https:') {
            throw ClientConfigurationError.createInsecureAuthorityUriError(this.urlString);
        }
    }
};

const client$ = (async () => new PublicClientApplication(await msalConfig$))();

export let authentication$: Promise<AuthenticationResult> = (async function () {
    const client = await client$;
    const redirectAuthResult = await client.handleRedirectPromise();
    if (redirectAuthResult !== null) return parseAuth(redirectAuthResult, client);
    try {
        const silentAuthResult = await client.acquireTokenSilent({});
        if (silentAuthResult) return parseAuth(silentAuthResult, client);
    } catch (err) {
        console.error(err);
    }
    await client.loginRedirect(); // page will reload, code will not continue
})();

function parseAuth(authResult: AuthenticationResult, client: PublicClientApplication) {
    client.setActiveAccount(authResult.account);
    return authResult;
}

export async function getAccessToken(): Promise<string> {
    const client = await client$;
    const auth = await authentication$;
    // check if there is logged-in session, with a valid token
    if (auth !== null) {
        let tokenExpiryMs = auth.expiresOn ? auth.expiresOn.getTime() : 0;
        tokenExpiryMs -= TOKEN_LIFETIME_MS_MARGIN;
        if (new Date().getTime() < tokenExpiryMs) return auth.accessToken;
    }
    try {
        // try to silently refresh the token
        console.log(new Date(), 'acquireTokenSilent', auth);
        authentication$ = client.acquireTokenSilent({});
        return (await authentication$).accessToken;
    } catch (error) {
        console.log('failed to retrieve token silently', error);
        // try to fetch a new token through a redirect, this will stop the current request
        await client.acquireTokenRedirect({ scopes: [] });
    }
    return null;
}

export async function logout() {
    const client = await client$;
    const auth = await authentication$;
    await client.logoutRedirect({ idTokenHint: auth.idToken });
}
