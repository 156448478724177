"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseICalendarItemDataEntity = void 0;
const icalendar_event_1 = require("./icalendar-event");
function parseICalendarItemDataEntity(data) {
    if (data.calendarEvent !== null && typeof data.calendarEvent === 'object')
        data.calendarEvent = (0, icalendar_event_1.parseICalendarEvent)(data.calendarEvent);
    return data;
}
exports.parseICalendarItemDataEntity = parseICalendarItemDataEntity;
