import { ICalendarEventLite } from '@phrospr/lib-models/src/resource/office365/calendar';
import { parse } from 'node-html-parser';
import * as moment from 'moment';
import { IPortalUserEntity } from '../manager/entity/user.entity';

export interface FrontendCalendarEvent<MetaType = any> {
    id?: string | number;
    start: Date;
    end?: Date;
    title: string;
    color?: any;
    actions?: any[];
    allDay?: boolean;
    cssClass?: string;
    resizable?: {
        beforeStart?: boolean;
        afterEnd?: boolean;
    };
    draggable?: boolean;
    meta?: MetaType;

    user?: string;
    location?: string;
    content?: string;
}

export namespace CalendarEventEngine {
    export function parseCalendarEventLite(event: ICalendarEventLite, user: IPortalUserEntity): FrontendCalendarEvent {
        return {
            user: user.email,
            id: Math.floor(Math.random() * 10000),
            start: moment.utc(event.start.dateTime).toDate(),
            end: moment.utc(event.end.dateTime).toDate(),
            title: event.subject + '<br> ' + event.location.displayName,
            draggable: false,
            color: user.data,
            resizable: {
                beforeStart: false,
                afterEnd: false,
            },
            actions: [],
            location: event.location.displayName,
            content: parse(event.body.content).text,
        };
    }

    export function splitEventsInWeeks(events: FrontendCalendarEvent[]): {
        [startOfWeekDate: string]: FrontendCalendarEvent[];
    } {
        const output: { [startOfWeekDate: string]: FrontendCalendarEvent[] } = {};
        events.forEach(event => {
            const startOfWeekDate = moment(event.start).startOf('week').format('YYYY-MM-DD');
            if (!output[startOfWeekDate]) output[startOfWeekDate] = [];
            output[startOfWeekDate].push(event);
        });
        return output;
    }
}
