<div class="grid-item">
  <p class="sub-title">Comments ({{comments.length ? comments.length : '0' }})</p>
  <div *ngIf="comments && comments.length">
    <div *ngFor="let comment of  comments | slice:0:3;"
         class="custom-remark comment-grid" style="border-bottom: 1px solid rgba(0, 0, 0, 0.125);">
      <clr-icon dir="right" shape="caret" class="button-icon"></clr-icon>
      <div class="text-overflow">
        {{comment.created_by_user.first_name + ' - ' + (comment.creation_date | dateFormatOnlyDate) + ' - ' +
      (comment.data.comment ? comment.data.comment : '')}}
      </div>

      <div class="icon-section">
        <clr-icon shape="phone-handset" class="phone-clicked is-solid" style=" margin-left: 5px"
                  *ngIf="comment.data.contacted_info && comment.data.contacted_info.phone"></clr-icon>
        <clr-icon shape="envelope" class="mail-clicked is-solid" style="margin-left: 5px"
                  *ngIf="comment.data.contacted_info && comment.data.contacted_info.mailed">
        </clr-icon>
      </div>
    </div>
  </div>

  <div (click)="showAddComment = true"
       *ngIf="!showAddComment" class="btn btn-outline btn-sm btn-menu">
    <clr-icon shape="chat-bubble"></clr-icon>
    Comment
  </div>
  <div *ngIf="showAddComment">
    <clr-icon shape="phone-handset" class="hover" style=" margin-left: 5px"
              (click)="phoneClicked = !phoneClicked"
              [ngClass]="{'phone-clicked': !!phoneClicked, 'is-solid': !!phoneClicked}"></clr-icon>
    <clr-icon shape="envelope" class="hover" style="margin-left: 5px"
              (click)="mailClicked = !mailClicked"
              [ngClass]="{'mail-clicked': !!mailClicked, 'is-solid': !!mailClicked}">
    </clr-icon>
  </div>
  <div *ngIf="showAddComment">
    <textarea [(ngModel)]="commentText"></textarea>
    <div class="btn-group">
      <button (click)="addComment()" class="btn btn-primary btn-sm">Add</button>
      <button (click)="clearComment()" class="btn btn-outline btn-sm">Cancel</button>
    </div>
  </div>
</div>
