import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ExactService } from '../services/exact.service';

@Injectable({
    providedIn: 'root',
})
export class ExactManager {
    constructor(private exactService: ExactService) {}

    public updateAllClientsFromExactInDB(): Observable<any> {
        return this.exactService.updateAllClientsFromExactInDB();
    }
}
