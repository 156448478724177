import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ROUTING } from '../../app.routing';
import { UserMaintenancePageComponent } from './containers/user-maintenance-page/user-maintenance-page.component';
import { SharedModule } from '../shared/shared.module';
import { ClarityModule } from '@clr/angular';
import { UserEditComponent } from './components/user-edit/user-edit.component';
import { ColorPickerModule } from 'ngx-color-picker';

const components = [UserEditComponent];

const containers = [UserMaintenancePageComponent];

const imports = [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ROUTING,
    SharedModule,
    ClarityModule,
    ColorPickerModule,
];

const sandboxes = [];

const services = [];

@NgModule({
    imports: [...imports],
    declarations: [...containers, ...components],
    providers: [...sandboxes, ...services],
    entryComponents: [],
})
export class UserMaintenanceModule {}
