import { BASE_URL } from 'src/environments/environment';
import ReactComponents from './index';
import { getAccessToken } from '../../app/modules/shared/resources/oidc';

export function reactComponentInitialize() {
    // @ts-ignore
    const baseUrl = BASE_URL === '/' ? location.origin : BASE_URL.replace(/\/$/, '');
    ReactComponents.PhrosprComponentsApi.setDefaultConfiguration({
        basePath: baseUrl,
        accessToken: getAccessToken,
    });
}

export const PhrosprComponents = {
    CommentAdd: ReactComponents.CommentAdd,
};
