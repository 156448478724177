"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseRequestQuoteListBody = void 0;
const iquote_search_entity_1 = require("./iquote-search-entity");
function parseRequestQuoteListBody(data) {
    if (data.quote_filter !== null && typeof data.quote_filter === 'object')
        data.quote_filter = (0, iquote_search_entity_1.parseIQuoteSearchEntity)(data.quote_filter);
    return data;
}
exports.parseRequestQuoteListBody = parseRequestQuoteListBody;
