import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { BASE_URL } from '../../../../environments/environment';
import { IPlanningItemAttachment } from '@phrospr/api-backend';

@Injectable()
export class PlanningItemService {
    constructor(private http: HttpClient) {}

    public async uploadPlanningItemAttachment(planning_item_id: number, formData: FormData) {
        const output = await (
            this.http.post(`${BASE_URL}api/planning-item/attachment`, formData, {
                params: { planning_item_id: `${planning_item_id}` },
            }) as Observable<IPlanningItemAttachment>
        ).toPromise();
        // return output.map(parsePlanningItemHttp); is not used since we don't get anything returned.
        return output;
    }

    public async downloadAttachment(fileName: string): Promise<Blob> {
        const params: any = { file_name: fileName };
        return (
            this.http.get(`${BASE_URL}api/storage/${fileName}`, { params, responseType: 'blob' }) as Observable<Blob>
        ).toPromise<Blob>();
    }

    public async deleteAttachment(planning_item_attachment_id: number) {
        return this.http
            .delete(
                `${BASE_URL}api/planning-item/attachment?planning_item_attachment_id=${planning_item_attachment_id}`,
            )
            .toPromise();
    }
}
