<div>
    <div class="card-content-search card-content search-content">
        <div class="search-item grid-item">
            <div>
                <input
                    (ngModelChange)="triggerFilter(filterKeys.PRODUCT_NUMBER, $event)"
                    [(ngModel)]="productNumber"
                    id="productNumber"
                    type="text"
                    placeholder="Zoek op product nummer"
                    style="width: 200px"
                />
            </div>
        </div>
        <div class="search-item grid-item">
            <div>
                <input
                    (ngModelChange)="triggerFilter(filterKeys.PRODUCT_NAME, $event)"
                    [(ngModel)]="productNameTrigger"
                    id="productName"
                    type="text"
                    placeholder="Zoek op product  naam"
                    style="width: 200px"
                />
            </div>
        </div>

        <div class="search-item grid-item">
            <div>
                <clr-checkbox-container>
                    <clr-checkbox-wrapper>
                        <input
                            type="checkbox"
                            clrCheckbox
                            [checked]="productActive"
                            (ngModelChange)="checkboxProductActiveClicked($event)"
                            [(ngModel)]="productActive"
                            name="QuoteStatus"
                        />
                        <label>Created</label>
                    </clr-checkbox-wrapper>
                </clr-checkbox-container>
            </div>
        </div>

        <div class="search-item grid-item">
            <button (click)="resetFilters()" class="btn btn-link btn-sm">
                <clr-icon shape="times"></clr-icon>
                Clear filters
            </button>
        </div>
    </div>
</div>
