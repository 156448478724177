
<!--
<div class="card-header">
  <a class="lighter pointer"  (click)="goToPlanningItemDetail(planningItemID)">
    <clr-icon shape="caret" dir="left"></clr-icon>
    <p class="lighter" style="margin: 10px 0px">Terug</p>
  </a>
</div>-->

<div class="card">
  <div id="content">
    <h1>Klant historiek</h1>
    <ul class="timeline">
      <li *ngFor="let planningItem of planningItemsClientHistory" class="event"
          [attr.data-date]="planningItem.creation_date | dateFormatOnlyDate">
        <h3>
          <a class="pointer" (click)="goToPlanningItemDetail(planningItem.planning_item_id)">
            {{planningItem.planning_item_type}} {{planningItem.planning_item_type_number}}
          </a>
        </h3>
        <p>Info to add</p>
      </li>
    </ul>
  </div>

</div>
