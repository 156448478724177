import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ROUTING } from '../../app.routing';
import { ClarityModule, ClrButtonGroupModule, ClrButtonModule, ClrTabsModule } from '@clr/angular';
import { SharedModule } from '../shared/shared.module';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PlanningItemListDetailComponent } from './components/planning-item-list-detail-card/planning-item-list-detail.component';
import { PlanningItemListContainer } from './containers/planning-item-list-detail/planning-item-list.container';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { PlanningItemListSlimContainer } from './containers/planning-item-list-slim/planning-item-list-slim.container';
import { PlanningItemFinishModalComponent } from './components/planning-item-finish-modal/planning-item-finish-modal.component';
import { PlanningItemDetailPageComponent } from './containers/planning-item-detail-page/planning-item-detail-page.component';
import { PlanningItemHistoryPageContainer } from './containers/planning-item-history-page/planning-item-history-page.container';

const components = [PlanningItemListDetailComponent, PlanningItemFinishModalComponent];

const containers = [
    PlanningItemListSlimContainer,
    PlanningItemListContainer,
    PlanningItemDetailPageComponent,
    PlanningItemHistoryPageContainer,
];

const imports = [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ROUTING,
    ClarityModule,
    ClrTabsModule,
    ClrButtonModule,
    SharedModule,
    ClrButtonGroupModule,
    CalendarModule.forRoot({
        provide: DateAdapter,
        useFactory: adapterFactory,
    }),
    NgbModule,
    NgbModalModule,
    NgxDatatableModule,
];

const sandboxes = [];

const services = [];

@NgModule({
    imports: [...imports, NgbModalModule],
    declarations: [...containers, ...components],
    providers: [...sandboxes, ...services],
    exports: [PlanningItemListContainer, PlanningItemListSlimContainer],
    entryComponents: [],
})
export class PlanningItemModule {}
