"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseIPlanningItemEntity = void 0;
const iactivity_entity_1 = require("./iactivity-entity");
const icalendar_item_entity_1 = require("./icalendar-item-entity");
const iclient_entity_1 = require("./iclient-entity");
const icomment_entity_1 = require("./icomment-entity");
const iplanning_item_attachment_1 = require("./iplanning-item-attachment");
const iplanning_item_data_entity_1 = require("./iplanning-item-data-entity");
const iplanning_item_report_entity_1 = require("./iplanning-item-report-entity");
const iquote_entity_1 = require("./iquote-entity");
function parseIPlanningItemEntity(data) {
    if (typeof data.creation_date === 'string')
        data.creation_date = new Date(data.creation_date);
    if (data.data !== null && typeof data.data === 'object')
        data.data = (0, iplanning_item_data_entity_1.parseIPlanningItemDataEntity)(data.data);
    if (data.client !== null && typeof data.client === 'object')
        data.client = (0, iclient_entity_1.parseIClientEntity)(data.client);
    if (data.assigned_calendar_item !== null && typeof data.assigned_calendar_item === 'object')
        data.assigned_calendar_item = (0, icalendar_item_entity_1.parseICalendarItemEntity)(data.assigned_calendar_item);
    if (data.selected_calendar_item !== null && typeof data.selected_calendar_item === 'object')
        data.selected_calendar_item = (0, icalendar_item_entity_1.parseICalendarItemEntity)(data.selected_calendar_item);
    if (data.comments !== null && typeof data.comments === 'object')
        data.comments = data.comments.map((x) => (0, icomment_entity_1.parseICommentEntity)(x));
    if (data.activities !== null && typeof data.activities === 'object')
        data.activities = data.activities.map((x) => (0, iactivity_entity_1.parseIActivityEntity)(x));
    if (data.calendar_items !== null && typeof data.calendar_items === 'object')
        data.calendar_items = data.calendar_items.map((x) => (0, icalendar_item_entity_1.parseICalendarItemEntity)(x));
    if (data.last_completed_planning_item !== null && typeof data.last_completed_planning_item === 'object')
        data.last_completed_planning_item = parseIPlanningItemEntity(data.last_completed_planning_item);
    if (data.visit_reports !== null && typeof data.visit_reports === 'object')
        data.visit_reports = data.visit_reports.map((x) => (0, iplanning_item_report_entity_1.parseIPlanningItemReportEntity)(x));
    if (data.attachments !== null && typeof data.attachments === 'object')
        data.attachments = data.attachments.map((x) => (0, iplanning_item_attachment_1.parseIPlanningItemAttachment)(x));
    if (data.last_approved_quote_slim !== null && typeof data.last_approved_quote_slim === 'object')
        data.last_approved_quote_slim = (0, iquote_entity_1.parseIQuoteEntity)(data.last_approved_quote_slim);
    return data;
}
exports.parseIPlanningItemEntity = parseIPlanningItemEntity;
