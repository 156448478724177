import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ROUTING } from '../../app.routing';
import { SharedModule } from '../shared/shared.module';
import { ClarityModule } from '@clr/angular';
import { SalesPageComponent } from './containers/sales-page/sales-page.component';

const components = [];

const containers = [SalesPageComponent];

const imports = [CommonModule, FormsModule, ReactiveFormsModule, ROUTING, SharedModule, ClarityModule];

const sandboxes = [];

const services = [];

@NgModule({
    imports: [...imports],
    declarations: [...containers, ...components],
    providers: [...sandboxes, ...services],
    entryComponents: [],
})
export class SalesModule {}
