import {
    ICalendarCreateEventBodyAttendeeType,
    ICalendarCreateEventBodyContentType,
    ICalendarCreateEventBodyEntity,
    ICalendarCreateEventBodyTimeZone,
} from '@phrospr/lib-models';
import { MailEngine } from './mail.engine';
import { ICalendarItemEntity, IClientEntity, IPlanningItemEntity, PlanningItemType } from '@phrospr/api-backend';
import { PlanningItemEngine } from './planning-item.engine';

export function newCalendarItemEntity(): ICalendarItemEntity {
    return {
        calendar_item_id: null,
        planning_item_id: null,
        date_time_start: null,
        date_time_stop: null,
        created_by_user_id: null,
        in_calendar_of_user_id: null,
        data: null,
        appointment_type: null,
        // temporary until we remove this field
        active: false,
    };
}

export namespace CalendarItemEngine {
    const titlePrefixMapper = {
        [PlanningItemType.maintenance]: 'Ond. - ',
        [PlanningItemType.lead]: 'Lead. - ',
        [PlanningItemType.delivery]: 'Lev. - ',
        [PlanningItemType.repair]: 'Dep. - ',
        [PlanningItemType.placement]: 'Pla. - ',
    };

    export function getTitleForCalendarEvent(planning_item_type: string, client: IClientEntity): string {
        return `${
            titlePrefixMapper[planning_item_type] ? titlePrefixMapper[planning_item_type] : '??? - '
        }${PlanningItemEngine.getClientName(client)}`;
    }

    export function getPlanningItemLink(
        planning_item_id: number,
        origin: string = 'https://aquagroup.phrospr.com',
    ): string {
        const link = `${origin}/planning-item-detail?planning_item_id=${planning_item_id}`;
        return `<br/><a href="${link}">
${link}</a><br/>`;
    }

    export function generateCalendarCreateBody(
        data: { location: string; email: string; start_time: Date; stop_time: Date },
        planningItem: IPlanningItemEntity,
        client: IClientEntity,
        content?: string,
        origin: string = 'https://aquagroup.phrospr.com',
    ): ICalendarCreateEventBodyEntity {
        if (!content) content = MailEngine.getPlanningMailContent(planningItem, client);
        return {
            subject: getTitleForCalendarEvent(planningItem.planning_item_type, client),
            start: {
                datetime: data.start_time.toISOString(),
                timeZone: ICalendarCreateEventBodyTimeZone.UTC,
            },
            end: {
                datetime: data.stop_time.toISOString(),
                timeZone: ICalendarCreateEventBodyTimeZone.UTC,
            },
            location: {
                displayName: data.location,
            },
            attendees: [
                {
                    emailAddress: { address: data.email, name: null },
                    type: ICalendarCreateEventBodyAttendeeType.Required,
                },
            ],
            body: {
                contentType: ICalendarCreateEventBodyContentType.HTML,
                content: content + getPlanningItemLink(planningItem.planning_item_id, origin),
            },
        };
    }
}
