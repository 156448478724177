import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as toastr from 'toastr';
import { IPlanningItemEntity, IPlanningItemReportEntity, IActivityEntity, ICommentEntity } from '@phrospr/api-backend';
import { PhrosprPortalApi } from '../../../../../resource/phrospr-api';
import { parsePaymentMethodTypeReadable } from '@phrospr/lib-core/src/engine/planning-item.engine';

export interface IPortalVisitReport extends IPlanningItemReportEntity {
    hide?: boolean;
}

export interface IPortalActivity extends IActivityEntity {
    hide?: boolean;
}

export interface IPortalComment extends ICommentEntity {
    hide?: boolean;
}

@Component({
    selector: 'pr-activity-section',
    styleUrls: ['./activity-section.component.scss'],
    templateUrl: './activity-section.component.html',
})
export class ActivitySectionComponent implements OnInit {
    @Input() comments: IPortalComment[];
    @Input() reports: IPortalVisitReport[];
    @Input() activities: IPortalActivity[];
    @Input() planningItem: IPlanningItemEntity;

    // WHAT DO WE NEED AS OUTPUT??
    @Output() refreshComments = new EventEmitter<any>();

    numberOfCommentsToShow = 5;
    olderComments = true;

    numberOfReportsToShow = 5;
    olderReports = true;

    numberOfActivitiesToShow = 5;
    olderActivities = true;

    state = 'comment';

    showAddComment = false;
    commentText = '';

    parsePaymentMethodTypeReadable = parsePaymentMethodTypeReadable;

    constructor() {}

    ngOnInit(): void {}

    cancelComment() {
        this.showAddComment = false;
    }

    async addComment() {
        if (!this.commentText) {
            return toastr.error('please fill in a comment');
        }
        await PhrosprPortalApi.PlanningItemCommentApi.addPlanningItemComment(this.planningItem.planning_item_id, {
            created_by_user_id: null,
            creation_date: new Date(),
            comment_id: null,
            data: {
                comment: this.commentText,
                contacted_info: {
                    mailed: false,
                    phone: false,
                },
            },
        });

        this.commentText = null;
        this.showAddComment = false;
        this.refreshComments.emit();
    }

    commentAdded() {
        this.commentText = null;
        this.showAddComment = false;
        this.refreshComments.emit();
    }

    showAllComments() {
        this.numberOfCommentsToShow = 9999;
        this.olderComments = false;
    }

    showAllReports() {
        this.numberOfReportsToShow = 9999;
        this.olderReports = false;
    }

    showAllActivities() {
        this.numberOfActivitiesToShow = 9999;
        this.olderActivities = false;
    }
}
