import { NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClarityModule, ClrButtonGroupModule, ClrButtonModule, ClrTabsModule } from '@clr/angular';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CalendarViewPageComponent } from './calendar-view-page/calendar-view-page.component';
import { ROUTING } from '../../../app.routing';
import { SharedModule } from '../../shared/shared.module';

registerLocaleData(localeFr);

const components = [];

const containers = [CalendarViewPageComponent];

const imports = [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ROUTING,
    ClarityModule,
    ClrTabsModule,
    ClrButtonModule,
    SharedModule,
    ClrButtonGroupModule,
    CalendarModule.forRoot({
        provide: DateAdapter,
        useFactory: adapterFactory,
    }),
    NgbModule,
    NgbModalModule,
];

const sandboxes = [];

const services = [];

@NgModule({
    imports: [...imports, NgbModalModule],
    declarations: [...containers, ...components],
    providers: [...sandboxes, ...services],
    entryComponents: [],
})
export class CalendarViewModule {}
