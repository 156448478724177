"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseIQuoteEntity = void 0;
const iaddress_1 = require("./iaddress");
const iclient_entity_1 = require("./iclient-entity");
const icomment_entity_1 = require("./icomment-entity");
const iplanning_item_entity_1 = require("./iplanning-item-entity");
const iquote_client_form_entity_1 = require("./iquote-client-form-entity");
const iquote_data_entity_1 = require("./iquote-data-entity");
const iquote_line_entity_1 = require("./iquote-line-entity");
const iuser_entity_1 = require("./iuser-entity");
function parseIQuoteEntity(data) {
    if (typeof data.creation_date === 'string')
        data.creation_date = new Date(data.creation_date);
    if (data.client_form !== null && typeof data.client_form === 'object')
        data.client_form = (0, iquote_client_form_entity_1.parseIQuoteClientFormEntity)(data.client_form);
    if (data.data !== null && typeof data.data === 'object')
        data.data = (0, iquote_data_entity_1.parseIQuoteDataEntity)(data.data);
    if (data.quote_lines !== null && typeof data.quote_lines === 'object')
        data.quote_lines = data.quote_lines.map((x) => (0, iquote_line_entity_1.parseIQuoteLineEntity)(x));
    if (data.planning_item_placement !== null && typeof data.planning_item_placement === 'object')
        data.planning_item_placement = (0, iplanning_item_entity_1.parseIPlanningItemEntity)(data.planning_item_placement);
    if (data.planning_item_lead !== null && typeof data.planning_item_lead === 'object')
        data.planning_item_lead = (0, iplanning_item_entity_1.parseIPlanningItemEntity)(data.planning_item_lead);
    if (data.client !== null && typeof data.client === 'object')
        data.client = (0, iclient_entity_1.parseIClientEntity)(data.client);
    if (data.delivery_address !== null && typeof data.delivery_address === 'object')
        data.delivery_address = (0, iaddress_1.parseIAddress)(data.delivery_address);
    if (data.created_by_user !== null && typeof data.created_by_user === 'object')
        data.created_by_user = (0, iuser_entity_1.parseIUserEntity)(data.created_by_user);
    if (data.sold_by_user !== null && typeof data.sold_by_user === 'object')
        data.sold_by_user = (0, iuser_entity_1.parseIUserEntity)(data.sold_by_user);
    if (data.comments !== null && typeof data.comments === 'object')
        data.comments = data.comments.map((x) => (0, icomment_entity_1.parseICommentEntity)(x));
    return data;
}
exports.parseIQuoteEntity = parseIQuoteEntity;
