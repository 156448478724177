import { Component, OnInit } from '@angular/core';
import { QuoteService } from '../../../shared/services/quote.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { IFEUserEntity, IPortalUserEntity } from '../../../shared/manager/entity/user.entity';
import * as toastr from 'toastr';
import { PhrosprPortalApi } from '../../../../../resource/phrospr-api';
import { IClientEntity, IQuoteEntity, IQuoteCreateEntity, IQuoteDataRating } from '@phrospr/api-backend';
import { newQuote } from '@phrospr/lib-core/src/engine/quote.engine';
import { user$ } from '../../../shared/resources/oidc/phrospr-user';
import { fromPromise } from 'rxjs/internal-compatibility';

@Component({
    selector: 'pr-upsert-quote-page',
    styleUrls: ['./upsert-quote-page.component.scss'],
    templateUrl: './upsert-quote-page.component.html',
})
export class UpsertQuotePageComponent implements OnInit {
    selectedClient: IClientEntity;
    isNewQuote = true;
    isExistingClient = true;
    selectClient = true; // set this value to false when a lead is supplied in the query params
    client_id: number;
    lead_planning_item_id: number;

    userList: IPortalUserEntity[] = [];
    loggedInUser: IFEUserEntity;

    upsertQuote: IQuoteEntity;

    vendorFormGroup: FormGroup;
    clientFormGroup: FormGroup;
    machineFormGroup: FormGroup;
    technicalFormGroup: FormGroup;
    settingsQuoteFormGroup: FormGroup;

    client_form_first_name: FormControl;
    client_form_last_name: FormControl;

    btwOptions = [
        { value: 1.06, name: '6%' },
        { value: 1.21, name: '21%' },
    ];

    subtotal = 0;
    btw = 1.21;

    rating: IQuoteDataRating = {
        value: 0,
        max: 5,
    };
    rateChanged = false;

    saving = false;

    constructor(
        private quoteService: QuoteService,
        private router: Router,
        private _formBuilder: FormBuilder,
        private route: ActivatedRoute,
    ) {
        this.setFormGroups();
    }

    ngOnInit(): void {
        fromPromise(user$).subscribe(user => {
            this.loggedInUser = user;
        });
        this.getUserList();
        this.loadQuote().catch(err => console.log(err));
        this.route.queryParams.pipe(first()).subscribe(async params => {
            if (params.planning_item_id || params.planning_item_id === 0) {
                this.client_id = parseInt('' + params.client_id, 10);
                this.selectClient = false;
                const planning_item_id = parseInt('' + params.planning_item_id, 10);
                if (!planning_item_id && planning_item_id !== 0) return toastr.error('missing planning item id');
                this.lead_planning_item_id = planning_item_id;
                const planningItem = (
                    await PhrosprPortalApi.PlanningItemApi.getPlanningItemDetail(undefined, planning_item_id)
                ).data;
                this.setClient(planningItem.client);
                this.enableBtwOptionZeroPercentage();
            }
        });

        // fetch all the users

        // todo if no quote create new

        // fixme add lead_id from existing lead
    }

    enableBtwOptionZeroPercentage() {
        if (
            this.clientFormGroup.get('bank_account').value !== '' &&
            this.btwOptions.filter(btwoption => btwoption.value === 1).length === 0
        ) {
            this.btwOptions.push({ value: 1, name: '0%' });
        } else if (
            this.clientFormGroup.get('bank_account').value === '' &&
            this.btwOptions.filter(btwoption => btwoption.value === 1).length > 0
        ) {
            this.btwOptions.splice(
                this.btwOptions.findIndex(element => element.value === 1),
                1,
            );
            this.machineFormGroup.controls['btw'].setValue(1.21);
        }
    }

    async loadQuote() {
        this.route.queryParams.pipe(first()).subscribe(async params => {
            if (!params.quote_id) {
                this.upsertQuote = newQuote();
                this.setSoldByUserId();
                return;
            }
            this.isNewQuote = false;
            this.upsertQuote = (await PhrosprPortalApi.QuoteApi.getQuote(parseInt('' + params.quote_id, 10))).data;
            if (this.upsertQuote.data.rating) {
                this.rating = this.upsertQuote.data.rating;
            }

            // load forms
            const quoteCreate = this.upsertQuote.data.quote_create;
            this.setQuoteCreate(quoteCreate);
        });
    }

    async getUserList() {
        this.userList = (await PhrosprPortalApi.UserApi.listUsers()).data.filter(user => !user.deleted);
        console.log(this.userList);
    }

    setSoldByUserId() {
        this.machineFormGroup.controls['btw'].setValue(1.21);
        this.settingsQuoteFormGroup.controls['sold_by_user_id'].setValue(this.loggedInUser.user_id);
        this.upsertQuote.data.sold_by_user_id = this.loggedInUser.user_id;
    }

    setFormGroups() {
        this.vendorFormGroup = this._formBuilder.group({
            secondCtrl: ['', [Validators.required]],
        });
        this.client_form_first_name = new FormControl('', { validators: [Validators.required] });
        this.client_form_last_name = new FormControl('', { validators: [] });
        this.clientFormGroup = new FormGroup({
            language: new FormControl('nl', { validators: [Validators.required] }),
            first_name: new FormControl('', { validators: [Validators.required] }),
            last_name: new FormControl('', { validators: [] }),
            address: new FormControl('', { validators: [Validators.required] }),
            city: new FormControl('', { validators: [Validators.required] }),
            zip_code: new FormControl('', { validators: [Validators.required] }),
            phone: new FormControl('', { validators: [Validators.required] }),
            email: new FormControl('', { validators: [Validators.required, Validators.email] }),
            bank_account: new FormControl('', { validators: [Validators.pattern(/^BE\d{10}$/)] }),
            remarks: new FormControl('', { validators: [] }),
        });
        this.machineFormGroup = new FormGroup({
            klep: new FormControl('', { validators: [Validators.required] }),
            bypass: new FormControl('', { validators: [] }),
            n_liters: new FormControl('', { validators: [Validators.required] }),
            type_harsen: new FormControl('', { validators: [] }),
            filter: new FormControl('', { validators: [] }),
            kdf_filters: new FormControl('', { validators: [] }),
            drukregelaar: new FormControl('', { validators: [] }),
            vlotter: new FormControl('', { validators: [] }),
            plaatsing_kost: new FormControl(0, { validators: [Validators.required] }),
            zout: new FormControl('', { validators: [] }),
            subtotal: new FormControl(0, { validators: [Validators.required] }),
            btw: new FormControl(1.21, { validators: [Validators.required] }),
            voorkeurs_plaatsing_datum: new FormControl(new Date().toISOString(), { validators: [] }),
        });
        this.technicalFormGroup = new FormGroup({
            analyse_f: new FormControl('', { validators: [Validators.required] }),
            leidingen: new FormControl('', { validators: [Validators.required] }),
            keer_klep: new FormControl('', { validators: [Validators.required] }),
            stopcontact: new FormControl('', { validators: [] }),
            afvoer: new FormControl('', { validators: [] }),
        });
        this.settingsQuoteFormGroup = new FormGroup({
            sold: new FormControl(false, { validators: [] }),
            mail_quote_to_client: new FormControl(true, { validators: [] }),
            sold_by_user_id: new FormControl(true, { validators: [] }),
        });
    }

    setClient(client: IClientEntity) {
        this.selectedClient = client;
        this.clientFormGroup.patchValue({
            first_name: client.client_data.first_name,
            last_name: client.client_data.last_name,
            address: client.client_data.main_address.address,
            city: client.client_data.main_address.city,
            zip_code: client.client_data.main_address.zip_code,
            phone: client.client_data.telephone_phone,
            email: client.client_data.email,
            bank_account:
                client.client_data.company_data && client.client_data.company_data.bank_account
                    ? client.client_data.company_data.bank_account
                    : '',
        });
    }

    getTotal() {
        const data = this.machineFormGroup.getRawValue();
        return (1.0 * data.subtotal * data.btw + data.plaatsing_kost).toFixed(2);
    }

    updatePlacementDate(value: any) {
        this.machineFormGroup.patchValue({ voorkeurs_plaatsing_datum: value });
    }

    setQuoteCreate(quoteCreate: IQuoteCreateEntity) {
        this.clientFormGroup.patchValue({ ...quoteCreate.clientFiche });
        this.machineFormGroup.patchValue({ ...quoteCreate.machineFiche });
        this.technicalFormGroup.patchValue({ ...quoteCreate.technicalFiche });
    }

    async cancel() {
        await this.router.navigateByUrl('/offer');
    }

    async updateQuote() {
        this.checkForms();
        if (this.technicalFormGroup.invalid || this.machineFormGroup.invalid || this.clientFormGroup.invalid) {
            toastr.error(`Niet alle velden zijn correct ingevuld! Verplichte velden: Email,
      postcode, Voornaam, adres, tel nr, analyse, leidingen, keer klep, klep, aantal liters`);
            return;
        }
        if (
            !this.technicalFormGroup.dirty &&
            !this.machineFormGroup.dirty &&
            !this.clientFormGroup.dirty &&
            !this.rateChanged
        ) {
            toastr.error('Er is niets gewijzigd');
            return;
        }
        if (this.saving) return;
        this.saving = true;
        const quoteCreate: IQuoteCreateEntity = {
            technicalFiche: this.technicalFormGroup.getRawValue(),
            machineFiche: this.machineFormGroup.getRawValue(),
            clientFiche: this.clientFormGroup.getRawValue(),
            quote_id: this.upsertQuote.quote_id,
            rating: this.rating,
        };
        try {
            await PhrosprPortalApi.QuoteApi.updateQuoteRaw(
                { quote: quoteCreate },
                this.upsertQuote.planning_item_lead && this.upsertQuote.planning_item_lead.lead_id !== null
                    ? this.upsertQuote.planning_item_lead.lead_id
                    : undefined,
            );
            await this.router.navigateByUrl('/offer');
        } catch (err) {
            console.error(err);
            toastr.error('Er is iets misgegaan bij het opslaan!');
        }
        this.saving = false;
    }

    checkForms() {
        for (const controlName in this.clientFormGroup.controls) {
            this.clientFormGroup.controls[controlName].markAsTouched();
        }
        for (const controlName in this.machineFormGroup.controls) {
            this.machineFormGroup.controls[controlName].markAsTouched();
        }
        for (const controlName in this.technicalFormGroup.controls) {
            this.technicalFormGroup.controls[controlName].markAsTouched();
        }
    }

    async createQuote() {
        this.checkForms();
        if (this.technicalFormGroup.invalid || this.machineFormGroup.invalid || this.clientFormGroup.invalid) {
            toastr.error(`Niet alle velden zijn correct ingevuld!`);
            return;
        }
        if (this.selectClient && this.isExistingClient && !this.selectedClient) {
            return toastr.error('Gelieve een klant te selecteren');
        }
        if (this.saving) return;
        this.saving = true;
        const quoteCreate: IQuoteCreateEntity = {
            technicalFiche: this.technicalFormGroup.getRawValue(),
            machineFiche: this.machineFormGroup.getRawValue(),
            clientFiche: this.clientFormGroup.getRawValue(),
            quote_id: null,
            sold: this.settingsQuoteFormGroup.get('sold').value,
            mailQuoteToClient: this.settingsQuoteFormGroup.get('mail_quote_to_client').value,
            sold_by_user_id: this.settingsQuoteFormGroup.get('sold_by_user_id').value,
            rating: this.rating,
        };

        try {
            await PhrosprPortalApi.QuoteApi.createQuoteRaw({
                quote: quoteCreate,
                client_id:
                    this.selectClient && this.isExistingClient
                        ? this.selectedClient.client_id
                        : this.client_id
                        ? this.client_id
                        : undefined,
                lead_planning_item_id: this.lead_planning_item_id ? this.lead_planning_item_id : null,
                createAsLead: this.selectClient && !this.isExistingClient,
            });
            await this.router.navigateByUrl('/offer');
        } catch (err) {
            console.error(err);
            toastr.error('Er is iets misgegaan bij het opslaan!');
        }
        this.saving = false;
    }
}
