import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as toastr from 'toastr';
import { UserManager } from '../../../shared/manager/user.manager';
import { addDays, addMinutes, endOfWeek } from 'date-fns';
import { fromEvent, Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
    CalendarDateFormatter,
    CalendarEvent,
    CalendarEventAction,
    CalendarEventTimesChangedEvent,
    CalendarEventTitleFormatter,
    CalendarView,
} from 'angular-calendar';
import { finalize, takeUntil } from 'rxjs/operators';
import { DayViewHourSegment } from 'calendar-utils';
import * as moment from 'moment';
import { IPortalUserEntity } from '../../../shared/manager/entity/user.entity';
import { CustomEventTitleFormatter } from '../../../shared/engine/calendar-view/custom-event-title-formatter.provider';
import { CustomDateFormatter } from '../../../shared/engine/calendar-view/custom-date-formatter.provider';
import { environment } from '../../../../../environments/environment';
import {
    ICalendarCreateEventBodyEntity,
    ICalendarItemEntity,
    IPlanningItemEntity,
    PlanningItemType,
} from '@phrospr/api-backend';
import { MailEngine } from '@phrospr/lib-core/src/engine/mail.engine';
import { getMailTemplatePlanningItem } from '@phrospr/lib-core/src/engine/mail-template.engine';
import { CalendarItemEngine, newCalendarItemEntity } from '@phrospr/lib-core/src/engine/calendar-item.engine';
import { Office365Service } from '../../../shared/services/office365.service';
import { PhrosprPortalApi } from '../../../../../resource/phrospr-api';
import { Language, IEmailObjectEntity } from '@phrospr/lib-models';
import { PlanningItemPlanBodyPlanningItemMail } from '../../../../../../../api-backend/models';

const colors: any = {
    red: {
        primary: '#ad2121',
        secondary: '#FAE3E3',
    },
    blue: {
        primary: '#1e90ff',
        secondary: '#D1E8FF',
    },
    yellow: {
        primary: '#e3bc08',
        secondary: '#FDF1BA',
    },
};

const planningItemTypeRoutes = {
    [PlanningItemType.maintenance]: '/maintenance',
    [PlanningItemType.delivery]: '/deliveries',
    [PlanningItemType.lead]: '/leads',
    [PlanningItemType.placement]: '/placement',
    [PlanningItemType.repair]: '/repair',
};

function floorToNearest(amount: number, precision: number) {
    return Math.floor(amount / precision) * precision;
}

function ceilToNearest(amount: number, precision: number) {
    return Math.ceil(amount / precision) * precision;
}

export interface IMyCalendarEvent extends CalendarEvent {
    user?: string;
    location?: string;
    content?: string;
}

@Component({
    selector: 'pr-plan-calendar-item-page',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./plan-calendar-item-page.component.scss', '../../../../app.component.css'],
    templateUrl: './plan-calendar-item-page.component.html',
    providers: [
        {
            provide: CalendarEventTitleFormatter,
            useClass: CustomEventTitleFormatter,
        },
        {
            provide: CalendarDateFormatter,
            useClass: CustomDateFormatter,
        },
    ],
})
export class PlanCalendarItemPageComponent implements OnInit {
    planningItem: IPlanningItemEntity = null;

    calendarItem: ICalendarItemEntity = newCalendarItemEntity();

    hours: { hour: string }[] = Array.from({ length: 48 }, (v, i) => ({
        hour: moment.utc((i + 1) * 30 * 60 * 1000).format('HH:mm'),
    }));

    dragToSelectEvent: IMyCalendarEvent = {
        id: null,
        title: null,
        start: null,
        meta: {
            tmpEvent: true,
        },
        draggable: null,
        resizable: {
            beforeStart: null,
            afterEnd: null,
        },
        actions: null,
        color: null,
        location: null,
        content: null,
    };

    userListDb$: Promise<IPortalUserEntity[]>;

    @ViewChild('modalContent')
    modalContent: TemplateRef<any>;

    view: CalendarView = CalendarView.Week;

    CalendarView = CalendarView;

    viewDate: Date = new Date();

    modalData: {
        action: string;
        event: IMyCalendarEvent;
    };

    openCreateModal = false;

    loading = false;

    // Todo when this is needed
    actions: CalendarEventAction[] = [
        {
            label: '<div></div>',
            onClick: ({ event }: { event: CalendarEvent }): void => {
                this.handleEvent('Edited', event);
            },
        },
        {
            label: '<clr-icon shape="trash"></clr-icon>',
            onClick: ({ event }: { event: CalendarEvent }): void => {
                this.events = this.events.filter(iEvent => iEvent !== event);
                /*this.handleEvent('Deleted', event);*/
                /*  console.log('Event deleted', event);*/
            },
        },
    ];

    refresh: Subject<any> = new Subject();

    events: IMyCalendarEvent[] = [];

    activeDayIsOpen = true;

    visualizeMailCard = false;

    email_object: PlanningItemPlanBodyPlanningItemMail = {
        subject: '',
        content: '',
        receivers: [],
        senders: [{ email: 'info@aquagroup.be' }],
    };

    loadingSaveCalendarItem = false;

    constructor(
        private router: Router,
        private userManager: UserManager,
        private modal: NgbModal,
        private route: ActivatedRoute,
        private office365Service: Office365Service,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        this.route.queryParams.subscribe(params => {
            if (params['planning_item_id']) {
                this.getPlanningItem(params['planning_item_id']);
            }
        });

        this.userListDb$ = this.getUsers();
    }

    async getUsers() {
        try {
            return (await PhrosprPortalApi.UserApi.listUsers()).data.filter(
                user => !user.data.ignoreUserInCalendarList,
            );
        } catch (error) {
            console.log(error);
        }
    }

    getMailTemplateObjectPlanned(language: Language): void {
        const mailTemplate: IEmailObjectEntity = getMailTemplatePlanningItem(
            this.planningItem,
            this.dragToSelectEvent.start,
            language,
        );
        this.email_object.content = mailTemplate.content;
        this.email_object.subject = mailTemplate.subject;
        this.email_object = JSON.parse(JSON.stringify(this.email_object));
    }

    eventTimesChanged({ event, newStart, newEnd }: CalendarEventTimesChangedEvent): void {
        this.events = this.events.map(iEvent => {
            if (iEvent === event) {
                return {
                    ...event,
                    start: newStart,
                    end: newEnd,
                };
            }
            return iEvent;
        });

        // Event is triggered when resizing or dropping somewhere else
        this.handleEvent('Dropped or resized', event);
    }

    handleEvent(action: string, event: IMyCalendarEvent): void {
        this.modalData = { event, action };
        this.openCreateModal = true;
    }

    setView(view: CalendarView) {
        this.view = view;
    }

    async closeOpenMonthViewDay() {
        this.activeDayIsOpen = false;
        await this.refreshCalendar();
    }

    getSendToEmailForEmailObject() {
        return this.planningItem.client.client_data.email;
    }

    async startDragToCreate(segment: DayViewHourSegment, mouseDownEvent: MouseEvent, segmentElement: HTMLElement) {
        let defaultMinutes = 60;
        if (this.planningItem && this.planningItem.planning_item_type === PlanningItemType.placement)
            defaultMinutes = 120;
        const activeUsers = (await this.userListDb$).filter(u => u.visualize);
        this.dragToSelectEvent = {
            id: this.events.length,
            title: CalendarItemEngine.getTitleForCalendarEvent(
                this.planningItem.planning_item_type,
                this.planningItem.client,
            ),
            start: segment.date,
            end: addDays(addMinutes(segment.date, defaultMinutes), 0),
            meta: {
                tmpEvent: true,
            },
            draggable: false,
            resizable: {
                beforeStart: false,
                afterEnd: false,
            },
            actions: this.actions,
            color: colors.blue,
            user: activeUsers.length === 1 ? activeUsers[0].email : null,
            content: MailEngine.getPlanningMailContent(this.planningItem, this.planningItem.client),
        };

        this.events = [...this.events, this.dragToSelectEvent];
        const segmentPosition = segmentElement.getBoundingClientRect();
        const endOfView = endOfWeek(this.viewDate);

        // Safety check for testing in development
        const receivers = this.email_object.receivers;
        if (!environment.production) {
            this.addReceiverIfNotExists(receivers, 'teo.deconinck@phrospr.com');
            this.addReceiverIfNotExists(receivers, 'hendrik.hamerlinck@phrospr.com');
        } else {
            this.addReceiverIfNotExists(receivers, this.getSendToEmailForEmailObject());
        }
        this.getMailTemplateObjectPlanned(Language.nl);

        fromEvent(document, 'mousemove')
            .pipe(
                finalize(() => {
                    delete this.dragToSelectEvent.meta.tmpEvent;
                    this.refreshCalendar(false);
                }),
                takeUntil(fromEvent(document, 'mouseup')),
            )
            .subscribe((mouseMoveEvent: MouseEvent) => {
                const minutesDiff = ceilToNearest(mouseMoveEvent.clientY - segmentPosition.top, 30);

                const daysDiff =
                    floorToNearest(mouseMoveEvent.clientX - segmentPosition.left, segmentPosition.width) /
                    segmentPosition.width;

                const newEnd = addDays(addMinutes(segment.date, minutesDiff), daysDiff);
                if (newEnd > segment.date && newEnd < endOfView) {
                    this.dragToSelectEvent.end = newEnd;
                    this.refresh.next();
                }
                this.refreshCalendar(false);
            });
    }

    async setToday() {
        this.viewDate = new Date();
        await this.refreshCalendar();
    }

    async refreshCalendar(refetch = true) {
        if (refetch) {
            console.log('refreshing', this.viewDate);
            const users = (await this.userListDb$).filter(u => u.visualize);
            this.loading = true;
            this.events = await this.userManager.loadEvents3Weeks(users, this.viewDate);
            this.loading = false;
        }
        this.cdr.detectChanges();
    }

    async getPlanningItem(planning_item_id: number) {
        try {
            this.planningItem = (
                await PhrosprPortalApi.PlanningItemApi.getPlanningItemDetail(undefined, planning_item_id)
            ).data;
        } catch (err) {
            console.error(err);
        }
    }

    close(modalAction?: string) {
        if (modalAction === 'Created') {
            this.events = this.events.filter(iEvent => iEvent !== this.dragToSelectEvent);
        }
        this.openCreateModal = false;
    }

    async visualizeUserCalendarData(user, visualize: boolean) {
        user.visualize = visualize;
        await this.refreshCalendar();
    }

    setTime(newCalendarEvent: CalendarEvent, time: string, start = true) {
        const obj = start ? newCalendarEvent.start : newCalendarEvent.end;
        obj.setHours(parseFloat(time), parseFloat(time.substring(3)));
        this.refresh.next();
        if (start) {
            this.getMailTemplateObjectPlanned(Language.nl);
        }
    }

    addZero(i) {
        if (i < 10) {
            i = '0' + i;
        }
        return i;
    }

    selectRightTime(timeFromCalendarEvent: Date, timeFromSelectOption: string) {
        if (timeFromCalendarEvent && timeFromSelectOption) {
            const hourAndMinFromCalendarEvent =
                this.addZero(timeFromCalendarEvent.getHours()).toString() +
                ':' +
                this.addZero(timeFromCalendarEvent.getMinutes()).toString();
            return hourAndMinFromCalendarEvent === timeFromSelectOption;
        }
        return;
    }

    async createCalendarEvent(calendarEvent: CalendarEvent) {
        this.loadingSaveCalendarItem = true;
        if (
            !this.dragToSelectEvent.start ||
            !this.dragToSelectEvent.end ||
            !this.dragToSelectEvent.user ||
            !this.dragToSelectEvent.location
        ) {
            this.loadingSaveCalendarItem = false;
            return toastr.error('fill in all fields');
        }
        const start_time = new Date(this.dragToSelectEvent.start);
        const stop_time = new Date(this.dragToSelectEvent.end);
        if (start_time.getTime() > stop_time.getTime()) {
            this.loadingSaveCalendarItem = false;
            return toastr.error(`start time is later then stop time`);
        }
        this.calendarItem.date_time_start = start_time;
        this.calendarItem.date_time_stop = stop_time;

        /*this.calendarItem.active = true;*/
        const content =
            '<p>' + this.dragToSelectEvent.content.replace(/\n{,}/g, '</p><p>').replace(/\n/g, '<br>') + '</p>';

        // Create calendar event to add in outlook
        const calendarEventToAdd: ICalendarCreateEventBodyEntity = CalendarItemEngine.generateCalendarCreateBody(
            {
                location: this.dragToSelectEvent.location,
                email: this.dragToSelectEvent.user,
                start_time,
                stop_time,
            },
            this.planningItem,
            this.planningItem.client,
            content +
                CalendarItemEngine.getTitleForCalendarEvent(
                    this.planningItem.planning_item_type,
                    this.planningItem.client,
                ),
            location.origin,
        );

        try {
            await PhrosprPortalApi.PlanningItemApi.planPlanningItem({
                calendarItem: this.calendarItem,
                planning_item_id: this.planningItem.planning_item_id,
                planningItemMail: this.email_object,
                mail: this.dragToSelectEvent.user,
                calendarEventToAdd,
            });
            if (planningItemTypeRoutes[this.planningItem.planning_item_type]) {
                await this.router.navigateByUrl(planningItemTypeRoutes[this.planningItem.planning_item_type]);
            }

            this.openCreateModal = false;
            this.loadingSaveCalendarItem = false;
        } catch (err) {
            console.error(err);
            this.loadingSaveCalendarItem = false;
            toastr.error('something went wrong, are all the fields correct?');
        }
    }

    addReceiverIfNotExists(receivers: { email: string }[], email: string) {
        if (receivers.filter(x => x.email === email).length === 0) {
            receivers.push({ email: email });
        }
    }

    saveMailLayout(newEmailObject) {
        this.email_object = newEmailObject;
        this.visualizeMailCard = false;
    }

    editMailLayout() {
        this.visualizeMailCard = true;
    }

    cancelMailLayout() {
        this.visualizeMailCard = false;
    }
}
