import { BASE_URL } from '../../environments/environment';
import * as ClientApi from '@phrospr/api-backend';
import axios from '@phrospr/api-backend/node_modules/axios';
import * as toastr from 'toastr';
import { getAccessToken } from '../../app/modules/shared/resources/oidc';

axios.interceptors.response.use(function (response) {
    // if (response.status === 401) {
    //     return AuthorizationManager.localLogout();
    // }
    if (response.status === 405) {
        toastr.error('Onvoldoende rechten!');
    }
    return response;
});

export function initializeApi() {
    // @ts-ignore
    const baseUrl = BASE_URL === '/' ? location.origin : BASE_URL.replace(/\/$/, '');
    ClientApi.setDefaultConfiguration({
        basePath: baseUrl,
        accessToken: getAccessToken,
    });
}

export const PhrosprPortalApi = ClientApi;
