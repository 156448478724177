import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ROUTING } from '../../app.routing';
import { SharedModule } from '../shared/shared.module';
import { ClarityModule } from '@clr/angular';
import { ClientPageComponent } from './containers/client-page/client-page.component';
import { ClientDetailPageComponent } from './containers/client-detail-page/client-detail-page.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { BrowserModule } from '@angular/platform-browser';

const components = [];

const containers = [ClientPageComponent, ClientDetailPageComponent];

const imports = [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ROUTING,
    SharedModule,
    ClarityModule,
    NgxDatatableModule,
    BrowserModule,
];

const sandboxes = [];

const services = [];

@NgModule({
    imports: [...imports],
    declarations: [...containers, ...components],
    providers: [...sandboxes, ...services],
    entryComponents: [],
})
export class ClientModule {}
