import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PlanningItemType, IPlanningItemSearchFilterEntity } from '@phrospr/api-backend';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import * as moment from 'moment';
import { validatePlanningItemSearchFilterKey } from '@phrospr/lib-core/src/engine/planning-item.engine';

@Component({
    selector: 'pr-planning-item-list-filter',
    styleUrls: ['./planning-item-list-filter.component.scss'],
    templateUrl: './planning-item-list-filter.component.html',
})
export class PlanningItemListFilterComponent implements OnInit {
    @Input() planningItemSearchFilter: IPlanningItemSearchFilterEntity;
    @Input() suggestedDatePicker = false;
    @Input() createdDatePicker = false;
    // The planned date picker = completed date picker since we check the same field
    @Input() plannedDatePicker = false;
    @Input() protestDatePicker = false;
    /*NOT POSSIBLE HERE FOR NOW AND WE DON'T WANT TO INVEST IN THIS*/
    @Input() searchQuoteSoldByName = false;

    @Output() updatePlanningItemSearchFilter = new EventEmitter<IPlanningItemSearchFilterEntity>();

    filterChangeSubject$: Subject<void> = new Subject<void>();

    fromSuggestedDateTrigger = '';
    toSuggestedDateTrigger = '';
    fromCreatedDateTrigger = '';
    toCreatedDateTrigger = '';
    fromPlannedDateTrigger = '';
    toPlannedDateTrigger = '';
    typeNumberId = '';
    clientNameTrigger = '';
    zipCodeTrigger = '';
    quoteSoldByNameTrigger = '';

    filterKeys = {
        TYPE_NUMBER: 'planning_item_type_number',
        FROM_SUGGESTED_DATE: 'from_suggested_date',
        TO_SUGGESTED_DATE: 'to_suggested_date',
        FROM_CREATED_DATE: 'from_created_date',
        TO_CREATED_DATE: 'to_created_date',
        FROM_PLANNED_DATE: 'from_planned_date',
        TO_PLANNED_DATE: 'to_planned_date',
        CLIENT_NUMBER: 'client_number',
        CLIENT_NAME: 'client_name',
        POSTCODE: 'postcode',
        PLANNING_ITEM_TYPE: PlanningItemType,
        QUOTE_SOLD_BY_USER_NAME: 'quote_sold_by_user_name',
    };

    constructor() {}

    ngOnInit(): void {
        if (!this.planningItemSearchFilter) {
            return console.log('missing planningItemsFilter');
        }
        if (!this.planningItemSearchFilter.planning_item_type) return console.log('missing planning item type');
        if (!this.planningItemSearchFilter.state) return console.log('missing planning item state');

        this.filterChangeSubject$.pipe(debounceTime(1000)).subscribe(_ => this.sendNewFilter());
    }

    triggerFilter(filterName, event) {
        if (!validatePlanningItemSearchFilterKey(filterName))
            return console.log('invalid filter key', filterName, event);
        this.planningItemSearchFilter[filterName] = event;
        this.filterChangeSubject$.next();
    }

    triggerDateFilter(filterName, event) {
        let date = moment(event, 'DD-MM-YYYY');
        if (filterName.startsWith('to')) date = moment(date).endOf('day');
        this.triggerFilter(filterName, date.toISOString());
    }

    sendNewFilter() {
        this.updatePlanningItemSearchFilter.emit(this.planningItemSearchFilter);
    }

    resetFilters() {
        for (const localFilterKey in this.filterKeys) {
            const filterKey = this.filterKeys[localFilterKey];
            this.planningItemSearchFilter[filterKey] = undefined;
        }

        this.fromSuggestedDateTrigger = null;
        this.toSuggestedDateTrigger = null;
        this.fromCreatedDateTrigger = null;
        this.toCreatedDateTrigger = null;
        this.fromPlannedDateTrigger = null;
        this.toPlannedDateTrigger = null;
        this.typeNumberId = null;
        this.clientNameTrigger = null;
        this.zipCodeTrigger = null;
        this.quoteSoldByNameTrigger = null;
        this.sendNewFilter();
    }
}
