<div *ngIf="!!client">
  <div class="card-content detail-content">
    <div>
      <div class="horizontal" *ngIf="client">
        <button class="btn btn-group-prospr btn-menu btn-sm " [class.active-button]="state==='activity'"
                (click)="state='activity'">
          Activity ({{activities.length}})
        </button>
      </div>

      <div class="content-section">
        <div class="card-content card-content-1" *ngIf="state==='activity' && activities">
          <div class="grid-item">
            <div>
              <div class="message-container pointer" *ngIf="activities.length > 5 && olderActivities">
                <a class="collapsed-comments" (click)="showAllActivities()">
                  <span class="collapsed-comments-line"></span>
                  <span class="collapsed-comments-line"></span>
                  <span class="show-more-comments" data-collapsed-count="37">
                  {{activities.length - 5}} older activity
                </span>
                </a>
              </div>
              <div *ngIf="activities && activities.length">
                <div
                     *ngFor="let activity of activities | slice:-numberOfActivitiesToShow; let i = index"
                     class="comment-block expanded" id="activity-{{activity.activity_id}}">
                  <div *ngIf="activity.hide">
                    <div class="comment-section verbose">
                      <div class="comment-header" (click)="activity.hide = !activity.hide">
                        <a class="icon-caret-comment">
                          <clr-icon dir="down" shape="caret"></clr-icon>
                        </a>
                        <div class="action-links">
                          <a class="activitymodule-link issue-comment-action"
                             title="Right click and copy link for a permanent link to this comment.">
                            <clr-icon class="is-solid small" shape="link"></clr-icon>
                          </a>
                          <a class="issue-comment-action" id="edit_activity_id" title="Edit">
                            <clr-icon class="is-solid small" shape="pencil"></clr-icon>
                          </a>
                          <a class="issue-comment-action" id="delete_activity_id" title="Delete">
                            <clr-icon class="is-solid small" shape="trash"></clr-icon>
                          </a>
                        </div>
                        <div>
                          {{activity.created_by_user.first_name + ' made changes '}} <b>{{activity.activity_type}}</b> {{' - ' +
                        (activity.creation_date | dateFormatWithTime)  }}

                        </div>
                      </div>
                      <div class="comment-body">
                        <p class="comment-paragraph">{{activity.activity_data | json}}</p>
                      </div>
                    </div>

                    <div class="comment-section concise">
                      <div class="comment-header">
                        <a class="icon-caret-comment">
                          <clr-icon dir="right" shape="caret"></clr-icon>
                        </a>
                        <div>
                          {{activity.created_by_user.first_name + ' made changes '}} <b>{{activity.activity_type}}</b> {{' - ' +
                        (activity.creation_date | dateFormatWithTime) + ' - ' +
                        activity.activity_data}}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="!activity.hide">
                    <div class="comment-section verbose">
                      <div class="comment-header" (click)="activity.hide = !activity.hide">
                        <a class="icon-caret-comment">
                          <clr-icon dir="right" shape="caret"></clr-icon>
                        </a>
                        <div class="action-links">
                          <a class="activitymodule-link issue-comment-action"
                             title="Right click and copy link for a permanent link to this comment.">
                            <clr-icon class="is-solid small" shape="link"></clr-icon>
                          </a>
                          <a class="issue-comment-action" title="Edit">
                            <clr-icon class="is-solid small" shape="pencil"></clr-icon>
                          </a>
                          <a class="issue-comment-action" title="Delete">
                            <clr-icon class="is-solid small" shape="trash"></clr-icon>
                          </a>
                        </div>
                        <div class="comment-hidden">
                          {{activity.created_by_user.first_name + ' made changs '}}
                          <b>{{activity.activity_type}}</b> {{' - ' +
                        (activity.creation_date | dateFormatWithTime)}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="!activities.length">
                Nog geen geschiedenis beschikbaar
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</div>

