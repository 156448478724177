"use strict";
/* tslint:disable */
/* eslint-disable */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuoteApi = exports.QuoteApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
// @ts-ignore
const models_1 = require("../models");
// @ts-ignore
const models_2 = require("../models");
// @ts-ignore
const models_3 = require("../models");
/**
 * QuoteApi - axios parameter creator
 * @export
 */
const QuoteApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {number} quoteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelQuote: (quoteId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'quoteId' is not null or undefined
            (0, common_1.assertParamExists)('cancelQuote', 'quoteId', quoteId);
            const localVarPath = `/api/quote/cancel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // http bearer authentication if accessToken is configured
            yield (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration);
            if (quoteId !== undefined) {
                localVarQueryParameter['quote_id'] = quoteId;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {RequestQuoteCreateBody} requestQuoteCreateBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createQuoteRaw: (requestQuoteCreateBody, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'requestQuoteCreateBody' is not null or undefined
            (0, common_1.assertParamExists)('createQuoteRaw', 'requestQuoteCreateBody', requestQuoteCreateBody);
            const localVarPath = `/api/quote/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // http bearer authentication if accessToken is configured
            yield (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(requestQuoteCreateBody, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAttachmentList: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/quote/attachment-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // http bearer authentication if accessToken is configured
            yield (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {number} quoteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuote: (quoteId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'quoteId' is not null or undefined
            (0, common_1.assertParamExists)('getQuote', 'quoteId', quoteId);
            const localVarPath = `/api/quote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // http bearer authentication if accessToken is configured
            yield (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration);
            if (quoteId !== undefined) {
                localVarQueryParameter['quoteId'] = quoteId;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {RequestQuoteListBody} requestQuoteListBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuoteListPost: (requestQuoteListBody, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'requestQuoteListBody' is not null or undefined
            (0, common_1.assertParamExists)('getQuoteListPost', 'requestQuoteListBody', requestQuoteListBody);
            const localVarPath = `/api/quote/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // http bearer authentication if accessToken is configured
            yield (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(requestQuoteListBody, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {QuoteRequestPeriod} period
         * @param {string} fromSuggestedDate
         * @param {string} [toSuggestedDate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuoteOverviewPerUserPerPeriod: (period, fromSuggestedDate, toSuggestedDate, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'period' is not null or undefined
            (0, common_1.assertParamExists)('getQuoteOverviewPerUserPerPeriod', 'period', period);
            // verify required parameter 'fromSuggestedDate' is not null or undefined
            (0, common_1.assertParamExists)('getQuoteOverviewPerUserPerPeriod', 'fromSuggestedDate', fromSuggestedDate);
            const localVarPath = `/api/quote/get-quote-overview-per-user-per-period`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // http bearer authentication if accessToken is configured
            yield (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration);
            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }
            if (fromSuggestedDate !== undefined) {
                localVarQueryParameter['from_suggested_date'] = (fromSuggestedDate instanceof Date) ?
                    fromSuggestedDate.toISOString() :
                    fromSuggestedDate;
            }
            if (toSuggestedDate !== undefined) {
                localVarQueryParameter['to_suggested_date'] = (toSuggestedDate instanceof Date) ?
                    toSuggestedDate.toISOString() :
                    toSuggestedDate;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {number} quoteId
         * @param {IQuoteDataRating} iQuoteDataRating
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rateQuote: (quoteId, iQuoteDataRating, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'quoteId' is not null or undefined
            (0, common_1.assertParamExists)('rateQuote', 'quoteId', quoteId);
            // verify required parameter 'iQuoteDataRating' is not null or undefined
            (0, common_1.assertParamExists)('rateQuote', 'iQuoteDataRating', iQuoteDataRating);
            const localVarPath = `/api/quote/rate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // http bearer authentication if accessToken is configured
            yield (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration);
            if (quoteId !== undefined) {
                localVarQueryParameter['quote_id'] = quoteId;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(iQuoteDataRating, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {RequestQuoteUpdateBody} requestQuoteUpdateBody
         * @param {number} [planningItemId]
         * @param {number} [clientId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateQuoteRaw: (requestQuoteUpdateBody, planningItemId, clientId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'requestQuoteUpdateBody' is not null or undefined
            (0, common_1.assertParamExists)('updateQuoteRaw', 'requestQuoteUpdateBody', requestQuoteUpdateBody);
            const localVarPath = `/api/quote/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // http bearer authentication if accessToken is configured
            yield (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration);
            if (planningItemId !== undefined) {
                localVarQueryParameter['planning_item_id'] = planningItemId;
            }
            if (clientId !== undefined) {
                localVarQueryParameter['client_id'] = clientId;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(requestQuoteUpdateBody, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {IUpsertQuoteBody} iUpsertQuoteBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertQuote: (iUpsertQuoteBody, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'iUpsertQuoteBody' is not null or undefined
            (0, common_1.assertParamExists)('upsertQuote', 'iUpsertQuoteBody', iUpsertQuoteBody);
            const localVarPath = `/api/quote/upsert-quote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // http bearer authentication if accessToken is configured
            yield (0, common_1.setBearerAuthToObject)(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(iUpsertQuoteBody, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.QuoteApiAxiosParamCreator = QuoteApiAxiosParamCreator;
/**
 * QuoteApi -
 */
var QuoteApi;
(function (QuoteApi) {
    /**
     *
     * @param {number} quoteId
     * @param {*} [options] Override http request option.
     * @param {*} [configuration] Override the defaultConfiguration
     * @throws {RequiredError}
     * @memberof QuoteApi
     * url /api/quote/cancel
     */
    function cancelQuote(quoteId, options, configuration = common_1.defaultConfiguration) {
        return __awaiter(this, void 0, void 0, function* () {
            const localVarAxiosParamCreator = (0, exports.QuoteApiAxiosParamCreator)(configuration);
            const localVarAxiosArgs = yield localVarAxiosParamCreator.cancelQuote(quoteId, options);
            const axiosRequest = (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            return axiosRequest(axios_1.default, (0, common_1.getBasePath)(configuration))
                .then(res => {
                return res;
            });
        });
    }
    QuoteApi.cancelQuote = cancelQuote;
    /**
     *
     * @param {RequestQuoteCreateBody} requestQuoteCreateBody
     * @param {*} [options] Override http request option.
     * @param {*} [configuration] Override the defaultConfiguration
     * @throws {RequiredError}
     * @memberof QuoteApi
     * url /api/quote/create
     */
    function createQuoteRaw(requestQuoteCreateBody, options, configuration = common_1.defaultConfiguration) {
        return __awaiter(this, void 0, void 0, function* () {
            const localVarAxiosParamCreator = (0, exports.QuoteApiAxiosParamCreator)(configuration);
            const localVarAxiosArgs = yield localVarAxiosParamCreator.createQuoteRaw(requestQuoteCreateBody, options);
            const axiosRequest = (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            return axiosRequest(axios_1.default, (0, common_1.getBasePath)(configuration))
                .then(res => {
                res.data = (0, models_2.parseIQuoteEntity)(res.data);
                return res;
            });
        });
    }
    QuoteApi.createQuoteRaw = createQuoteRaw;
    /**
     *
     * @param {*} [options] Override http request option.
     * @param {*} [configuration] Override the defaultConfiguration
     * @throws {RequiredError}
     * @memberof QuoteApi
     * url /api/quote/attachment-list
     */
    function getAttachmentList(options, configuration = common_1.defaultConfiguration) {
        return __awaiter(this, void 0, void 0, function* () {
            const localVarAxiosParamCreator = (0, exports.QuoteApiAxiosParamCreator)(configuration);
            const localVarAxiosArgs = yield localVarAxiosParamCreator.getAttachmentList(options);
            const axiosRequest = (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            return axiosRequest(axios_1.default, (0, common_1.getBasePath)(configuration))
                .then(res => {
                return res;
            });
        });
    }
    QuoteApi.getAttachmentList = getAttachmentList;
    /**
     *
     * @param {number} quoteId
     * @param {*} [options] Override http request option.
     * @param {*} [configuration] Override the defaultConfiguration
     * @throws {RequiredError}
     * @memberof QuoteApi
     * url /api/quote
     */
    function getQuote(quoteId, options, configuration = common_1.defaultConfiguration) {
        return __awaiter(this, void 0, void 0, function* () {
            const localVarAxiosParamCreator = (0, exports.QuoteApiAxiosParamCreator)(configuration);
            const localVarAxiosArgs = yield localVarAxiosParamCreator.getQuote(quoteId, options);
            const axiosRequest = (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            return axiosRequest(axios_1.default, (0, common_1.getBasePath)(configuration))
                .then(res => {
                res.data = (0, models_2.parseIQuoteEntity)(res.data);
                return res;
            });
        });
    }
    QuoteApi.getQuote = getQuote;
    /**
     *
     * @param {RequestQuoteListBody} requestQuoteListBody
     * @param {*} [options] Override http request option.
     * @param {*} [configuration] Override the defaultConfiguration
     * @throws {RequiredError}
     * @memberof QuoteApi
     * url /api/quote/list
     */
    function getQuoteListPost(requestQuoteListBody, options, configuration = common_1.defaultConfiguration) {
        return __awaiter(this, void 0, void 0, function* () {
            const localVarAxiosParamCreator = (0, exports.QuoteApiAxiosParamCreator)(configuration);
            const localVarAxiosArgs = yield localVarAxiosParamCreator.getQuoteListPost(requestQuoteListBody, options);
            const axiosRequest = (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            return axiosRequest(axios_1.default, (0, common_1.getBasePath)(configuration))
                .then(res => {
                res.data = (0, models_1.parseBatchResponseIQuoteEntity)(res.data);
                return res;
            });
        });
    }
    QuoteApi.getQuoteListPost = getQuoteListPost;
    /**
     *
     * @param {QuoteRequestPeriod} period
     * @param {string} fromSuggestedDate
     * @param {string} [toSuggestedDate]
     * @param {*} [options] Override http request option.
     * @param {*} [configuration] Override the defaultConfiguration
     * @throws {RequiredError}
     * @memberof QuoteApi
     * url /api/quote/get-quote-overview-per-user-per-period
     */
    function getQuoteOverviewPerUserPerPeriod(period, fromSuggestedDate, toSuggestedDate, options, configuration = common_1.defaultConfiguration) {
        return __awaiter(this, void 0, void 0, function* () {
            const localVarAxiosParamCreator = (0, exports.QuoteApiAxiosParamCreator)(configuration);
            const localVarAxiosArgs = yield localVarAxiosParamCreator.getQuoteOverviewPerUserPerPeriod(period, fromSuggestedDate, toSuggestedDate, options);
            const axiosRequest = (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            return axiosRequest(axios_1.default, (0, common_1.getBasePath)(configuration))
                .then(res => {
                res.data = res.data.map((x) => (0, models_3.parseIQuoteOverviewPerUserPerPeriod)(x));
                return res;
            });
        });
    }
    QuoteApi.getQuoteOverviewPerUserPerPeriod = getQuoteOverviewPerUserPerPeriod;
    /**
     *
     * @param {number} quoteId
     * @param {IQuoteDataRating} iQuoteDataRating
     * @param {*} [options] Override http request option.
     * @param {*} [configuration] Override the defaultConfiguration
     * @throws {RequiredError}
     * @memberof QuoteApi
     * url /api/quote/rate
     */
    function rateQuote(quoteId, iQuoteDataRating, options, configuration = common_1.defaultConfiguration) {
        return __awaiter(this, void 0, void 0, function* () {
            const localVarAxiosParamCreator = (0, exports.QuoteApiAxiosParamCreator)(configuration);
            const localVarAxiosArgs = yield localVarAxiosParamCreator.rateQuote(quoteId, iQuoteDataRating, options);
            const axiosRequest = (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            return axiosRequest(axios_1.default, (0, common_1.getBasePath)(configuration))
                .then(res => {
                res.data = (0, models_2.parseIQuoteEntity)(res.data);
                return res;
            });
        });
    }
    QuoteApi.rateQuote = rateQuote;
    /**
     *
     * @param {RequestQuoteUpdateBody} requestQuoteUpdateBody
     * @param {number} [planningItemId]
     * @param {number} [clientId]
     * @param {*} [options] Override http request option.
     * @param {*} [configuration] Override the defaultConfiguration
     * @throws {RequiredError}
     * @memberof QuoteApi
     * url /api/quote/update
     */
    function updateQuoteRaw(requestQuoteUpdateBody, planningItemId, clientId, options, configuration = common_1.defaultConfiguration) {
        return __awaiter(this, void 0, void 0, function* () {
            const localVarAxiosParamCreator = (0, exports.QuoteApiAxiosParamCreator)(configuration);
            const localVarAxiosArgs = yield localVarAxiosParamCreator.updateQuoteRaw(requestQuoteUpdateBody, planningItemId, clientId, options);
            const axiosRequest = (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            return axiosRequest(axios_1.default, (0, common_1.getBasePath)(configuration))
                .then(res => {
                res.data = (0, models_2.parseIQuoteEntity)(res.data);
                return res;
            });
        });
    }
    QuoteApi.updateQuoteRaw = updateQuoteRaw;
    /**
     *
     * @param {IUpsertQuoteBody} iUpsertQuoteBody
     * @param {*} [options] Override http request option.
     * @param {*} [configuration] Override the defaultConfiguration
     * @throws {RequiredError}
     * @memberof QuoteApi
     * url /api/quote/upsert-quote
     */
    function upsertQuote(iUpsertQuoteBody, options, configuration = common_1.defaultConfiguration) {
        return __awaiter(this, void 0, void 0, function* () {
            const localVarAxiosParamCreator = (0, exports.QuoteApiAxiosParamCreator)(configuration);
            const localVarAxiosArgs = yield localVarAxiosParamCreator.upsertQuote(iUpsertQuoteBody, options);
            const axiosRequest = (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            return axiosRequest(axios_1.default, (0, common_1.getBasePath)(configuration))
                .then(res => {
                res.data = (0, models_2.parseIQuoteEntity)(res.data);
                return res;
            });
        });
    }
    QuoteApi.upsertQuote = upsertQuote;
})(QuoteApi = exports.QuoteApi || (exports.QuoteApi = {}));
