import { Injectable } from '@angular/core';
import { IPortalUserEntity } from './entity/user.entity';
import { CalendarEventEngine, FrontendCalendarEvent } from '../engine/calendar-event.engine';
import * as moment from 'moment';
import * as toastr from 'toastr';
import { Office365Service } from '../services/office365.service';
import { PhrosprPortalApi } from '../../../../resource/phrospr-api';

@Injectable({
    providedIn: 'root',
})
export class UserManager {
    constructor(private office365Service: Office365Service) {}
    public addNewUsersFrom365() {
        return PhrosprPortalApi.UserApi.addNewUsersFrom365();
    }

    public async loadEvents3Weeks(users: IPortalUserEntity[], date: Date): Promise<FrontendCalendarEvent[]> {
        const startOfWeek = moment(date).startOf('week');
        const startOfWeekDate = startOfWeek.format('YYYY-MM-DD');
        const previousWeek = moment(startOfWeek).subtract(1, 'weeks').startOf('week');
        const previousWeekDate = previousWeek.format('YYYY-MM-DD');
        const nextWeek = moment(startOfWeek).add(1, 'weeks').startOf('week');
        const nextWeekDate = nextWeek.format('YYYY-MM-DD');
        const endOfNextWeek = nextWeek.endOf('week');
        return (
            await Promise.all(
                users.map(async user => {
                    if (!user.weekEvents) user.weekEvents = {};
                    if (user.weekEvents[startOfWeekDate]) return user.weekEvents[startOfWeekDate];

                    // get startdate
                    let startDate = previousWeek.toDate();
                    if (user.weekEvents[previousWeekDate]) startDate = startOfWeek.toDate();
                    // get enddate
                    let endDate = endOfNextWeek.toDate();
                    if (user.weekEvents[nextWeekDate]) endDate = moment(startOfWeek).endOf('week').toDate();
                    try {
                        const userEvents = (
                            await this.office365Service.getEventsPlanningCalendarForUser(startDate, endDate, user.email)
                        ).map(event => CalendarEventEngine.parseCalendarEventLite(event, user));
                        user.weekEvents = {
                            ...user.weekEvents,
                            ...CalendarEventEngine.splitEventsInWeeks(userEvents),
                        };
                        if (!user.weekEvents[startOfWeekDate]) user.weekEvents[startOfWeekDate] = [];
                        if (!user.weekEvents[previousWeekDate]) user.weekEvents[previousWeekDate] = [];
                        if (!user.weekEvents[nextWeekDate]) user.weekEvents[nextWeekDate] = [];
                        return user.weekEvents[startOfWeekDate];
                    } catch (error) {
                        toastr.error(`failed to load ${user.email} please try again`);
                        return [];
                    }
                }),
            )
        ).reduce((value, total) => total.concat(value), []);
    }
}
