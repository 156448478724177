import { IQuoteEntity, QuoteStatus } from '@phrospr/api-backend';

export function newQuote(): IQuoteEntity {
    return {
        creation_date: undefined,
        quote_id: undefined,
        quote_lines: undefined,
        client_id: undefined,
        final_price_to_pay: 0,
        lead_id: undefined,
        placement_cost: 0,
        status: QuoteStatus.created,
        sub_total_price: 0,
        pdf_raw: '',
        client_form: {
            client_id: null,
            lead_id: null,
            date: null,
            first_name: null,
            last_name: null,
            address: null,
            city: null,
            email: null,
            bankAccount: null,
            phone_number: null,
            remarks: null,
            zip_code: null,
            mobile_phone: null,
        },
        data: {
            created_by_user_id: null,
            sold_by_user_id: null,
            technicalFiche: {
                keer_klep: null,
                stopcontact: null,
                leidingen: null,
                afvoer: null,
                analyse_f: null,
            },
            discount_percentage: 0,
        },
    };
}

export function getQuoteVersion(quote: IQuoteEntity): number {
    if (!quote) return null;
    if (quote.data && quote.data.quote_create) return 1;
    return 2;
}
