import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateFormatWithDayPipe } from './pipes/date-format-with-day.pipe';
import { DateFormatOnlyDatePipe } from './pipes/date-format-only-date.pipe';
import { ClarityModule } from '@clr/angular';
import { ModalDeleteComponent } from './components/modalDelete/modalDelete.component';
import { ClientService } from './services/client.service';
import { CalendarItemService } from './services/calendar-item.service';
import { ClientManager } from './manager/client.manager';
import { AuthorizationGuard } from './authorization.guard';
import { RoutingState } from './services/route-state.service';
import { Office365Service } from './services/office365.service';
import { ExactService } from './services/exact.service';
import { ExactManager } from './manager/exact.manager';
import { TimesPipe } from './pipes/times.pipe';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CustomDateFormatter } from './engine/calendar-view/custom-date-formatter.provider';
import { SendMailCardComponent } from './components/send-mail-card/send-mail-card.component';
import { LeadManager } from './manager/lead.manager';
import { LeadService } from './services/lead.service';
import { ActivitySectionComponent } from './components/activity-section/activity-section.component';
import { DateFormatWithTimePipe } from './pipes/date-format-with-time.pipe';
import { CommentSectionSmallComponent } from './components/comment-section-small/comment-section-small.component';
import { ClientSearchModalComponent } from './components/client-search-modal/client-search-modal.component';
import { PlanningItemService } from './services/planning-item.service';
import { PlanningItemListFilterComponent } from './components/planning-item-list-filter/planning-item-list-filter.component';
import { GetNameOfPlanningItemType } from './pipes/planning-item-type-name.pipe';
import { EnumToArrayPipe } from './pipes/emun-to-array.pipe';
import { GetNameOfLeadType } from './pipes/lead-type-name.pipe';
import { PlanningItemSearchModalComponent } from './components/planning-item-search-modal/planning-item-search-modal.component';
import { PlanningItemTypeImageComponent } from './components/planning-item-type-image/planning-item-type-image.component';
import { QuoteService } from './services/quote.service';
import { CreatePlacementComponent } from './components/create-placement/create-placement.component';
import { QuoteFilterComponent } from './components/quote-filter/quote-filter.component';
import { QuoteApproveModalComponent } from './components/quote-approve-modal/quote-approve-modal.component';
import { DateFormatOnlyMonthNamePipe } from './pipes/date-format-only-month-name.pipe';
import { DateFormatGetWeekNumberPipe } from './pipes/date-format-get-week-number.pipe';
import { GetUserFullName } from './pipes/user-name.pipe';
import { ParseActivityType } from './pipes/activity-type.pipe';
import { ActivityFilterComponent } from './components/activity-filter/activity-filter.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { ProductFilterComponent } from './components/product-filter/product-filter.component';
import { ClientActivitySectionComponent } from './components/client-activity-section/client-activity-section.component';
import { AuthImagePipe } from './pipes/auth-image.pipe';
import { ModalPictureComponent } from './components/modal-picture/modal-picture.component';
import { UpsertProductModalComponent } from './components/upsert-product-modal/upsert-product-modal.component';
import { ProductSearchModalComponent } from './components/product-search-modal/product-search-modal.component';
import { CommentAddComponent } from './components/comment-add/comment-add';

const pipes = [
    DateFormatWithDayPipe,
    DateFormatOnlyDatePipe,
    TimesPipe,
    CustomDateFormatter,
    DateFormatWithTimePipe,
    GetNameOfPlanningItemType,
    EnumToArrayPipe,
    GetNameOfLeadType,
    DateFormatOnlyMonthNamePipe,
    DateFormatGetWeekNumberPipe,
    GetUserFullName,
    ParseActivityType,
    AuthImagePipe,
];

const components = [
    ModalDeleteComponent,
    SendMailCardComponent,
    ActivitySectionComponent,
    ClientSearchModalComponent,
    CommentSectionSmallComponent,
    PlanningItemListFilterComponent,
    PlanningItemSearchModalComponent,
    PlanningItemTypeImageComponent,
    CreatePlacementComponent,
    QuoteFilterComponent,
    QuoteApproveModalComponent,
    ActivityFilterComponent,
    ProductFilterComponent,
    ClientActivitySectionComponent,
    ModalPictureComponent,
    UpsertProductModalComponent,
    ProductSearchModalComponent,
    CommentAddComponent,
];

const directives = [];

const imports = [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ClarityModule,
    CalendarModule.forRoot({
        provide: DateAdapter,
        useFactory: adapterFactory,
    }),
];

const services = [
    ClientManager,
    ClientService,
    CalendarItemService,
    RoutingState,
    Office365Service,
    ExactService,
    ExactManager,
    LeadManager,
    LeadService,
    PlanningItemService,
    QuoteService,
];

const guards = [AuthorizationGuard];

@NgModule({
    imports: [...imports, MatFormFieldModule, MatSelectModule],
    exports: [...components, ...directives, ...pipes],
    declarations: [...components, ...directives, ...pipes],
    providers: [...services, ...guards],
    entryComponents: [],
})
export class SharedModule {}
