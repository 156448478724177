import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
    IPlanningItemEntity,
    IPlanningItemSearchEntity,
    IPlanningItemSearchFilterEntity,
    IPlanningItemSearchShowEntity,
    IQuoteEntity,
    PlanningItemType,
    PlanningState,
    RequestSort,
} from '@phrospr/api-backend';
import { Language } from '@phrospr/lib-models';
import { PlanningItemService } from '../../../shared/services/planning-item.service';
import { ReplaySubject } from 'rxjs';
import * as toastr from 'toastr';
import { QuoteService } from '../../../shared/services/quote.service';
import { IPlanningItemFinishPortal } from '@phrospr/lib-core/src/models/planning-item-finish-portal';
import { PhrosprPortalApi } from '../../../../../resource/phrospr-api';

@Component({
    selector: 'pr-planning-item-list-slim-container',
    styleUrls: ['./planning-item-list-slim.container.scss'],
    templateUrl: './planning-item-list-slim.container.html',
})
export class PlanningItemListSlimContainer implements OnInit {
    PlanningItemType = PlanningItemType;
    PlanningState = PlanningState;
    Language = Language;

    @Input() planningItemShow: IPlanningItemSearchShowEntity;
    @Input() planningItemFilter: IPlanningItemSearchFilterEntity;
    @Input() planningItemFilterUpdate$: ReplaySubject<void>;

    planningItemSearch: IPlanningItemSearchEntity;

    planningItems: IPlanningItemEntity[];

    loading = false;

    selectedPlanningItem: IPlanningItemEntity;
    finishModal = false;

    approveQuoteModal = false;
    planningItemToApproveQuote: IPlanningItemEntity;
    quoteToApprove: IQuoteEntity;

    reorderable: true;

    page: {
        limit: number;
        count: number;
        offset: number;
        sort?: { order: RequestSort; column: string };
    } = {
        limit: 25,
        count: 0,
        offset: 0,
        sort: { order: RequestSort.ASC, column: 'number' },
    };

    constructor(
        private router: Router,
        private planningItemService: PlanningItemService,
        private quoteService: QuoteService,
    ) {}

    ngOnInit(): void {
        if (this.planningItemFilter.state === PlanningState.planned) {
            this.page.sort = { order: RequestSort.DESC, column: 'assignedCalendarItemDate' };
        } else if (
            this.planningItemFilter.state === PlanningState.completed ||
            this.planningItemFilter.state === PlanningState.completedNoOffer ||
            this.planningItemFilter.state === PlanningState.completedWithOffer
        ) {
            this.page.sort = { order: RequestSort.DESC, column: 'lastCompletedCalendarItemDate' };
        } else if (this.planningItemFilter.state === PlanningState.inactive) {
            this.page.sort = { order: RequestSort.DESC, column: 'maintenanceInactiveDate' };
        } else if (this.planningItemFilter.state === PlanningState.protest) {
            this.page.sort = { order: RequestSort.DESC, column: 'leadCreationDate' };
        }

        this.finishModal = false;
        this.pageCallback(this.page);
        this.planningItemFilterUpdate$.subscribe(filter => {
            this.pageCallback(this.page);
        });
    }

    pageCallback(pageInfo: { count?: number; pageSize?: number; limit?: number; offset?: number }) {
        this.planningItemSearch = {
            filter: this.planningItemFilter,
            show: this.planningItemShow,
            limit: pageInfo.limit,
            offset: pageInfo.offset * pageInfo.pageSize,
            sort: {
                order: this.planningItemSearch ? this.planningItemSearch.sort.order : this.page.sort.order,
                column: this.planningItemSearch ? this.planningItemSearch.sort.column : this.page.sort.column,
            },
        };
        this.loadPlanningItemList();
    }

    async loadPlanningItemList() {
        this.planningItems = [];
        this.loading = true;
        try {
            const { data } = await PhrosprPortalApi.PlanningItemApi.listPlanningItems({
                planning_item_filter: this.planningItemSearch,
            });
            this.planningItems = data.records;
            this.page.count = data.total;
        } catch (err) {
            console.log(err);
        } finally {
            this.loading = false;
        }
    }

    sortCallback(sortInfo: {
        sorts: { dir: 'ASC' | 'DESC'; prop: string };
        column: {};
        prevValue: string;
        newValue: string;
    }) {
        // there will always be one "sort" object if "sortType" is set to "single"
        // FYI it's always the column prop that is filled in the sorting
        this.planningItemSearch.sort.order = sortInfo.sorts[0].dir.toUpperCase();
        this.planningItemSearch.sort.column = sortInfo.sorts[0].prop;
        this.loadPlanningItemList();
    }

    onActivate(event) {
        if (event.type === 'click') {
            this.goToPlanningItemDetail(event.row.planning_item_id);
        }
    }

    showFinishPlanningItem(planningItem: IPlanningItemEntity) {
        this.finishModal = true;
        this.selectedPlanningItem = planningItem;
    }

    async showFinishQuote(planningItem: IPlanningItemEntity) {
        const latestQuote = await this.getLatestQuote(planningItem);
        // If this is empty no problem => correct logic is triggered in the approve component
        this.quoteToApprove = latestQuote;
        this.planningItemToApproveQuote = planningItem;
        this.approveQuoteModal = true;
    }

    async finishPlannedPlanningItem(newPlanningItemReport: IPlanningItemFinishPortal) {
        try {
            await PhrosprPortalApi.PlanningItemApi.finishPlanningItem({
                calendarItemID: this.selectedPlanningItem.assigned_calendar_item.calendar_item_id,
                comment: newPlanningItemReport.comment,
                paymentMethod: newPlanningItemReport.payment_method,
                action: newPlanningItemReport.state,
                deleteCalendarUponReschedule: true,
            });
            toastr.info(
                `${this.selectedPlanningItem.planning_item_type}-${this.selectedPlanningItem.planning_item_type_number} is afgewerkt!`,
            );
            this.finishModal = false;
        } catch (err) {
            toastr.error(
                `Kon ${this.selectedPlanningItem.planning_item_type}-${this.selectedPlanningItem.planning_item_type_number} niet afwerken`,
            );
            console.error(err);
        }
        await this.loadPlanningItemList();
        this.finishModal = false;
        this.selectedPlanningItem = null;
    }

    cancelFinish() {
        this.planningItemToApproveQuote = null;
        this.quoteToApprove = null;
        this.finishModal = false;
    }

    async getLatestQuote(planningItem: IPlanningItemEntity): Promise<IQuoteEntity> {
        const quotes = (
            await PhrosprPortalApi.QuoteApi.getQuoteListPost({
                quote_filter: {
                    filter: { planning_item_id: planningItem.planning_item_id },
                    show: { client: true },
                    sort: { column: 'quote_id', order: RequestSort.DESC },
                    limit: 1,
                },
            })
        ).data;
        if (!quotes.records.length) {
            return null;
        }
        return quotes.records[quotes.records.length - 1];
    }

    async downloadQuote(planningItem: IPlanningItemEntity) {
        const latestQuote = await this.getLatestQuote(planningItem);
        if (latestQuote === null) return toastr.error('Geen PDF beschikbaar');

        try {
            await this.quoteService.downloadQuotePdf(latestQuote.quote_id);
        } catch (err) {
            console.error(err);
            toastr.error('Fout tijdens het ophalen van de pdf');
        }
    }

    async ApproveQuoteModal(event: { planningItemToAdd: IPlanningItemEntity; planningItem: IPlanningItemEntity }) {
        await PhrosprPortalApi.PlanningItemApi.approvePlanningItemLeadQuote({
            planning_item_id: this.planningItemToApproveQuote.planning_item_id,
            planning_item_placement_to_create: event.planningItemToAdd,
            quote_id: null,
        });
        await this.loadPlanningItemList();
        this.quoteToApprove = null;
        this.planningItemToApproveQuote = null;
        this.approveQuoteModal = false;
    }

    cancelApproveQuote() {
        this.quoteToApprove = null;
        this.planningItemToApproveQuote = null;
        this.approveQuoteModal = false;
    }

    getRowClass(row: IPlanningItemEntity) {
        return {
            'row-style-datatable': row.planning_item_id !== null,
        };
    }

    async goToPlanningItemDetail(planning_item_id: number) {
        await this.router.navigateByUrl('/planning-item-detail?planning_item_id=' + planning_item_id);
    }
}
