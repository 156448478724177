import { Component, OnInit } from '@angular/core';
import { QuoteService } from '../../../shared/services/quote.service';
import * as moment from 'moment';
import { IQuoteOverviewPerUserPerPeriod, QuoteRequestPeriod, QuoteStatus, RequestSort } from '@phrospr/api-backend';
import { PhrosprPortalApi } from '../../../../../resource/phrospr-api';
import { getEnumKeys } from '@phrospr/lib-core/src/engine/enum.engine';

@Component({
    selector: 'pr-quote-stats-overview-page',
    styleUrls: ['./quote-stats-overview-page.component.scss'],
    templateUrl: './quote-stats-overview-page.component.html',
})
export class QuoteStatsOverviewPageComponent implements OnInit {
    QuoteStatus = QuoteStatus;
    QuoteRequestPeriod = QuoteRequestPeriod;

    quoteOverViewPerUserPerPeriod: IQuoteOverviewPerUserPerPeriod[] = [];

    fromDate: Date = moment().startOf('month').toDate();
    toDate: Date = moment().endOf('month').toDate();

    page: {
        limit: number;
        count: number;
        offset: number;
        sort?: { order: RequestSort; column: string };
    } = {
        limit: 50,
        count: 0,
        offset: 0,
        sort: { order: RequestSort.DESC, column: 'creation_date' },
    };

    periodOptions = getEnumKeys(QuoteRequestPeriod);
    periodSelected: QuoteRequestPeriod = QuoteRequestPeriod.month;

    constructor(private quoteService: QuoteService) {}

    ngOnInit(): void {
        this.getOverview().catch(err => console.log(err));
    }

    async next(period: QuoteRequestPeriod) {
        this.fromDate = moment(this.fromDate).add(1, period).startOf(period).toDate();
        this.toDate = moment(this.toDate).add(1, period).endOf(period).toDate();
        await this.getOverview();
    }

    async previous(period: QuoteRequestPeriod) {
        this.fromDate = moment(this.fromDate).subtract(1, period).startOf(period).toDate();
        this.toDate = moment(this.toDate).subtract(1, period).endOf(period).toDate();
        await this.getOverview();
    }

    async setSelectedPeriod(period: QuoteRequestPeriod) {
        this.periodSelected = period;
        this.fromDate = moment(this.fromDate).startOf(period).toDate();
        this.toDate = moment(this.fromDate).endOf(period).toDate();
        await this.getOverview();
    }

    async currentPeriod(period: QuoteRequestPeriod) {
        this.fromDate = moment().startOf(period).toDate();
        this.toDate = moment().endOf(period).toDate();
        await this.getOverview();
    }

    sortCallback(sortInfo: {
        sorts: { dir: RequestSort; prop: string };
        column: { name: string };
        prevValue: string;
        newValue: string;
    }) {
        // there will always be one "sort" object if "sortType" is set to "single"
        // FYI it's always the column prop that is filled in the sorting
        this.page.sort.order = sortInfo.sorts[0].dir.toUpperCase();
        this.page.sort.column = sortInfo.column.name;
        this.getOverview();
    }

    async getOverview() {
        try {
            // Still to implement sorting
            this.quoteOverViewPerUserPerPeriod = (
                await PhrosprPortalApi.QuoteApi.getQuoteOverviewPerUserPerPeriod(
                    this.periodSelected,
                    this.fromDate.toISOString(),
                    this.toDate.toISOString(),
                )
            ).data;
            this.page.count = this.quoteOverViewPerUserPerPeriod.length;
        } catch (err) {
            console.log(err);
        }
    }

    getTotalCountOfQuotes(count_of_quotes_created: string, count_of_quotes_approved: string): number {
        const totalCountOfQuotes: number =
            (count_of_quotes_created ? parseInt(count_of_quotes_created, 10) : 0) +
            (count_of_quotes_approved ? parseInt(count_of_quotes_approved, 10) : 0);
        return totalCountOfQuotes;
    }

    onActivate(event) {
        if (event.type === 'click') {
            console.log(event);
        }
    }

    getRowClass(row: IQuoteOverviewPerUserPerPeriod) {
        return {
            'row-style-datatable': row.sold_by_user_id !== null,
        };
    }
}
