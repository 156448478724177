import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserManager } from '../../../shared/manager/user.manager';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
    CalendarDateFormatter,
    CalendarEvent,
    CalendarEventAction,
    CalendarEventTitleFormatter,
    CalendarView,
} from 'angular-calendar';
import { IMyCalendarEvent } from '../../../plan-calendar-item/containers/plan-calendar-item-page/plan-calendar-item-page.component';
import { CustomDateFormatter } from '../../../shared/engine/calendar-view/custom-date-formatter.provider';
import { CustomEventTitleFormatter } from '../../../shared/engine/calendar-view/custom-event-title-formatter.provider';
import { PhrosprPortalApi } from '../../../../../resource/phrospr-api';
import { IPortalUserEntity } from '../../../shared/manager/entity/user.entity';
import { ICalendarItemEntity } from '@phrospr/api-backend';
import { newCalendarItemEntity } from '@phrospr/lib-core/src/engine/calendar-item.engine';
import { FrontendCalendarEvent } from '../../../shared/engine/calendar-event.engine';

@Component({
    selector: 'pr-calendar-view-page',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./calendar-view-page.component.scss', '../../../../app.component.css'],
    templateUrl: './calendar-view-page.component.html',
    providers: [
        {
            provide: CalendarEventTitleFormatter,
            useClass: CustomEventTitleFormatter,
        },
        {
            provide: CalendarDateFormatter,
            useClass: CustomDateFormatter,
        },
    ],
})
export class CalendarViewPageComponent implements OnInit {
    calendarItem: ICalendarItemEntity = newCalendarItemEntity();

    userListDb$: Promise<IPortalUserEntity[]>;

    @ViewChild('modalContent') modalContent: TemplateRef<any>;

    view: CalendarView = CalendarView.Week;
    CalendarView = CalendarView;
    viewDate: Date = new Date();
    locale = 'fr';

    modalData: {
        action: string;
        event: IMyCalendarEvent;
    };

    openCreateModal = false;
    loading = false;

    // Todo when this is needed
    actions: CalendarEventAction[] = [
        {
            label: '<div></div>',
            onClick: ({ event }: { event: CalendarEvent }): void => {
                this.handleEvent('Edited', event);
            },
        },
        {
            label: '<clr-icon shape="trash"></clr-icon>',
            onClick: ({ event }: { event: CalendarEvent }): void => {
                this.events = this.events.filter(iEvent => iEvent !== event);
            },
        },
    ];

    refresh: Subject<any> = new Subject();

    events: FrontendCalendarEvent[] = [];

    activeDayIsOpen = true;

    constructor(
        private router: Router,
        private userManager: UserManager,
        private modal: NgbModal,
        private route: ActivatedRoute,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        this.userListDb$ = this.getUsers();
    }

    async getUsers() {
        try {
            return (await PhrosprPortalApi.UserApi.listUsers()).data.filter(
                user => !user.data.ignoreUserInCalendarList,
            );
        } catch (error) {
            console.log(error);
        }
    }

    handleEvent(action: string, event: IMyCalendarEvent): void {
        this.modalData = { event, action };
        this.openCreateModal = true;
    }

    setView(view: CalendarView) {
        this.view = view;
    }

    closeOpenMonthViewDay() {
        this.activeDayIsOpen = false;
        this.next();
    }

    close() {
        this.openCreateModal = false;
    }

    async setToday() {
        this.viewDate = new Date();
        await this.refreshCalendar();
    }

    async loadUserCalendarData(user: IPortalUserEntity, visualize: boolean) {
        user.visualize = visualize;
        await this.refreshCalendar();
    }

    async next() {
        await this.refreshCalendar();
    }

    private async refreshCalendar() {
        console.log('refreshing', this.viewDate);
        const users = (await this.userListDb$).filter(u => u.visualize);
        this.loading = true;
        this.events = await this.userManager.loadEvents3Weeks(users, this.viewDate);
        this.loading = false;
        this.cdr.detectChanges();
    }
}
