import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientManager } from '../../../shared/manager/client.manager';
import { Location } from '@angular/common';
import * as toastr from 'toastr';
import { PhrosprPortalApi } from '../../../../../resource/phrospr-api';
import { IClientEntity, ClientState, IPlanningItemEntity, PlanningState } from '@phrospr/api-backend';
import { IClientExactEntity } from '@phrospr/lib-models';
import { transformClientInExactClient } from '@phrospr/lib-core/src/engine/exact.engine';
import { newDeliveryAddress } from '@phrospr/lib-core/src/engine/client.engine';

@Component({
    selector: 'pr-client-detail-page',
    styleUrls: ['./client-detail-page.component.scss'],
    templateUrl: './client-detail-page.component.html',
})
export class ClientDetailPageComponent implements OnInit {
    client_id: number = null;
    clientExact: IClientExactEntity = null;
    ClientState = ClientState;
    PlanningState = PlanningState;

    clientDB: IClientEntity = null;

    sendMailForUpcomingMaintenance: boolean;
    sendMailForPlannedNotification: boolean;
    sendMailNotifyPlanningItemForHisAppointment: boolean;
    sendFeedbackMail: boolean;

    historyOfClient: IPlanningItemEntity[];

    editMode = false;
    createMode = false;
    loadingSave = false;

    constructor(
        private clientManager: ClientManager,
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
    ) {}

    ngOnInit(): void {
        this.getClientDb();
    }

    async getClientDb() {
        this.route.queryParams.subscribe(async params => {
            this.client_id = params['client_id'];
            if (this.client_id) {
                try {
                    this.getHistoryClient(this.client_id);
                    this.clientDB = (await PhrosprPortalApi.ClientApi.getClient(this.client_id)).data;
                    this.setMailSettings();
                } catch (e) {
                    toastr.error('Could not load client from Database');
                }
            } else {
                this.createMode = true;
                this.editMode = true;
                this.clientDB = {
                    client_id: null,
                    exact_client_number: null,
                    exact_last_sync: null,
                    state: ClientState.client,
                    inactive: false,
                    client_data: {
                        first_name: null,
                        last_name: null,
                        email: null,
                        main_address: {
                            address: null,
                            city: null,
                            zip_code: null,
                            deleted: false,
                        },
                        company_data: {},
                    },
                    client_settings: {
                        mailSettings: {
                            disableSendMailForPlannedNotification: false,
                            disableSendMailForUpcomingMaintenance: false,
                            disableSendMailNotifyPlanningItemForHisAppointment: false,
                        },
                        maintenanceSettings: {
                            active: false,
                            inactive_date: null,
                        },
                    },
                };
                this.setMailSettings();
            }
        });
    }

    async getHistoryClient(client_id: number) {
        this.historyOfClient = (await PhrosprPortalApi.PlanningItemApi.getPlanningItemClientHistory(client_id)).data;
    }

    backToOverview() {
        this.location.back();
    }

    setMailSettings() {
        if (this.clientDB) {
            const {
                disableSendMailForUpcomingMaintenance,
                disableSendMailForPlannedNotification,
                disableSendMailNotifyPlanningItemForHisAppointment,
                disableFeedbackMail,
            } = this.clientDB.client_settings.mailSettings;
            this.sendMailForUpcomingMaintenance = !disableSendMailForUpcomingMaintenance;
            this.sendMailForPlannedNotification = !disableSendMailForPlannedNotification;
            this.sendMailNotifyPlanningItemForHisAppointment = !disableSendMailNotifyPlanningItemForHisAppointment;
            this.sendFeedbackMail = !disableFeedbackMail;
        }
    }

    async updateClient() {
        this.loadingSave = true;
        try {
            this.clientExact = transformClientInExactClient(this.clientDB);
            const { mailSettings } = this.clientDB.client_settings;
            mailSettings.disableSendMailForUpcomingMaintenance = !this.sendMailForUpcomingMaintenance;
            mailSettings.disableSendMailForPlannedNotification = !this.sendMailForPlannedNotification;
            mailSettings.disableSendMailNotifyPlanningItemForHisAppointment =
                !this.sendMailNotifyPlanningItemForHisAppointment;
            mailSettings.disableFeedbackMail = !this.sendFeedbackMail;

            const clientToUpdate: IClientEntity = {
                client_id: this.clientDB.client_id,
                client_data: this.clientDB.client_data,
                client_settings: this.clientDB.client_settings,
                state: this.clientDB.state,
                exact_client_number: this.clientDB.exact_client_number,
                exact_last_sync: this.clientDB.exact_last_sync,
                inactive: this.clientDB.inactive,
                lead_data: this.clientDB.lead_data,
            };

            await PhrosprPortalApi.ClientApi.updateClient(clientToUpdate);
            if (this.clientDB.client_data.exact_client_id) {
                try {
                    await this.clientManager.updateClientInfoInExact(this.clientExact).toPromise();
                } catch (e) {
                    this.loadingSave = false;
                    toastr.error('Er ging iets mis met het opslaan van de klant in exact. ' + e);
                    return;
                }
            }
            this.editMode = false;
            this.loadingSave = false;
            toastr.success('Klant wijzigingen opgeslagen');
        } catch (e) {
            this.loadingSave = false;
            toastr.error('Er ging iets mis met het opslaan van de klant in de database. ' + e);
        }
    }

    async addClient() {
        if (
            !this.clientDB.client_data.first_name ||
            this.clientDB.client_data.first_name === '' ||
            !this.clientDB.client_data.email ||
            this.clientDB.client_data.email === '' ||
            !this.clientDB.client_data.main_address.address ||
            this.clientDB.client_data.main_address.address === ''
        ) {
            toastr.error(
                'Je moet op zijn minst een Naam, email & adres ingeven voor het aanmaken van een nieuwe klant',
            );
            return;
        }
        try {
            await PhrosprPortalApi.ClientApi.addClient(this.clientDB);
            this.router.navigateByUrl('/client');
            toastr.success('Klant correct aangemaakt');
        } catch (e) {
            toastr.error('Er ging iets mis met het aanmaken van de klant.' + e);
        }
    }

    async cancelClientEdit() {
        await this.getClientDb();
        this.editMode = false;
    }

    createOffer() {
        toastr.error('Nog niet beschikbaar - contacteer Hendrik of Teo');
    }

    async addDeliveryAddress() {
        if (this.clientDB.client_data.delivery_addresses) {
            this.clientDB.client_data.delivery_addresses.push(newDeliveryAddress());
        } else {
            this.clientDB.client_data.delivery_addresses = [newDeliveryAddress()];
        }
    }

    async deleteDeliveryAddress(index: number) {
        // We never delete a delivery address => For the purpose of historical data
        this.clientDB.client_data.delivery_addresses[index].deleted = true;
    }

    async activateDeliveryAddress(index: number) {
        this.clientDB.client_data.delivery_addresses[index].deleted = false;
    }

    async goToPlanningItem(planningItemID: number) {
        await this.router.navigateByUrl('/planning-item-detail?planning_item_id=' + planningItemID);
    }
}
