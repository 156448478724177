import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { catchError, switchMap } from 'rxjs/operators';
import * as toastr from 'toastr';
import { getAccessToken } from '../resources/oidc';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
    constructor() {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.method === 'OPTIONS') {
            return next.handle(req);
        }
        return fromPromise(getAccessToken()).pipe(
            switchMap(token => {
                if (token) {
                    // Logged in. Add Bearer token.
                    return next.handle(
                        req.clone({
                            headers: req.headers.append('Authorization', 'Bearer ' + token),
                        }),
                    );
                }
                return next.handle(req);
            }),
            catchError((err, caught) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 401) {
                        return throwError('invalid token, unauthorized');
                    }
                    if (err.status === 405) {
                        toastr.error('Onvoldoende rechten!');
                    }
                }
                return throwError(err);
            }),
        );
    }
}
