import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IClientEntity, IPlanningItemEntity } from '@phrospr/api-backend';
import { newPlanningItem } from '@phrospr/lib-core/src/engine/planning-item.engine';
import { newClientEntity } from '@phrospr/lib-core/src/engine/client.engine';

@Component({
    selector: 'pr-placement-create',
    templateUrl: './create-placement.component.html',
    styleUrls: ['./create-placement.component.scss'],
})
export class CreatePlacementComponent implements OnInit {
    @Input() clientSelectionVisible: boolean;

    @Output() planningItem = new EventEmitter<IPlanningItemEntity>();

    newPlanningItemPlacement: IPlanningItemEntity = newPlanningItem();

    selectedClient: IClientEntity = newClientEntity();

    constructor() {}

    ngOnInit(): void {}

    setClient(client: IClientEntity) {
        /* this.newDelivery.client_number = client.number;*/
        this.selectedClient = client;
        this.newPlanningItemPlacement.client_id = client.client_id;
        this.sendPlacementToComponent();
    }

    sendPlacementToComponent() {
        this.planningItem.emit(this.newPlanningItemPlacement);
    }
}
