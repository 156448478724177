import * as moment from 'moment';
import { Language, IEmailObjectEntity } from '@phrospr/lib-models';
import { IPlanningItemEntity, PlanningItemType } from '@phrospr/api-backend';
import { getNamePlanningItemType, PlanningItemEngine } from './planning-item.engine';

export function getMailTemplatePlanningItem(
    planningItem: IPlanningItemEntity,
    calendarEventToAddStartDate: Date,
    language: Language,
): IEmailObjectEntity {
    return getMailTemplateAppointmentPlanned(planningItem, calendarEventToAddStartDate, language);
}

export function getMailFooter() {
    return `
<html>
<head></head>
<body>
<table style="width:100.0%" width="100%" cellspacing="0" cellpadding="0" border="0">
  <tbody>
  <tr>
    <td style="padding:20.25pt 18.75pt 20.25pt 0">
      <table style="width:100.0%" width="100%" cellspacing="0" cellpadding="0" border="0">
        <tbody>
        <tr>
          <td style="padding:0cm 0cm 12.75pt 0cm">
            <p class="MsoNormal" style="line-height:11.25pt"><span
              style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;color:black">Met vriendelijke groet,
<u></u><u></u></span></p>
          </td>
        </tr>
        <tr>
          <td style="padding:0cm 0cm 20.25pt 0cm">
            <table cellspacing="0" cellpadding="0" border="0" align="left">
              <tbody>
              <tr>
                <td style="padding:0cm 27.0pt 15.0pt 0cm" valign="top">
                  <p class="MsoNormal"><span><img style="width:1.7916in;height:.5in"
                                                  id="m_-5345392473130271055_x0000_i1029"
                                                  src="https://ci5.googleusercontent.com/proxy/6Up2CHuk3WVr9Ne9yvO319e4urcFtQrftXhb_-U4223emES9iNKGwSdPE8InZ4SVaWY=s0-d-e1-ft#https://i.imgur.com/gNKVn6i.png"
                                                  alt="Aquagroup / Het begint bij zacht water" class="CToWUd"
                                                  width="172" height="48"></span><span><u></u><u></u></span></p>
                </td>
              </tr>
              </tbody>
            </table>
<!--            <p class="MsoNormal"><span style="display:none"><u></u>&nbsp;<u></u></span></p>-->
            <table cellspacing="0" cellpadding="0" border="0" align="left">
              <tbody>
              <tr><td></td></tr>
              <tr>
                <td style="padding:0cm 0cm 0cm 0cm">
                  <p class="MsoNormal" style="line-height:11.25pt"><span
                    style="font-size:8.5pt;font-family:&quot;Arial&quot;,sans-serif;color:black"><a
                    href="tel:+3256615162" target="_blank"><span style="color:black;text-decoration:none">+32 56
 61 51 62</span></a> <br>
W</span><span style="font-size:8.5pt;font-family:&quot;Tahoma&quot;,sans-serif;color:black">﻿</span><span
                    style="font-size:8.5pt;font-family:&quot;Arial&quot;,sans-serif;color:black">indh</span><span
                    style="font-size:8.5pt;font-family:&quot;Tahoma&quot;,sans-serif;color:black">﻿</span><span
                    style="font-size:8.5pt;font-family:&quot;Arial&quot;,sans-serif;color:black">oek
 1</span><span style="font-size:8.5pt;font-family:&quot;Tahoma&quot;,sans-serif;color:black">﻿</span><span
                    style="font-size:8.5pt;font-family:&quot;Arial&quot;,sans-serif;color:black">8, 8</span><span
                    style="font-size:8.5pt;font-family:&quot;Tahoma&quot;,sans-serif;color:black">﻿</span><span
                    style="font-size:8.5pt;font-family:&quot;Arial&quot;,sans-serif;color:black">79</span><span
                    style="font-size:8.5pt;font-family:&quot;Tahoma&quot;,sans-serif;color:black">﻿</span><span
                    style="font-size:8.5pt;font-family:&quot;Arial&quot;,sans-serif;color:black">0
 W</span><span style="font-size:8.5pt;font-family:&quot;Tahoma&quot;,sans-serif;color:black">﻿</span><span
                    style="font-size:8.5pt;font-family:&quot;Arial&quot;,sans-serif;color:black">aregem
<br>
<b><a href="https://www.aquagroup.be/" target="_blank"
      data-saferedirecturl="https://www.google.com/url?q=https://www.aquagroup.be/&amp;source=gmail&amp;ust=1615799885231000&amp;usg=AFQjCNHHzTcrKcApaQ5AMf_Kk90B3e1Swg"><span
  style="color:#008bc7;text-decoration:none">www.aquagroup.be</span></a></b>
<u></u><u></u></span></p>
                </td>
              </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <td style="border:none;border-bottom:solid #008bc7 1.0pt;padding:0cm 0cm 0cm 0cm" valign="top">
            <table cellspacing="0" cellpadding="0" border="0" align="left">
              <tbody>
              <tr>
                <td style="padding:0cm 0cm 20.25pt 0cm" valign="top">
                  <table style="width:100.0%" width="100%" cellspacing="0" cellpadding="0" border="0">
                    <tbody>
                    <tr>
                      <td style="width:73.5pt;padding:0cm 0cm 0cm 0cm" width="98" valign="top">
                        <table style="width:100.0%" width="100%" cellspacing="0" cellpadding="0" border="0">
                          <tbody>
                          <tr>
                            <td style="padding:0cm 0cm 7.5pt 0cm">
                              <p class="MsoNormal" style="text-align:center" align="center"><a
                                href="https://waterverzachteronlinekopen.be/?utm_source=webshop&amp;utm_medium=email&amp;utm_campaign=emailsignature"
                                target="_blank"
                                data-saferedirecturl="https://www.google.com/url?q=https://waterverzachteronlinekopen.be/?utm_source%3Dwebshop%26utm_medium%3Demail%26utm_campaign%3Demailsignature&amp;source=gmail&amp;ust=1615799885231000&amp;usg=AFQjCNGzAjf-GrHjfngOPdmexb8h9jF8Xw"><span
                                style="color:blue;text-decoration:none"><img style="width:.7291in;height:.7291in"
                                                                             id="m_-5345392473130271055_x0000_i1028"
                                                                             src="https://ci5.googleusercontent.com/proxy/U23UBUM685FibwtH29Gn-iTFV3X6w9CaqxTJTy0gT-Ep51-pA_Pkgzm-VoYAmdV4Wig=s0-d-e1-ft#https://i.imgur.com/IFdLq4j.png"
                                                                             alt="webshop" class="CToWUd" width="70"
                                                                             height="70"
                                                                             border="0"></span></a><span><u></u><u></u></span>
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td style="padding:0cm 0cm 0cm 0cm">
                              <p class="MsoNormal" style="text-align:center;line-height:7.5pt" align="center">
                                <b><span
                                  style="font-size:6.0pt;font-family:&quot;Arial&quot;,sans-serif;color:black;text-transform:uppercase"><a
                                  href="https://waterverzachteronlinekopen.be/?utm_source=webshop&amp;utm_medium=email&amp;utm_campaign=emailsignature"
                                  target="_blank"
                                  data-saferedirecturl="https://www.google.com/url?q=https://waterverzachteronlinekopen.be/?utm_source%3Dwebshop%26utm_medium%3Demail%26utm_campaign%3Demailsignature&amp;source=gmail&amp;ust=1615799885231000&amp;usg=AFQjCNGzAjf-GrHjfngOPdmexb8h9jF8Xw"><span
                                  style="font-size:7.0pt;color:black">WEBSHOP</span></a>
<u></u><u></u></span></b></p>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </td>
                      <td style="width:3.75pt;padding:0cm 0cm 0cm 0cm" width="5"></td>
                      <td style="width:73.5pt;padding:0cm 0cm 0cm 0cm" width="98" valign="top">
                        <table style="width:100.0%" width="100%" cellspacing="0" cellpadding="0" border="0">
                          <tbody>
                          <tr>
                            <td style="padding:0cm 0cm 7.5pt 0cm">
                              <p class="MsoNormal" style="text-align:center" align="center"><a
                                href="https://www.waterverzachteraquagroup.be/?utm_source=waterverzachter&amp;utm_medium=email&amp;utm_campaign=emailsignature"
                                target="_blank"
                                data-saferedirecturl="https://www.google.com/url?q=https://www.waterverzachteraquagroup.be/?utm_source%3Dwaterverzachter%26utm_medium%3Demail%26utm_campaign%3Demailsignature&amp;source=gmail&amp;ust=1615799885232000&amp;usg=AFQjCNGpMlCeoV2YIXnUgliCTW9zaOUVjA"><span
                                style="color:blue;text-decoration:none"><img style="width:.7291in;height:.7291in"
                                                                             id="m_-5345392473130271055_x0000_i1027"
                                                                             src="https://ci3.googleusercontent.com/proxy/yANdgE0UYgIOweZQqzz0_S6ugaFUZqx1_kJYOAKshROPQ3Ha0rHVXdpGJJHsjqjQ2NM=s0-d-e1-ft#https://i.imgur.com/Ru9WrOI.png"
                                                                             alt="waterverzachters" class="CToWUd"
                                                                             width="70" height="70"
                                                                             border="0"></span></a><span><u></u><u></u></span>
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td style="padding:0cm 0cm 0cm 0cm">
                              <p class="MsoNormal" style="text-align:center;line-height:7.5pt" align="center">
                                <b><span
                                  style="font-size:6.0pt;font-family:&quot;Arial&quot;,sans-serif;color:black;text-transform:uppercase"><a
                                  href="https://www.waterverzachteraquagroup.be/?utm_source=waterverzachter&amp;utm_medium=email&amp;utm_campaign=emailsignature"
                                  target="_blank"
                                  data-saferedirecturl="https://www.google.com/url?q=https://www.waterverzachteraquagroup.be/?utm_source%3Dwaterverzachter%26utm_medium%3Demail%26utm_campaign%3Demailsignature&amp;source=gmail&amp;ust=1615799885232000&amp;usg=AFQjCNGpMlCeoV2YIXnUgliCTW9zaOUVjA"><span
                                  style="font-size:7.0pt;color:black">WATERVERZACHTERS</span></a>
<u></u><u></u></span></b></p>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </td>
                      <td style="width:3.75pt;padding:0cm 0cm 0cm 0cm" width="5"></td>
                    </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              </tbody>
            </table>
<!--            <p class="MsoNormal"><span style="display:none"><u></u>&nbsp;<u></u></span></p>-->
            <table cellspacing="0" cellpadding="0" border="0" align="left">
              <tbody>
              <tr>
                <td style="padding:0cm 0cm 20.25pt 0cm" valign="top">
                  <table style="width:100.0%" width="100%" cellspacing="0" cellpadding="0" border="0">
                    <tbody>
                    <tr>
                      <td style="width:73.5pt;padding:0cm 0cm 0cm 0cm" width="98" valign="top">
                        <table style="width:100.0%" width="100%" cellspacing="0" cellpadding="0" border="0">
                          <tbody>
                          <tr>
                            <td style="padding:0cm 0cm 7.5pt 0cm">
                              <p class="MsoNormal" style="text-align:center" align="center"><a
                                href="https://www.waterverzachteraquagroup.be/waterbehandeling/drinkwaterfilters?utm_source=drinkwaterfilters&amp;utm_medium=email&amp;utm_campaign=emailsignature"
                                target="_blank"
                                data-saferedirecturl="https://www.google.com/url?q=https://www.waterverzachteraquagroup.be/waterbehandeling/drinkwaterfilters?utm_source%3Ddrinkwaterfilters%26utm_medium%3Demail%26utm_campaign%3Demailsignature&amp;source=gmail&amp;ust=1615799885232000&amp;usg=AFQjCNEes6vpDhzpVzUoeMlk-Kn_cnZIyw"><b><span
                                style="font-size:7.0pt;font-family:&quot;Arial&quot;,sans-serif;color:black;text-transform:uppercase;text-decoration:none"><img
                                style="width:.7291in;height:.7291in" id="m_-5345392473130271055_x0000_i1026"
                                src="https://ci6.googleusercontent.com/proxy/87JefEi2Ke1W3lop4m_LaawBtAiSnl8rKBF80HreppJuKzOsJJG8aNt0xV0CKDzHHWc=s0-d-e1-ft#https://i.imgur.com/6YQTf5u.png"
                                alt="drinkwaterfilters" class="CToWUd" width="70" height="70" border="0"></span></b></a><span><u></u><u></u></span>
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td style="padding:0cm 0cm 0cm 0cm">
                              <p class="MsoNormal" style="text-align:center;line-height:7.5pt" align="center">
                                <b><span
                                  style="font-size:6.0pt;font-family:&quot;Arial&quot;,sans-serif;color:black;text-transform:uppercase"><a
                                  href="https://www.waterverzachteraquagroup.be/waterbehandeling/drinkwaterfilters?utm_source=drinkwaterfilters&amp;utm_medium=email&amp;utm_campaign=emailsignature"
                                  target="_blank"
                                  data-saferedirecturl="https://www.google.com/url?q=https://www.waterverzachteraquagroup.be/waterbehandeling/drinkwaterfilters?utm_source%3Ddrinkwaterfilters%26utm_medium%3Demail%26utm_campaign%3Demailsignature&amp;source=gmail&amp;ust=1615799885232000&amp;usg=AFQjCNEes6vpDhzpVzUoeMlk-Kn_cnZIyw"><span
                                  style="font-size:7.0pt;color:black">DRINKWATERFILTERS
</span></a><u></u><u></u></span></b></p>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </td>
                      <td style="width:3.75pt;padding:0cm 0cm 0cm 0cm" width="5"></td>
                      <td style="width:73.5pt;padding:0cm 0cm 0cm 0cm" width="98" valign="top">
                        <table style="width:100.0%" width="100%" cellspacing="0" cellpadding="0" border="0">
                          <tbody>
                          <tr>
                            <td style="padding:0cm 0cm 7.5pt 0cm">
                              <p class="MsoNormal" style="text-align:center" align="center"><a
                                href="https://www.aquagroupdrinkwatersystemen.be/?utm_source=drinkwatersystemen&amp;utm_medium=email&amp;utm_campaign=emailsignature"
                                target="_blank"
                                data-saferedirecturl="https://www.google.com/url?q=https://www.aquagroupdrinkwatersystemen.be/?utm_source%3Ddrinkwatersystemen%26utm_medium%3Demail%26utm_campaign%3Demailsignature&amp;source=gmail&amp;ust=1615799885232000&amp;usg=AFQjCNE3WI2ssag7Kl2O3dm29yhmLAfoSQ"><b><span
                                style="font-size:7.0pt;font-family:&quot;Arial&quot;,sans-serif;color:black;text-transform:uppercase;text-decoration:none"><img
                                style="width:.7291in;height:.7291in" id="m_-5345392473130271055_x0000_i1025"
                                src="https://ci3.googleusercontent.com/proxy/5tZFS3Iah_Ur1PuEYdrVvJoeYTEh4RbCAv1WTYUvUyt8xFceJS0kq0gxYWQSHHBjoKQ=s0-d-e1-ft#https://i.imgur.com/nRWNfj3.png"
                                class="CToWUd" width="70" height="70"
                                border="0"></span></b></a><span><u></u><u></u></span></p>
                            </td>
                          </tr>
                          <tr>
                            <td style="padding:0cm 0cm 0cm 0cm">
                              <p class="MsoNormal" style="text-align:center;line-height:7.5pt" align="center">
                                <b><span
                                  style="font-size:6.0pt;font-family:&quot;Arial&quot;,sans-serif;color:black;text-transform:uppercase"><a
                                  href="https://www.aquagroupdrinkwatersystemen.be/?utm_source=drinkwatersystemen&amp;utm_medium=email&amp;utm_campaign=emailsignature"
                                  target="_blank"
                                  data-saferedirecturl="https://www.google.com/url?q=https://www.aquagroupdrinkwatersystemen.be/?utm_source%3Ddrinkwatersystemen%26utm_medium%3Demail%26utm_campaign%3Demailsignature&amp;source=gmail&amp;ust=1615799885232000&amp;usg=AFQjCNE3WI2ssag7Kl2O3dm29yhmLAfoSQ"><span
                                  style="font-size:7.0pt;color:black">DRINKWATERSYSTEMEN</span></a>
<u></u><u></u></span></b></p>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </td>
                      <td style="width:3.75pt;padding:0cm 0cm 0cm 0cm" width="5"></td>
                    </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              </tbody>
            </table>
          </td>
        </tr>
        </tbody>
      </table>
    </td>
  </tr>
  </tbody>
</table>
</body>
</html>
    `;
}

export function getMailTemplateAppointmentPlanned(
    planningItem: IPlanningItemEntity,
    calendarEventToAddStartDate: Date,
    language: Language,
): IEmailObjectEntity {
    const email_object: IEmailObjectEntity = {
        subject: '',
        content: '',
        receivers: [{ email: 'teodeconinck@gmail.com' }],
        senders: [{ email: 'info@aquagroup.be' }],
        language: language,
    };

    let address = '';
    let clientName = '';
    const plannedDateTime = moment(calendarEventToAddStartDate).format('DD/MM/YYYY HH:mm:ss');

    clientName = PlanningItemEngine.getClientName(planningItem.client);
    address = planningItem.client.client_data.main_address.address;
    if (planningItem.planning_item_type === PlanningItemType.lead) {
        if (language === 'nl') {
            email_object.content = getTemplateAppointmentNl(clientName, plannedDateTime);
            email_object.subject = 'Aquagroup - Bevestiging van afspraak';
        } else if (language === 'fr') {
            email_object.content = getTemplateAppointmentFr(clientName, plannedDateTime);
            email_object.subject = 'Aquagroup - Confirmation de rendez-vous';
        }
    } else {
        if (language === 'nl') {
            email_object.content = getTemplateNl(
                clientName,
                plannedDateTime,
                getNamePlanningItemType(planningItem.planning_item_type, language),
                planningItem.planning_item_type,
            );
            email_object.subject =
                'Aquagroup - Bevestiging van ' + getNamePlanningItemType(planningItem.planning_item_type, language);
        } else if (language === 'fr') {
            email_object.content = getTemplateFr(
                clientName,
                plannedDateTime,
                getNamePlanningItemType(planningItem.planning_item_type, language),
                planningItem.planning_item_type,
            );
            email_object.subject =
                'Aquagroup - Confirmation de ' +
                getNamePlanningItemType(planningItem.planning_item_type, language) +
                ' planifiée';
        }
    }

    return email_object;
}

function getTemplateNl(
    clientName: string,
    plannedDateTime: string,
    type: string,
    planningItemType: PlanningItemType,
): string {
    let emailHtml: string = null;

    emailHtml = `
    <p style="margin-bottom:5px;">Beste ${clientName},</p>
    <p>Graag bevestig ik uw ${type} die gepland staat op ${plannedDateTime}.</p>
    <p style="margin-bottom:5px;">Rekening houdend met het wijzigen van het voorgestelde uur door verkeer/ andere omstandigheden.
    <br/>Gelieve ons te verwittigen indien de afspraak niet kan doorgaan.
    <br/>Alvast bedankt voor het vertrouwen en graag tot dan!
    </p>
`;

    if (planningItemType === PlanningItemType.maintenance) {
        emailHtml += getTemplateExtraInfoMaintenanceNL();
    }

    return emailHtml;
}

function getTemplateFr(
    clientName: string,
    plannedDateTime: string,
    type: string,
    planningItemType: PlanningItemType,
): string {
    let emailHtml: string = null;

    emailHtml = `
    <p style="margin-bottom:5px;">Cher ${clientName},</p>
    <p>Je voudrais confirmer votre ${type} qui est programmée le ${plannedDateTime}.</p>
    <p style="margin-bottom:5px;">Prise en compte de la variation de l'heure proposée en raison du trafic ou d'autres circonstances.
    <br/>Veuillez nous informer si le rendez-vous ne peut avoir lieu.
    <br/>Merci d'avance pour votre confiance et à bientôt !</p>
`;

    if (planningItemType === PlanningItemType.maintenance) {
        emailHtml += getTemplateExtraInfoMaintenanceFR();
    }

    return emailHtml;
}

function getTemplateAppointmentNl(clientName: string, plannedDateTime: string): string {
    return `
    <p>Beste ${clientName},</p>
    <br>
    <p>Graag bevestig ik de geplande afspraak op ${plannedDateTime}.</p>

      <p>Indien de afspraak niet kan doorgaan gelieve tijdig te verwittigen.</p>

    <br>
    <p>
      Alvast bedankt voor het vertrouwen en graag tot dan!
    </p>

`;
}

function getTemplateAppointmentFr(clientName: string, plannedDateTime: string): string {
    return `<p>Cher ${clientName},</p>
    <br>
    <p>Je voudrais confirmer le rendez-vous qui est programmée le ${plannedDateTime}.</p>

      <p>Si le rendez-vous ne peut pas aller de l'avant, veuillez aviser à temps s’il vous plaît. </p>

    <br>
    <p>
      Merci d'avance pour votre confiance et à bientôt!
    </p>
`;
}

export function getTemplateNotifyForUpcomingMaintenance(clientName: string): string {
    return `
    <p>Beste ${clientName},</p>
    <br>
    <p>Het is ondertussen bijna een jaar geleden dat het jaarlijks onderhoud uitgevoerd werd op uw ontharder.</p>

      <p> Binnenkort bellen wij u op om het jaarlijks onderhoud in te plannen.</p>

    <br>
    <p>
      Als u geen onderhoud of zelf een datum wenst voor te stellen kan je antwoorden op deze mail of bellen op ons
       nummer 056 61 51 62.
    </p>
    ${getTemplateExtraInfoMaintenanceNL()}
    <hr/>
    <br/>
    <p>Cher ${clientName},</p>
    <br>
    <p>Cela fait presque un an que l'entretien annuel a été effectué sur votre adoucisseur d'eau..</p>

      <p>Bientôt, nous vous appellerons pour programmer l'entretien annuel.</p>

    <br>
    <p>
      Si vous ne souhaitez pas d’entretien ou si vous voulez proposer une date vous-même, vous pouvez répondre à ce courrier ou nous appeler au 056 61 51 62.
    </p>
    ${getTemplateExtraInfoMaintenanceFR()}
`;
}

export function getTemplateNotifyAppointmentDayBefore(
    clientName: string,
    plannedDateTime: string,
    planningItemType: PlanningItemType,
): string {
    let emailHtml: string = null;

    emailHtml = `
    <p style="margin-bottom:5px;">Beste ${clientName},</p>
    <p>Graag herinner ik u aan uw afspraak die gepland staat op ${plannedDateTime}.</p>
    <p style="margin-bottom:5px;">Rekening houdend met het wijzigen van het voorgestelde uur door verkeer/ andere omstandigheden.
    <br/>Gelieve ons te verwittigen indien de afspraak niet kan doorgaan.
    <br/>Alvast bedankt voor het vertrouwen en graag tot dan!
    </p>
  `;

    if (planningItemType === PlanningItemType.maintenance) {
        emailHtml += getTemplateExtraInfoMaintenanceNL();
    }

    emailHtml += `
    <hr/>
    <br/>
    <p style="margin-bottom:5px;">Beste ${clientName},</p>
    <p>Je vous rappelle votre rendez-vous qui est prévue le ${plannedDateTime}</p>
    <p style="margin-bottom:5px;">Prise en compte de la variation de l'heure proposée en raison du trafic ou d'autres circonstances.
    <br/>Veuillez nous informer si le rendez-vous ne peut avoir lieu.
    <br/>Merci d'avance pour votre confiance et à bientôt !
    </p>
  `;

    if (planningItemType === PlanningItemType.maintenance) {
        emailHtml += getTemplateExtraInfoMaintenanceFR();
    }

    return emailHtml;
}

export function getTemplateExtraInfoMaintenanceNL() {
    return `
       <br/>
    <p style="font-size: 12px">
      <i>
      Gezien de blijvende prijsstijgingen, zijn we genoodzaakt om de prijs van het
      onderhoud te verhogen naar €80 excl. BTW.
      Door de toenemende verhoging van de wereldwijde grondstofprijzen is het huidige tarief niet meer haalbaar voor ons.
      <br/>
      Deze zullen plaatsvinden vanaf 01/05/2022.<br/>
      Mocht u hiermee niet akkoord gaan, graag een seintje dan komen wij niet meer langs.
      Dit om discussies te vermijden.
      </i>
    </p>
  `;
}

export function getTemplateExtraInfoMaintenanceFR() {
    return `
 <br>
    <p style="font-size: 12px">
      <i>
        Compte tenu de l'augmentation continue des prix, nous sommes contraints d'augmenter le prix de la maintenance à 80 € sans TVA.
        En raison de l'augmentation croissante des prix des matières premières au niveau mondial, le taux actuel n'est plus réalisable pour nous.
        <br/>
        Cela se fera à partir du 01/05/2022.<br/>
        Si vous n'êtes pas d'accord avec cela, veuillez nous le faire savoir et nous ne vous rendrons plus visite. Ceci afin d'éviter les discussions.
      </i>
    </p>
    `;
}

export function getTemplateFinishedPlanningItemFeedback(clientName: string) {
    return `
    <p>Beste ${clientName},</p>
    <br>
    <p>Naar aanleiding van ons bezoek i.v.m. de waterontharder sturen wij u dit bericht.</p>
    <p>Heeft u opmerkingen of eventuele tips naar de toekomst toe?</p>
    <br>
    <p>Wij kijken alvast uit naar uw feedback om onze service blijven te optimaliseren.</p>
    <br/>
    <hr/>
    <br/>
    <p>Cher ${clientName},</p>
    <br>
    <p>Nous vous envoyons cet e-mail suite à notre visite en rapport avec votre adoucisseur d’eau.</p>

    <p>Avez-vous d’éventuels conseils ou remarques pour le futur?</p>
    <br>
    <p>Nous attendons votre retour avec impatience afin de continuer à optimiser nos services.</p>
    <br/>
  `;
}

export function getTemplateQuoteCreated(clientName: string) {
    return `
Beste ${clientName},<br>
<br>
Aquagroup biedt u de volgende offerte aan, zie bijlage!<br>
U kan deze offerte via mail bevestigen. Indien er nog iets gewijzigd moet worden gelieve ons te contacteren.<br>
<br>
<br>
----------------------------------------
<br>
Cher ${clientName},<br>
<br>
Aquagroup vous propose l'offre suivante, voir pièce jointe! <br>
Vous pouvez confirmer ce devis par mail. Si quelque chose doit être changé, veuillez nous contacter.<br>
<br>
<br>`;
}
