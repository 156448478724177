import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PlanningItemType, QuoteStatus, IQuoteSearchFilterEntity } from '@phrospr/api-backend';
import { validateQuoteItemSearchFilterKey } from '@phrospr/lib-core/src/engine/quote-filter.engine';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
    selector: 'pr-quote-filter',
    styleUrls: ['./quote-filter.component.scss'],
    templateUrl: './quote-filter.component.html',
})
export class QuoteFilterComponent implements OnInit {
    @Input() quoteItemSearchFilter: IQuoteSearchFilterEntity;
    @Input() createdDatePicker = false;
    @Input() searchLeadName = true;
    @Input() createdByName = true;
    @Input() soldByName = true;

    @Output() updateQuoteItemSearchFilter = new EventEmitter<IQuoteSearchFilterEntity>();

    filterChangeSubject$: Subject<void> = new Subject<void>();

    public QuoteStatus = QuoteStatus;

    fromCreatedDateTrigger = '';
    toCreatedDateTrigger = '';
    typeNumberId = '';
    clientNameTrigger = '';
    zipCodeTrigger = '';
    leadNameTrigger = '';
    quoteId = '';
    quoteStates: QuoteStatus[] = [QuoteStatus.approved, QuoteStatus.created];
    quoteStatusCreatedChecked = true;
    quoteStatusCancelledChecked = false;
    quoteStatusApprovedChecked = true;
    createdByUserNameTrigger = '';
    soldByUserNameTrigger = '';

    filterKeys = {
        TYPE_NUMBER: 'planning_item_type_number',
        FROM_CREATED_DATE: 'from_created_date',
        TO_CREATED_DATE: 'to_created_date',
        CLIENT_NUMBER: 'client_number',
        CLIENT_NAME: 'client_name',
        POSTCODE: 'postcode',
        PLANNING_ITEM_TYPE: PlanningItemType,
        LEAD_NAME: 'lead_name',
        QUOTE_ID: 'quote_id',
        CREATED_BY_USER_NAME: 'created_by_user_name',
        SOLD_BY_USER_NAME: 'sold_by_user_name',
    };

    constructor() {}

    ngOnInit(): void {
        if (!this.quoteItemSearchFilter) {
            return console.log('missing quoteItemsFilter');
        }

        this.filterChangeSubject$.pipe(debounceTime(1000)).subscribe(_ => this.sendNewFilter());
    }

    triggerFilter(filterName, event) {
        if (!validateQuoteItemSearchFilterKey(filterName)) return console.log('invalid filter key', filterName, event);
        this.quoteItemSearchFilter[filterName] = event;
        this.filterChangeSubject$.next();
    }

    triggerDateFilter(filterName, event) {
        let date = moment(event, 'DD-MM-YYYY');
        if (filterName.startsWith('to')) date = moment(date).endOf('day');
        this.triggerFilter(filterName, date.toISOString());
    }

    sendNewFilter() {
        this.updateQuoteItemSearchFilter.emit(this.quoteItemSearchFilter);
    }

    resetFilters() {
        for (const localFilterKey in this.filterKeys) {
            const filterKey = this.filterKeys[localFilterKey];
            this.quoteItemSearchFilter[filterKey] = undefined;
        }

        this.fromCreatedDateTrigger = null;
        this.toCreatedDateTrigger = null;
        this.typeNumberId = null;
        this.clientNameTrigger = null;
        this.zipCodeTrigger = null;
        this.leadNameTrigger = null;
        this.quoteId = null;
        this.quoteStatusCreatedChecked = true;
        this.quoteStatusApprovedChecked = true;
        this.quoteStatusCancelledChecked = false;
        this.quoteStates = [QuoteStatus.approved, QuoteStatus.created];
        this.quoteItemSearchFilter.quote_states = [QuoteStatus.approved, QuoteStatus.created];
        this.createdByUserNameTrigger = null;
        this.soldByUserNameTrigger = null;
        this.sendNewFilter();
    }

    public checkboxStatusClicked(value: QuoteStatus, checked: boolean) {
        if (checked === true) {
            this.quoteStates.push(value);
        } else {
            const index = this.quoteStates.indexOf(value);
            this.quoteStates.splice(index, 1);
        }
        this.quoteItemSearchFilter.quote_states = this.quoteStates;
        this.sendNewFilter();
    }
}
