import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { BASE_URL } from '../../../../environments/environment';
import { ICalendarItemEntity } from '@phrospr/api-backend';

@Injectable()
export class CalendarItemService {
    constructor(private http: HttpClient) {}

    public addCalendarItem(calendarItem: ICalendarItemEntity): Observable<any> {
        return this.http.post(`${BASE_URL}api/calendar-item/add`, calendarItem);
    }

    public updateCalendarItem(calendarItem: ICalendarItemEntity): Observable<any> {
        const params: any = { calendar_item_id: calendarItem.calendar_item_id };
        return this.http.post(`${BASE_URL}api/calendar-item/update`, calendarItem, { params });
    }

    public GetActiveLeadCalendarItem(lead_id: number): Observable<any> {
        const params: any = { lead_id };
        return this.http.get(`${BASE_URL}api/calendar-items/getActiveLeadCalendarItem`, { params });
    }

    public getActiveCalendarItemsPerAppointmentType(appointment_type: string): Observable<any> {
        const params: any = { appointment_type };
        return this.http.get(`${BASE_URL}api/calendar-items/getActiveCalendarItemsPerAppointmentType`, { params });
    }
}
