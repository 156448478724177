import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ExactManager } from '../../../shared/manager/exact.manager';
import { PhrosprPortalApi } from '../../../../../resource/phrospr-api';
import { IClientEntity, IClientFilterEntity, ClientState, RequestSort } from '@phrospr/api-backend';

@Component({
    selector: 'pr-sales-page',
    styleUrls: ['./client-page.component.scss'],
    templateUrl: './client-page.component.html',
})
export class ClientPageComponent implements OnInit {
    page: {
        limit: number;
        count: number;
        offset: number;
        sort?: {
            order: RequestSort;
            column: string;
        };
    } = {
        limit: 25,
        count: 0,
        offset: 0,
        sort: { order: RequestSort.ASC, column: 'client_number' },
    };

    clients: IClientEntity[];
    columns = [
        { prop: 'exact_client_number', name: 'Klant nummer' },
        { prop: 'client_data.first_name', name: 'Naam' },
        { prop: 'client_data.email', name: 'Email' },
        { prop: 'client_data.mobile_phone', name: 'Telefoon nummer' },
    ];
    clientList$: ReplaySubject<any> = new ReplaySubject(1);

    reorderable: true;

    ClientState = ClientState;
    clientStates: ClientState[] = [ClientState.client];
    clientStateClientChecked = true;
    clientStateLeadChecked = false;

    filter: { clientNumber: string; clientName: string; client_states?: ClientState[] } = {
        clientNumber: '',
        clientName: '',
        client_states: this.clientStates,
    };
    filter$: ReplaySubject<{ key: string; value: any }> = new ReplaySubject(1);

    clientNumberTrigger = '';
    searchClientNumberField$: ReplaySubject<any> = new ReplaySubject(1);

    clientNameTrigger = '';
    searchClientNameField$: ReplaySubject<any> = new ReplaySubject(1);

    updateClientsLoading = false;

    constructor(private router: Router, private exactManager: ExactManager) {
        this.filter$.subscribe(item => {
            if (!item) return;
            this.filter[item.key] = item.value;
        });
        this.searchClientNumberField$.pipe(debounceTime(1000)).subscribe(_ => {
            this.filter$.next({ key: 'clientNumber', value: this.clientNumberTrigger });
            this.reloadTable({ count: 1, pageSize: 25, limit: 25, offset: 0 });
        });
        this.searchClientNameField$.pipe(debounceTime(1000)).subscribe(_ => {
            this.filter$.next({ key: 'clientName', value: this.clientNameTrigger });
            this.filter$.next({ key: 'clientNumber', value: this.clientNameTrigger });
            this.reloadTable({ count: 1, pageSize: 25, limit: 25, offset: 0 });
        });
    }

    ngOnInit(): void {
        this.pageCallback({ offset: 0 });
    }

    pageCallback(pageInfo: { count?: number; pageSize?: number; limit?: number; offset?: number }) {
        this.reloadTable(pageInfo);
    }

    sortCallback(sortInfo: {
        sorts: { dir: 'ASC' | 'DESC'; prop: string };
        column: {};
        prevValue: string;
        newValue: string;
    }) {
        // there will always be one "sort" object if "sortType" is set to "single"
        this.page.sort.order = sortInfo.sorts[0].dir.toUpperCase();
        this.page.sort.column = sortInfo.sorts[0].prop;
        this.reloadTable();
    }

    async reloadTable(pageInfo?: {
        count?: number;
        pageSize?: number;
        limit?: number;
        offset?: number;
        sort?: {
            order: RequestSort;
            column: string;
        };
    }) {
        const limit = pageInfo && pageInfo.limit ? pageInfo.limit : this.page.limit;
        const offset = pageInfo && (pageInfo.offset || pageInfo.offset === 0) ? pageInfo.offset : this.page.offset;
        let sort = pageInfo && pageInfo.sort ? pageInfo.sort : this.page.sort;
        if (sort) {
            sort = JSON.parse(JSON.stringify(sort));
            if (sort.column === 'number') {
                sort.column = 'client_number';
            }
        }
        if (this.filter) {
            this.filter = JSON.parse(JSON.stringify(this.filter));
        }
        const ClientFilterEntity: IClientFilterEntity = {
            filter: this.filter,
            offset: offset * limit,
            sort: sort,
            limit: limit,
            show: {},
        };

        try {
            const clientList = (await PhrosprPortalApi.ClientApi.listClients(ClientFilterEntity)).data;
            this.clients = clientList.clients;
            this.page = {
                limit: limit,
                offset: offset,
                count: clientList.total,
                sort: sort,
            };
        } catch (err) {
            console.error(err);
        }
    }

    updateAllClientsInDB() {
        this.updateClientsLoading = true;
        this.exactManager
            .updateAllClientsFromExactInDB()
            .toPromise()
            .then(booleanValue => {
                this.updateClientsLoading = false;
            });
    }

    onActivate(event) {
        if (event.type === 'click') {
            this.goToOverviewClient(event.row.client_id);
        }
    }

    /* getAllClientsTest() {
      PhrosprPortalApi.ClientApi.listClients()
        this.clientManager.getClients().subscribe(clients => {
            this.clients = clients;
        });
    }*/

    goToOverviewClient(clientID: number) {
        this.router.navigateByUrl('/client-detail?client_id=' + clientID);
    }

    getRowClass(row) {
        return {
            'row-style-datatable': row.client !== null,
        };
    }

    clearFilters() {
        this.filter = { clientNumber: '', clientName: '', client_states: [ClientState.client] };
        this.clientNumberTrigger = null;
        this.clientNameTrigger = null;
        this.reloadTable({ count: 1, pageSize: 25, limit: 25, offset: 0 });
    }

    public checkboxClientStateClicked(value: ClientState, checked: boolean) {
        if (checked === true) {
            this.clientStates.push(value);
        } else {
            const index = this.clientStates.indexOf(value);
            this.clientStates.splice(index, 1);
        }
        this.filter.client_states = this.clientStates;
        this.reloadTable({ count: 1, pageSize: 25, limit: 25, offset: 0 });
    }

    async newClient() {
        await this.router.navigateByUrl('/client-detail');
    }
}
