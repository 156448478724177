export enum ActivityType {
    CREATE = 'CREATE',
    UPDATE = 'UPDATE',
    STATE_UPDATE = 'STATE_UPDATE',
    ADD_COMMENT = 'ADD_COMMENT',
    DELETE = 'DELETE',
    PLANNED = 'PLANNED',
    PLANNED_CONFIRMED = 'PLANNED_CONFIRMED',
    MANUAL_FINISHED = 'MANUAL_FINISHED',
    QUOTE_CREATED = 'QUOTE_CREATED',
    QUOTE_UPDATED = 'QUOTE_UPDATED',
    QUOTE__STATE_UPDATED = 'QUOTE__STATE_UPDATED',
    QUOTE_MAIL_SEND = 'QUOTE_MAIL_SEND',
    QUOTE_MAIL_FAILED = 'QUOTE_MAIL_FAILED',
    QUOTE_CANCELLED = 'QUOTE_CANCELLED',
    QUOTE_RATED = 'QUOTE_RATED',
    VISIT_REPORT_UPDATED = 'VISIT_REPORT_UPDATED',
    PLANNING_ITEM_FEEDBACK_MAIL_SEND = 'PLANNING_ITEM_FEEDBACK_MAIL_SEND',
    PLANNING_ITEM_FEEDBACK_MAIL_FAILED = 'PLANNING_ITEM_FEEDBACK_MAIL_FAILED',
    PLANNING_ITEM_PLANNED_MAIL_SEND = 'PLANNING_ITEM_PLANNED_MAIL_SEND',
    PLANNING_ITEM_PLANNED_MAIL_FAILED = 'PLANNING_ITEM_PLANNED_MAIL_FAILED',
    PLANNING_ITEM_UPCOMING_MAIL_SEND = 'PLANNING_ITEM_UPCOMING_MAIL_SEND',
    PLANNING_ITEM_UPCOMING_MAIL_FAILED = 'PLANNING_ITEM_UPCOMING_MAIL_FAILED',
    PLANNED_NOTIFICATION_MAIL_SEND = 'PLANNED_NOTIFICATION_MAIL_SEND',
    PLANNED_NOTIFICATION_MAIL_FAILED = 'PLANNED_NOTIFICATION_MAIL_FAILED',
    LEAD_VIEWED = 'LEAD_VIEWED',
    PROTEST_LEAD = 'PROTEST_LEAD',
    UPDATE_LEAD_DATA = 'UPDATE_LEAD_DATA',
    NEW_LEAD = 'NEW_LEAD',
    RAW_LEAD = 'RAW_LEAD',
    LEAD_TRANSFORMED_TO_CLIENT = 'LEAD_TRANSFORMED_TO_CLIENT',
    MAINTENANCE_INACTIVE = 'MAINTENANCE_INACTIVE',
    MAINTENANCE_ACTIVE = 'MAINTENANCE_ACTIVE',
    CLIENT_UPDATE = 'CLIENT_UPDATE',
    CLIENT_EMAIL_WRONG_DATA_UPDATE = 'CLIENT_EMAIL_WRONG_DATA_UPDATE',
    ADD_CLIENT = 'ADD_CLIENT',
    ADD_CLIENT_THROUGH_PORTAL = 'ADD_CLIENT_THROUGH_PORTAL',
    ADD_CLIENT_THROUGH_SYNC_EXACT = 'ADD_CLIENT_THROUGH_SYNC_EXACT',
    ADD_PRODUCT = 'ADD_PRODUCT',
    PRODUCT_UPDATE = 'PRODUCT_UPDATE',
    ATTACHMENT_ADDED = 'ATTACHMENT_ADDED',
    ATTACHMENT_DELETED = 'ATTACHMENT_DELETED',
    MAINTENANCE_CREATED_FOR_NEW_LEAD = 'MAINTENANCE_CREATED_FOR_NEW_LEAD',
    UPDATE_WATER_METER_STAND = 'UPDATE_WATER_METER_STAND',
}
