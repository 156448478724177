import { Pipe, PipeTransform } from '@angular/core';
import { PlanningItemType } from '@phrospr/api-backend';
import { getNamePlanningItemType } from '@phrospr/lib-core/src/engine/planning-item.engine';
import { Language } from '@phrospr/lib-models';

@Pipe({
    name: 'getNameOfPlanningItemTypePipe',
})
export class GetNameOfPlanningItemType implements PipeTransform {
    transform(planningItemType: PlanningItemType, language?: Language): string {
        return getNamePlanningItemType(planningItemType, language);
    }
}
