"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseIUpsertQuoteData = void 0;
const iaddress_1 = require("./iaddress");
const iquote_data_entity_1 = require("./iquote-data-entity");
const iquote_line_entity_1 = require("./iquote-line-entity");
function parseIUpsertQuoteData(data) {
    if (data.quoteLines !== null && typeof data.quoteLines === 'object')
        data.quoteLines = data.quoteLines.map((x) => (0, iquote_line_entity_1.parseIQuoteLineEntity)(x));
    if (data.deliveryAddress !== null && typeof data.deliveryAddress === 'object')
        data.deliveryAddress = (0, iaddress_1.parseIAddress)(data.deliveryAddress);
    if (data.data !== null && typeof data.data === 'object')
        data.data = (0, iquote_data_entity_1.parseIQuoteDataEntity)(data.data);
    return data;
}
exports.parseIUpsertQuoteData = parseIUpsertQuoteData;
