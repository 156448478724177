"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseICalendarEvent = exports.ICalendarEventOriginalEndTimeZoneEnum = exports.ICalendarEventOriginalStartTimeZoneEnum = void 0;
const icalendar_event_attendees_1 = require("./icalendar-event-attendees");
const icalendar_event_body_1 = require("./icalendar-event-body");
const icalendar_event_location_1 = require("./icalendar-event-location");
const icalendar_event_organizer_1 = require("./icalendar-event-organizer");
const icalendar_event_response_status_1 = require("./icalendar-event-response-status");
const icalendar_event_start_1 = require("./icalendar-event-start");
/**
    * @export
    * @enum {string}
    */
var ICalendarEventOriginalStartTimeZoneEnum;
(function (ICalendarEventOriginalStartTimeZoneEnum) {
    ICalendarEventOriginalStartTimeZoneEnum["Utc"] = "UTC";
})(ICalendarEventOriginalStartTimeZoneEnum = exports.ICalendarEventOriginalStartTimeZoneEnum || (exports.ICalendarEventOriginalStartTimeZoneEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ICalendarEventOriginalEndTimeZoneEnum;
(function (ICalendarEventOriginalEndTimeZoneEnum) {
    ICalendarEventOriginalEndTimeZoneEnum["Utc"] = "UTC";
})(ICalendarEventOriginalEndTimeZoneEnum = exports.ICalendarEventOriginalEndTimeZoneEnum || (exports.ICalendarEventOriginalEndTimeZoneEnum = {}));
function parseICalendarEvent(data) {
    if (data.responseStatus !== null && typeof data.responseStatus === 'object')
        data.responseStatus = (0, icalendar_event_response_status_1.parseICalendarEventResponseStatus)(data.responseStatus);
    if (data.body !== null && typeof data.body === 'object')
        data.body = (0, icalendar_event_body_1.parseICalendarEventBody)(data.body);
    if (data.start !== null && typeof data.start === 'object')
        data.start = (0, icalendar_event_start_1.parseICalendarEventStart)(data.start);
    if (data.end !== null && typeof data.end === 'object')
        data.end = (0, icalendar_event_start_1.parseICalendarEventStart)(data.end);
    if (data.location !== null && typeof data.location === 'object')
        data.location = (0, icalendar_event_location_1.parseICalendarEventLocation)(data.location);
    if (data.attendees !== null && typeof data.attendees === 'object')
        data.attendees = data.attendees.map((x) => (0, icalendar_event_attendees_1.parseICalendarEventAttendees)(x));
    if (data.organizer !== null && typeof data.organizer === 'object')
        data.organizer = (0, icalendar_event_organizer_1.parseICalendarEventOrganizer)(data.organizer);
    return data;
}
exports.parseICalendarEvent = parseICalendarEvent;
