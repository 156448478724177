import { IClientExactEntity } from '@phrospr/lib-models/src/resource/exact/client.exact.entity';
import { IClientEntity } from '@phrospr/api-backend';

export function transformClientInExactClient(client: IClientEntity): IClientExactEntity {
    if (!client.client_data.first_name && !client.client_data.last_name)
        throw new Error('at least one name needs to be filled in');
    const name = `${client.client_data.first_name ? client.client_data.first_name + ' ' : ''}`;
    // I removed following because else we always get dirty data.... Problem that exact only has 1 name field
    // ${client.client_data.last_name ? client.client_data.last_name : ''}
    const clientExact: IClientExactEntity = {
        id: client.client_data.exact_client_id ? client.client_data.exact_client_id : undefined,
        phoneNumber: client.client_data.telephone_phone ? client.client_data.telephone_phone : undefined,
        city: client.client_data.main_address.city ? client.client_data.main_address.city : undefined,
        countryName: client.client_data.main_address.countryName
            ? client.client_data.main_address.countryName
            : undefined,
        country: client.client_data.main_address.country ? client.client_data.main_address.country : undefined,
        email: client.client_data.email ? client.client_data.email : undefined,
        name: name,
        stateName: client.client_data.main_address.stateName ? client.client_data.main_address.stateName : undefined,
        state: client.client_data.main_address.state ? client.client_data.main_address.state : undefined,
        addressLine1: client.client_data.main_address.address ? client.client_data.main_address.address : undefined,
        addressLine2:
            client.client_data.delivery_addresses && client.client_data.delivery_addresses[0].address
                ? client.client_data.delivery_addresses[0].address
                : undefined,
        postCode: client.client_data.main_address.zip_code ? client.client_data.main_address.zip_code : undefined,
        VATNumber:
            client.client_data.company_data && client.client_data.company_data.vat_number
                ? client.client_data.company_data.vat_number
                : undefined,
        remarks: client.client_data.remarks ? client.client_data.remarks : undefined,
        clientNumber: client.exact_client_number ? client.exact_client_number : null,
    };
    return clientExact;
}

export function newClientExactEntity(): IClientExactEntity {
    return {
        id: null,
        clientNumber: null,
        name: null,
        postCode: null,
        city: null,
        addressLine1: null,
        addressLine2: null,
        email: null,
        phoneNumber: null,
        remarks: null,
        stateName: null,
        state: null,
        countryName: null,
        country: null,
        VATNumber: null,
    };
}
