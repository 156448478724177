import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ROUTING } from '../../app.routing';
import { SharedModule } from '../shared/shared.module';
import { ClarityModule, ClrButtonGroupModule } from '@clr/angular';
import { LeadsPageComponent } from './containers/leads-page/leads-page.component';
import { NewLeadsPageComponent } from './containers/new-leads-page/new-leads-page.component';
import { PlannedLeadsPageComponent } from './containers/planned-leads-page/planned-leads-page.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { BrowserModule } from '@angular/platform-browser';
import { CreateLeadComponent } from './containers/create-lead/create-lead.component';
import { CompletedNoOfferPageComponent } from './containers/completed-no-offer-page/completed-no-offer-page.component';
import { CompletedWithOfferPageComponent } from './containers/completed-with-offer-page/completed-with-offer-page.component';
import { ProtestLeadsPageComponent } from './containers/protest-lead-page/protest-leads-page.component';
import { PlanningItemModule } from '../planning-item/planning-item.module';
import { LeadCompletedAndQuoteApprovedComponent } from './containers/lead-completed-and-quote-approved/lead-completed-and-quote-approved.component';
import { LeadCompletedAndQuoteCancelledComponent } from './containers/lead-completed-and-quote-cancelled/lead-completed-and-quote-cancelled.component';

const components = [];

const containers = [
    LeadsPageComponent,
    NewLeadsPageComponent,
    PlannedLeadsPageComponent,
    CreateLeadComponent,
    CompletedNoOfferPageComponent,
    CompletedWithOfferPageComponent,
    ProtestLeadsPageComponent,
    LeadCompletedAndQuoteApprovedComponent,
    LeadCompletedAndQuoteCancelledComponent,
];

const imports = [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ROUTING,
    SharedModule,
    ClarityModule,
    ClrButtonGroupModule,
    NgxDatatableModule,
    BrowserModule,
    PlanningItemModule,
];

const sandboxes = [];

const services = [];

@NgModule({
    imports: [...imports],
    declarations: [...containers, ...components],
    providers: [...sandboxes, ...services],
    entryComponents: [],
    exports: [...containers],
})
export class LeadsModule {}
