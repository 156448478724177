<div class="contentCentered">

  <div class="dashboard-search-content">
  <div>
      <h3 class="dashboard-search-item grid-item">DASHBOARD </h3>
      <div class="dashboard-search-item grid-item">
        <input (ngModelChange)="triggerDateFilter('from', $event)"
               [(ngModel)]="fromDate"
               clrDate placeholder="From"
               type="date"> to
        <input (ngModelChange)="triggerDateFilter('to', $event)"
               [(ngModel)]="toDate"
               clrDate placeholder="To" type="date">
      </div>

      <div class="dashboard-search-item grid-item">
        <button (click)="resetDates()" class="btn btn-link btn-sm">
          <clr-icon shape="times"></clr-icon>
          Reset Dates
        </button>
      </div>
    </div>
  </div>
</div>






<div class="dashboard-figures-grid" *ngIf="dashboard">
  <div class="grid-item" style="background: rgba(200,198,255,0.31);">
    <pr-planning-item-type-image
      [planningItemType]="PlanningItemType.maintenance"
      [big]="true">
    </pr-planning-item-type-image>
    <label class=" dashboard-text"> ONDERHOUD</label>
    <div class="dashboard-text-grid">
      <label class="grid-item-text-line"># In te plannen</label>
      <label class="grid-item-text-line dashboard-text pointer"
             (click)="goToPlanningItemOverviewPage(PlanningItemType.maintenance, 'toBePlannedTab')">
        {{getCountPlanningItemTypePerState(PlanningItemType.maintenance, PlanningState.toBePlanned)}}
      </label>
      <label class="grid-item-text-line"># Ingepland</label>
      <label class="grid-item-text-line dashboard-text pointer"
             (click)="goToPlanningItemOverviewPage(PlanningItemType.maintenance, 'plannedTab')">
        {{getCountPlanningItemTypePerState(PlanningItemType.maintenance, PlanningState.planned)}}
      </label>
      <label class="grid-item-text-line"># Uitgevoerd</label>
      <label class="grid-item-text-line dashboard-text pointer"
             (click)="goToPlanningItemOverviewPage(PlanningItemType.maintenance, 'completedTab')">
        {{getCountPlanningItemTypePerState(PlanningItemType.maintenance, PlanningState.completed)}}
      </label>
    </div>
  </div>
  <div class="grid-item" style="background: rgba(255,165,0,0.21);">
    <pr-planning-item-type-image
      [planningItemType]="PlanningItemType.lead"
      [big]="true">
    </pr-planning-item-type-image>
    <label class=" dashboard-text"> LEADS</label>
    <div class="dashboard-text-grid">
      <label class="grid-item-text-line"># In te plannen</label>
      <label class="grid-item-text-line dashboard-text  pointer"
             (click)="goToPlanningItemOverviewPage(PlanningItemType.lead, 'completedTab')">
        {{getCountPlanningItemTypePerState(PlanningItemType.lead, PlanningState.toBePlanned)}}
      </label>
      <label class="grid-item-text-line"># Ingepland</label>
      <label class="grid-item-text-line  dashboard-text pointer"
             (click)="goToPlanningItemOverviewPage(PlanningItemType.lead, 'plannedTab')">
        {{getCountPlanningItemTypePerState(PlanningItemType.lead, PlanningState.planned)}}
      </label>
      <label class="grid-item-text-line"># Uitgevoerd zonder offerte</label>
      <label class="grid-item-text-line dashboard-text pointer"
             (click)="goToPlanningItemOverviewPage(PlanningItemType.lead, 'completedNoOfferTab')">
        {{getCountPlanningItemTypePerState(PlanningItemType.lead, PlanningState.completedNoOffer)}}
      </label>
      <label class="grid-item-text-line"># Uitgevoerd met offerte</label>
      <label class="grid-item-text-line dashboard-text pointer"
             (click)="goToPlanningItemOverviewPage(PlanningItemType.lead, 'completedWithOfferTab')">
        {{getCountPlanningItemTypePerState(PlanningItemType.lead, PlanningState.completedWithOffer)}}
      </label>

    </div>
  </div>
  <div class="grid-item" style="background: rgba(255,255,0,0.22);">
    <pr-planning-item-type-image
      [planningItemType]="PlanningItemType.delivery"
      [big]="true">
    </pr-planning-item-type-image>
    <label class=" dashboard-text"> LEVERING</label>
    <div class="dashboard-text-grid">
      <label class="grid-item-text-line"># In te plannen</label>
      <label class="grid-item-text-line dashboard-text pointer"
             (click)="goToPlanningItemOverviewPage(PlanningItemType.delivery, 'newTab')">
        {{getCountPlanningItemTypePerState(PlanningItemType.delivery, PlanningState.toBePlanned)}}
      </label>

      <label class="grid-item-text-line"># Ingepland</label>
      <label class="grid-item-text-line dashboard-text pointer"
             (click)="goToPlanningItemOverviewPage(PlanningItemType.delivery, 'plannedTab')">
        {{getCountPlanningItemTypePerState(PlanningItemType.delivery, PlanningState.planned)}}
      </label>
      <label class="grid-item-text-line"># Uitgevoerd</label>
      <label class="grid-item-text-line dashboard-text pointer"
             (click)="goToPlanningItemOverviewPage(PlanningItemType.delivery, 'completedTab')">
        {{getCountPlanningItemTypePerState(PlanningItemType.delivery, PlanningState.completed)}}
      </label>
    </div>
  </div>
  <div class="grid-item" style="background: rgba(59,107,7,0.24);">
    <pr-planning-item-type-image
      [planningItemType]="PlanningItemType.placement"
      [big]="true">
    </pr-planning-item-type-image>
    <label class=" dashboard-text"> PLAATSING</label>
    <div class="dashboard-text-grid">
      <label class="grid-item-text-line"># In te plannen</label>
      <label class="grid-item-text-line dashboard-text pointer"
             (click)="goToPlanningItemOverviewPage(PlanningItemType.placement, 'newTab')">
        {{getCountPlanningItemTypePerState(PlanningItemType.placement, PlanningState.toBePlanned)}}
      </label>
      <label class="grid-item-text-line"># Ingepland</label>
      <label class="grid-item-text-line dashboard-text pointer"
             (click)="goToPlanningItemOverviewPage(PlanningItemType.placement, 'plannedTab')">
        {{getCountPlanningItemTypePerState(PlanningItemType.placement, PlanningState.planned)}}
      </label>
      <label class="grid-item-text-line"># Uitgevoerd</label>
      <label class="grid-item-text-line dashboard-text pointer"
             (click)="goToPlanningItemOverviewPage(PlanningItemType.placement, 'completedTab')">
        {{getCountPlanningItemTypePerState(PlanningItemType.placement, PlanningState.completed)}}
      </label>
    </div>
  </div>
  <div class="grid-item" style="background: rgba(255,0,0,0.25);">
    <pr-planning-item-type-image
      [planningItemType]="PlanningItemType.repair"
      [big]="true">
    </pr-planning-item-type-image>
    <label class=" dashboard-text"> DEPANNAGE</label>
    <div class="dashboard-text-grid">
      <label class="grid-item-text-line"># In te plannen</label>
      <label class="grid-item-text-line dashboard-text pointer"
             (click)="goToPlanningItemOverviewPage(PlanningItemType.repair, 'newTab')">
        {{getCountPlanningItemTypePerState(PlanningItemType.repair, PlanningState.toBePlanned)}}
      </label>
      <label class="grid-item-text-line"># Ingepland</label>
      <label class="grid-item-text-line dashboard-text pointer"
             (click)="goToPlanningItemOverviewPage(PlanningItemType.repair, 'plannedTab')">
        {{getCountPlanningItemTypePerState(PlanningItemType.repair, PlanningState.planned)}}
      </label>
      <label class="grid-item-text-line"># Uitgevoerd</label>
      <label class="grid-item-text-line dashboard-text pointer"
             (click)="goToPlanningItemOverviewPage(PlanningItemType.repair, 'completedTab')">
        {{getCountPlanningItemTypePerState(PlanningItemType.repair, PlanningState.completed)}}
      </label>
    </div>
  </div>
</div>

<div id="dashboard" class="dashboard-grid">
  <div id="myLeadsPerSource" class="grid-item"></div>
  <div id="sunburstFinishedItems" class="grid-item"></div>
  <div id="stackedBarSumQuoteTotals" class="grid-item"></div>

</div>
<div id="PlannedItemsPerDay"></div>
<!--<div id="myDiv"></div>-->



