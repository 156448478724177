import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as toastr from 'toastr';
import { ICommentEntity, IPlanningItemEntity } from '@phrospr/api-backend';

@Component({
    selector: 'pr-comment-section-small',
    styleUrls: ['./comment-section-small.component.scss'],
    templateUrl: './comment-section-small.component.html',
})
export class CommentSectionSmallComponent {
    @Input() planningItem: IPlanningItemEntity;
    @Input() comments: [ICommentEntity];

    // WHAT DO WE NEED AS OUTPUT??
    @Output() refresh = new EventEmitter<any>();
    @Output() createComment = new EventEmitter<{ commentText: string; phone: boolean; mail: boolean }>();

    phoneClicked = false;
    mailClicked = false;

    showAddComment = false;
    commentText = '';

    addComment() {
        if (!this.commentText) {
            return toastr.error('please fill in a comment');
        }

        this.createComment.emit({ commentText: this.commentText, phone: this.phoneClicked, mail: this.mailClicked });
        this.clearComment();
    }

    clearComment() {
        this.commentText = '';
        this.showAddComment = false;
        this.mailClicked = false;
        this.phoneClicked = false;
    }
}
